import {
    Fragment,
    useEffect,
    useState
} from "react";
import { useNavigate, Link } from "react-router-dom";

import { BackendObj } from "../lib/backend";
import * as t from "../lib/backend/extractions.types.generated";
import { classNames, prettySmartDateTime } from "../lib/utils";
import { useSelector } from "react-redux";
import { selectIsSidebarLarge } from "../lib/scraper.slice";

type ReferralsAdministrationProps = {
}

type referralView = t.IReferral & { status: string };

export function ReferralsAdministration(props: ReferralsAdministrationProps) {
    // const { } = props;
    const navigate = useNavigate();

    const is_sidebar_large = useSelector(selectIsSidebarLarge);

    const [referrals, setReferrals] = useState<referralView[]>([]);

    useEffect(() => {

        BackendObj.extractions.getReferrals({})
            .then(res3 => {
                const mapped_referrals: referralView[] = res3.referrals.map(referral => {
                    return {
                        ...referral,
                        status: referral.concluded_on !== undefined ? "Concluded" : (referral.expires_on < Date.now() ? "Expired" : "Pending")
                    };
                });
                setReferrals(mapped_referrals);
            })
            .catch(err => console.error(err));

    }, [navigate]);

    return <Fragment>
        <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <div className="m-16 mb-6">
                <h2 className="text-xl font-semibold leading-7 text-gray-600 sm:truncate sm:text-3xl sm:tracking-tight">
                    Referrals
                </h2>
                <div className="pt-5 border-b-4 border-sky-600" />
            </div>

            <div className="flex flex-col px-10 py-5 overflow-y-auto">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Org</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Email</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Created</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Created by</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">New user</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Concluded on</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                        {referrals.length > 0 && referrals.map((referral, idx) => (
                            <tr key={idx}>

                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">
                                    <Link
                                        to={`/admin/org/${referral.org.uuid}`}
                                        className="underline"
                                    >{referral.org.title}</Link>
                                </td>
                                <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{referral.email}</td>
                                <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{referral.status}</td>
                                <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{prettySmartDateTime(referral.created_at)}</td>
                                <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                    <Link
                                        to={`/admin/user/${referral.user_uuid_creator}`}
                                        className="underline"
                                    >{referral.user_uuid_creator}</Link>
                                </td>
                                <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                    <Link
                                        to={`/admin/user/${referral.user_uuid_new}`}
                                        className="underline"
                                    >{referral.user_uuid_new}</Link>
                                </td>
                                <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{referral.concluded_on === undefined ? "-" : prettySmartDateTime(referral.concluded_on)}</td>
                            </tr>
                        ))}
                        {referrals.length === 0 && <tr>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" colSpan={4}>
                                No referrals found.
                            </td>
                        </tr>}
                    </tbody>
                </table>
            </div>
        </div>
    </Fragment >;
};
