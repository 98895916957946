import {
    useEffect,
    useState
} from "react";
import { useSelector } from "react-redux";

import { classNames } from "../lib/utils";
import { selectIsSidebarLarge } from "../lib/scraper.slice";
import {
    EMAIL_TYPES,
    email_types
} from "../lib/consts";
import { EmailType } from "../lib/backend/extractions.types.generated";
import { IUserList } from "../lib/types";
import { Backend } from "../lib/backend";

import { LoadingSpinnerLimit } from "../components/LoadingSpinner";
import { Dropdown } from "../components/Dropdown";
import { Button } from "../components/Button";

const limit = 1000;

export function AdminEmailUsers() {
    const is_sidebar_large = useSelector(selectIsSidebarLarge);

    const [users_list, setUsersList] = useState<IUserList | undefined>(undefined);
    const [selected_user_uuid_set, setSelectedUserUuidSet] = useState<Set<string>>(new Set());
    const [error_message, setErrorMessage] = useState<string | undefined>(undefined);
    const [email_type, setEmailType] = useState<string>(EMAIL_TYPES.terms_of_service);
    const [is_sending, setIsSending] = useState<boolean>(false);

    useEffect(() => {
        Backend.getUserList({
            offset: 0,
            limit,
            search: undefined,
            role: undefined
        }).then((result) => {
            if (result.total > limit) {
                setErrorMessage("Too many users to email. Ask R&D team to update this page to support more users.");
            }
            setUsersList(result);
        });
    }, []);

    const onSelectedChange = (user_uuid: string, checked: boolean) => {
        const new_set = new Set(selected_user_uuid_set);
        if (checked) {
            new_set.add(user_uuid);
        } else {
            new_set.delete(user_uuid);
        }
        setSelectedUserUuidSet(new_set);
    };

    const onSendEmail = () => {
        setIsSending(true);
        Backend.emailUsers({
            user_uuids: Array.from(selected_user_uuid_set),
            email_type: email_type as EmailType
        }).then(() => {
            setIsSending(false);
        });
    };

    return <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        <div className="m-16 mb-6 max-w-5xl">
            <div className="flex flex-row items-center">
                <h2 className="text-xl font-semibold leading-7 text-gray-600 sm:truncate sm:text-3xl sm:tracking-tight">
                    Email Users
                </h2>
            </div>
            <div className="pt-5 border-b-4 border-sky-600" />
        </div>
        <div className="px-10 py-5 max-w-6xl flow-root">
            {error_message && <div className="text-red-600">{error_message}</div>}
            {users_list === undefined && <div className="flex justify-center"><div className="m-10"><LoadingSpinnerLimit /></div></div>}

            {users_list !== undefined && <div className="sm:px-6 lg:px-8 pb-4 flex">
                <div className="max-w-md"><Dropdown values={email_types} selected={email_type} onChange={setEmailType} /></div>
                <div className="flex-grow" />
                <Button text="Select All" disabled={is_sending} onClick={() => setSelectedUserUuidSet(new Set(users_list.users.map((user) => user.uuid)))} />
                <Button text="Deselect All" disabled={is_sending} onClick={() => setSelectedUserUuidSet(new Set())} />
                <Button text="Send Email" highlight={true} disabled={is_sending} loading={is_sending} onClick={onSendEmail} />
            </div>}

            {users_list !== undefined && <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                        <tr>
                            <th className="py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"></th>
                            <th className="py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Name</th>
                            <th className="py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Email</th>
                            <th className="py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Role</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                        {users_list.users.map((user) => <tr key={user.uuid}>
                            <td className="px-3 py-2 w-10">
                                <input
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                                    checked={selected_user_uuid_set.has(user.uuid)}
                                    onChange={(e) => { onSelectedChange(user.uuid, e.target.checked) }}
                                />
                            </td>
                            <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">{user.first_name} {user.last_name}</td>
                            <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">{user.email}</td>
                            <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">{user.role}</td>
                        </tr>)}
                    </tbody>
                </table>
            </div>}
        </div>
    </div>;
}