import { Switch } from "@headlessui/react"

import { classNames } from "../lib/utils"

type ToggleProps = {
    enabled: boolean,
    setEnabled: (enabled: boolean) => void,
    bg_color?: string,
    toggle_color?: string
}

export function Toggle(props: ToggleProps) {
    const { enabled, setEnabled, bg_color, toggle_color } = props;

    return (
        <Switch
            checked={enabled}
            onChange={setEnabled}
            className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full"
        >
            <span className="sr-only">Use setting</span>
            <span aria-hidden="true" className={classNames("pointer-events-none absolute h-full w-full rounded-md", bg_color || "bg-white")} />
            <span
                aria-hidden="true"
                className={classNames(
                    enabled ? (toggle_color || "bg-sky-600") : "bg-gray-200",
                    "pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out"
                )}
            />
            <span
                aria-hidden="true"
                className={classNames(
                    enabled ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out"
                )}
            />
        </Switch>
    )
}
