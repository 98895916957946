import { ClipboardIcon } from "@heroicons/react/24/outline";

export function CodeBox(props: { language: string, code: string }) {
    const { language, code } = props;

    const onCopy = () => {
        navigator.clipboard.writeText(code);
    };

    return <div className="w-full">
        <div className="mx-4 text-xs sm:col-span-4 sm:mt-0 bg-gray-600 text-gray-200 rounded-t-lg p-2 flex justify-between items-center">
            <span>{language}</span>
            <ClipboardIcon className="w-5 h-5 cursor-pointer hover:text-white" onClick={onCopy} />
        </div>
        <div className="mx-4 text-sm sm:col-span-4 sm:mt-0 bg-gray-700 text-white font-mono overflow-auto whitespace-pre rounded-b-lg p-4">
            {code}
        </div>
    </div>;
}
