import { useEffect } from "react";
import { useSelector } from "react-redux";

import { classNames } from "../lib/utils";
import {
    selectIsSidebarLarge,
    selectOrgBalances
} from "../lib/scraper.slice";

import { LoadingSpinnerIcon } from "./LoadingSpinner";
import { ORG_TYPES } from "../lib/consts";
import { useNavigate } from "react-router-dom";
import { Backend, BackendObj } from "../lib/backend";


export function OneTimePaymentRedirect() {
    const navigate = useNavigate();

    const is_sidebar_large = useSelector(selectIsSidebarLarge);
    const org_balances = useSelector(selectOrgBalances);

    useEffect(() => {
        // get org uuid
        const org_uuid = org_balances.find(org => org.org.type === ORG_TYPES.personal)?.org.uuid;

        const inner_body = async () => {
            // first get subscription status
            const subscriptions = await Backend.getUserSubscriptions();
            const org_subscription = subscriptions.find(sub => sub.org.uuid === org_uuid);
            // if user has no subscription, redirect to subscription page
            if (org_subscription === undefined || org_subscription.subscription === undefined) {
                console.error("User has no subscription");
                navigate(`/stripe-subscribe/${org_uuid}`);
                return;
            }
            // user has subscription, get options
            const stripe_options = await BackendObj.stripe.getStripeOptions({ org_uuid: org_subscription.org.uuid });
            const stripe_tier = org_subscription.subscription.type === "tier2" ? stripe_options.tier2 : stripe_options.tier1;
            const lookup_key = stripe_tier.lookup_key;
            const { url } = await BackendObj.stripe.createCheckoutSession({ lookup_key, org_uuid: org_subscription.org.uuid });
            window.location.replace(url);
        };

        if (org_uuid !== undefined) {
            inner_body().catch((err) => {
                // in case of error redirect to home
                console.error(err);
                navigate("/");
            });
        }
    }, [navigate, org_balances]);

    return <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        <div className="px-10 py-40">
            <div className="flex flex-col items-center justify-center py-20">
                <LoadingSpinnerIcon />
            </div>
            <div className="flex flex-col items-center justify-center">
                <p className="max-w-xl py-2 text-center">
                    Checking subscription status...
                </p>
                <p className="max-w-xl py-2 text-center">
                    You will be automatically redirected to the payment page.
                </p>
            </div>
        </div>
    </div>;
}
