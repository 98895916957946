import {
    useState,
    useEffect
} from 'react';
import { LoadingSpinner } from '../components/LoadingSpinner';

import { BackendObj } from "../lib/backend";
import { IAuditLogEntry } from '../lib/backend/extractions.types.generated';
import { prettyDateTime } from "../lib/utils";
import { FullScreenText } from '../components/FullScreen';
import { ErrorMessageBar } from '../components/ErrorMessageBar';
import { ButtonGroup } from '../components/Button';

export type AuditLogEntity = {
    uuid: string;
    name: string;
    type: "template" | "context" | "endpoint";
}

interface AuditLogHistoryProps {
    entities: AuditLogEntity[];
}

const AuditLogHistory = ({ entities }: AuditLogHistoryProps) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [auditLogEntries, setAuditLogEntries] = useState<(IAuditLogEntry & { entity_name: string })[]>([]);
    const [show_details, setShowDetails] = useState<boolean>(false);
    const [details, setDetails] = useState<string>("");

    useEffect(() => {
        const fetchAuditLog = async () => {
            if (entities.length === 0) {
                return;
            }
            try {
                setLoading(true);
                const data: (IAuditLogEntry & { entity_name: string })[] = [];
                // load data for all entities
                for (const entity of entities) {
                    const response = await BackendObj.extractions.getAuditLogForEntity({
                        entity_uuid: entity.uuid,
                        entity_type: entity.type
                    });
                    data.push(...response.entries
                        .map(entry => ({ ...entry, entity_name: entity.name }))
                        .filter(entry => entry.diff_string !== undefined && entry.diff_string.length > 0));
                }
                data.sort((a, b) => b.timestamp - a.timestamp); // sort by timestamp descending
                setAuditLogEntries(data);
                setError(null);
            } catch (err: any) {
                setError('Failed to fetch audit log entries, ' + err.meessage);
                console.error('Error fetching audit log:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchAuditLog();
    }, [entities]);

    const getDiffString = (entry: IAuditLogEntry): any => {
        if (entry.diff_string !== undefined) {
            // replace console color instructions with actual colors
            const colorMap: { [key: string]: string } = {
                '0': '#000000', // Black (reset)
                '31': '#aa0000', // Red
                '32': '#00aa00', // Green
                '33': '#aaaa00', // Yellow
                '34': '#0000aa', // Blue
                '35': '#FF00FF', // Magenta
                '36': '#00FFFF', // Cyan
                '37': '#FFFFFF', // White
                '90': '#808080', // Bright Black (Gray)
                '91': '#FF8080', // Bright Red
            };

            const diff_string = entry.diff_string
                .replace(/\x1b\[(\d+)m/g, (match, p1) => { // eslint-disable-line
                    return colorMap[p1] ? `<span style="color: ${colorMap[p1]}">` : '';
                })
                .replace(/\x1b\[0m/g, '</span>'); // eslint-disable-line
            return <pre dangerouslySetInnerHTML={{ __html: diff_string }} />;
        }
        return null;
    };

    const showRecordData = (record: any) => {
        setDetails(JSON.stringify(record, null, 2));
        setShowDetails(true);
        return true;
    };

    return (
        <div className="min-w-full">
            {loading && <LoadingSpinner />}
            {error && <ErrorMessageBar message={error} clearMessage={() => setError(null)} />}

            <div className="max-w-5xl">
                {auditLogEntries.map((entry) => (<div key={entry.id} className="py-4 ">
                    <div className="px-4 flex flex-row items-center gap-x-2">
                        <span className="text-sm text-gray-600">{entry.entity_type}</span> <br />
                        <span className="text-sm text-gray-600">{entry.entity_name}</span> <br />
                        <div className="grow" />
                        <span className="text-xs text-gray-600">{prettyDateTime(entry.timestamp)}</span> <br />
                        <ButtonGroup
                            buttons={[
                                { text: "Old", onClick: () => showRecordData(entry.old_data ?? {}) },
                                { text: "New", onClick: () => showRecordData(entry.new_data ?? {}) }
                            ]}
                            tiny={true}
                        />
                    </div>
                    <div className="mt-2 border border-gray-200 bg-gray-50 rounded-md p-4 text-xs text-gray-600 align-top overflow-x-auto">
                        {getDiffString(entry)}
                    </div>
                </div>))}
            </div>
            {show_details && <FullScreenText
                title={""}
                text={details}
                show={show_details}
                onClose={() => setShowDetails(false)} />}
        </div>
    );
};

export default AuditLogHistory;

