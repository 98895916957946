import {
    Fragment,
    useEffect,
    useState
} from "react";
import { useNavigate } from "react-router-dom";
import {
    useDispatch,
    useSelector
} from "react-redux";

import * as hi from "@heroicons/react/24/outline";
import {
    BrowserIcon,
    FileCodeIcon,
    MarkGithubIcon
} from "@primer/octicons-react";

import {
    ORG_ROLES,
    ORG_TYPES,
    USER_ROLES
} from "../lib/consts";
import * as st from "../lib/types";
import { Backend, BackendObj } from "../lib/backend";
import * as stripe from "../lib/backend/stripe.types.generated";
import { AppDispatch } from "../store";
import {
    scraperUser,
    selectIsSSO,
    selectIsSidebarLarge,
    selectMemberships,
    selectSelfUrl,
    selectUser
} from "../lib/scraper.slice";
import {
    classNames,
    redirectToExternalPage,
    validateEmail
} from "../lib/utils";

import { UserDetails } from "./UserDetails";
import { Button } from "../components/Button";
import { OrgPill } from "../components/OrgPill";
import { LoadingSpinnerLimit } from "../components/LoadingSpinner";
import { TransactionHistory } from "../components/TransactionHistory";
import { ExtractJobs } from "../components/ExtractJobs";
import { UserAuditLog } from "../components/UserAuditLog";
import { ITab, Tabs } from "../components/Tabs";
import { OrgAuditLog } from "../components/OrgAuditLog";
import { ButtonStripeSubscribe } from "../components/ButtonStripeSubscribe";
import { Toggle } from "../components/Toggle";
import { SecurityDocs } from "../components/SecurityDocs";
import { Textbox } from "../components/Textbox";
import { WebApiKeyList } from "../components/WebApiKeyList";

interface OrgBalanceEx extends st.IOrgBalance {
    stripe_options?: stripe.IGetStripeOptionsRes;
    subscription?: st.IOrgSubscription;
}

export function User() {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const is_sidebar_large = useSelector(selectIsSidebarLarge);
    const user = useSelector(selectUser);
    const memberships = useSelector(selectMemberships);
    const is_sso = useSelector(selectIsSSO);
    const self_url = useSelector(selectSelfUrl);

    const admin_orgs = memberships
        .filter((membership) => membership.role === ORG_ROLES.admin)
        .map((membership) => membership.org)
        .filter((org) => org.type === ORG_TYPES.business);

    const personal_org = memberships.find((membership) => membership.org.type === ORG_TYPES.personal);

    const [is_loading, setIsLoading] = useState<boolean>(false);
    const [balances, setBalances] = useState<OrgBalanceEx[] | undefined>(undefined);
    const [selected_tab_key, setSelectedTab] = useState<string>("extract_jobs");
    const [history_org, setHistoryOrg] = useState<st.IOrganization | undefined>(undefined);
    const [open_transaction_history, setOpenTransactionHistory] = useState<boolean>(false);
    const [email_to_invite, setEmailToInvite] = useState<string>("");
    const [is_sending_invite, setIsSendingInvite] = useState<boolean>(false);
    const [invite_error, setInviteError] = useState<string | undefined>(undefined);
    const [invite_msg, setInviteMsg] = useState<string | undefined>(undefined);

    const tabs: ITab[] = [
        { name: "Extractions", key: "extract_jobs" },
        { name: "User details", key: "registration_details" },
        { name: "REST API", key: "rest_api", hide: user.role === USER_ROLES.free },
        { name: "Audit log", key: "audit_trail" }
    ];

    for (const org of admin_orgs) {
        tabs.push({ name: `${org.name} audit log`, key: `${org.uuid}_audit_trail` });
    }

    tabs.push({ name: "Security", key: "security_docs" });

    useEffect(() => {
        // user is loaded when we get here
        setIsLoading(false);
        const inner_body = async () => {
            const balances: OrgBalanceEx[] = await Backend.getUserBalances();
            const subscriptions = await Backend.getUserSubscriptions();
            for (const subscription of subscriptions) {
                const stripe_options = await BackendObj.stripe.getStripeOptions({ org_uuid: subscription.org.uuid });
                balances
                    .filter(x => x.org.uuid === subscription.org.uuid)
                    .forEach(x => {
                        x.subscription = subscription;
                        x.stripe_options = stripe_options;
                    });
            }
            setBalances(balances);
        };
        inner_body().catch(console.error);
    }, [user]);

    const logout = () => {
        Backend.logout().then(() => {
            redirectToExternalPage("/");
        });
    };

    const toggleDebug = () => {
        // user state will be invalidate with this action
        setIsLoading(true);
        Backend.setUserDebug({ debug: !user.debug }).then(() => {
            dispatch(scraperUser());
        });
    };

    const openStripePortal = (url: string) => {
        let final_url = url.replace("{EMAIL}", encodeURIComponent(user.email));
        redirectToExternalPage(final_url);
    };

    const showTransactionHistory = (org: st.IOrganization) => {
        setHistoryOrg(org);
        setOpenTransactionHistory(true);
    }

    const determineTier = (balance: OrgBalanceEx): stripe.IStripeTierData | undefined => {
        if (balance.subscription?.subscription?.type === "tier2") {
            return balance.stripe_options?.tier2;
        } else {
            return balance.stripe_options?.tier1;
        }
    }
    const getOnetimePaymentLookupKey = (balance: OrgBalanceEx): string => {
        return determineTier(balance)?.onetime_lookup_key ?? "";
    };
    const getOnetimePaymentCredits = (balance: OrgBalanceEx): number => {
        return determineTier(balance)?.onetime_credits ?? 0;
    };

    const sendInvite = async (): Promise<void> => {
        setIsSendingInvite(true);
        setInviteError(undefined);
        setInviteMsg(undefined);
        try {
            await BackendObj.extractions.createNewReferral({ email: email_to_invite });
            setInviteMsg(`Invitation successfully sent to ${email_to_invite}!`);
            setEmailToInvite("");
        } catch (err: any) {
            let error_message_raw = err.response.data || err.message;
            if (error_message_raw.startsWith("Error: ")) {
                error_message_raw = error_message_raw.slice("Error: ".length);
            }
            setInviteError(error_message_raw);
        }
        setIsSendingInvite(false);
    };

    return <Fragment>
        <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <div className="flex flex-col p-10">
                <div className="w-full max-w-5xl bg-gray-50 border rounded-lg p-4">
                    <div className="flex items-center space-x-4">
                        <img className="h-16 w-16 rounded-full object-cover" src={user.picture_url} alt={user.first_name + " " + user.last_name} />
                        <div className="flex-1">
                            <h2 className="text-xl font-semibold text-gray-800">{user.first_name + " " + user.last_name}</h2>
                            <p className="text-sm text-gray-500">{user.email}</p>
                        </div>
                        <div className="">
                            {!is_sso && <Button href="/user/change_password" text="Change Password" highlight={false} />}
                            <Button onClick={() => { logout(); }} text="Logout" highlight={true} />
                        </div>
                    </div>
                    <div className="mt-6">
                        <div className="flex flex-row items-center space-x-4">
                            <h3 className="text-md font-semibold text-gray-800 w-32">Debug mode:</h3>
                            {!is_loading && <Toggle enabled={user.debug} setEnabled={toggleDebug} />}
                            {is_loading && <i className="fas fa-spinner fa-spin" />}
                        </div>
                        {user.debug && <div className="text-sm text-gray-600 mt-2 space-y-2">
                            <p>
                                Your account is currently in debug mode. While it is in debug mode all the extraction requests will be
                                fully stored and available for extrakt.AI customer success team to review and debug. Any access from
                                the side ouf our customer success team is logged and is visible under the audit trail tab below.
                            </p>
                            <p className="text-xs text-gray-400">Your UUID is {user.uuid}</p>
                        </div>}
                    </div>

                    <div className="mt-6 flex flex-row items-center space-x-4">
                        <h3 className="text-md font-semibold text-gray-800 w-32">Organizations:</h3>
                        <div className="flex gap-2 text-sm text-gray-600">
                            {memberships.map((membership, idx) => (<OrgPill key={idx} name={membership.org.name} type={membership.org.type} />))}
                        </div>
                    </div>
                </div>

                <div className="w-full mt-6 max-w-5xl bg-gray-50 border rounded-lg p-4">
                    {balances && <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                            <tr>
                                <th scope="col" className="py-3 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                    Organization
                                </th>
                                <th scope="col" className="px-3 py-3 text-left text-sm font-semibold text-gray-900">
                                    Available credits
                                </th>
                                <th scope="col" className="px-3 py-3 text-left text-sm font-semibold text-gray-900">
                                    <span className="sr-only">Transactions</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                            {balances && balances.map((balance, idx) => (<tr key={idx}>
                                <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
                                    {balance.org.name}
                                </td>
                                <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
                                    {balance.balance}
                                </td>
                                <td className="whitespace-nowrap px-3 py-3 text-sm text-right text-gray-500">
                                    <Button onClick={() => showTransactionHistory(balance.org)} text="History" icon={hi.BookOpenIcon} highlight={false} />
                                    {(user.role === USER_ROLES.free || user.role === USER_ROLES.professional || user.role === USER_ROLES.professional_plus) && // only trail or professional(+) users with expired subscription can subscribe
                                        balance.stripe_options?.can_use_stripe === true &&
                                        balance.subscription?.subscription === undefined &&
                                        <Button
                                            icon={hi.CurrencyDollarIcon}
                                            onClick={() => { navigate(`/stripe-subscribe/${balance.org.uuid}`); }}
                                            text="Subscribe" />}
                                    {(user.role === USER_ROLES.professional || user.role === USER_ROLES.professional_plus) && // only professional(+) users with active subscription can buy credits
                                        balance.stripe_options?.can_use_stripe === true &&
                                        balance.subscription?.subscription !== undefined &&
                                        getOnetimePaymentLookupKey(balance) !== "ERROR" &&
                                        <ButtonStripeSubscribe
                                            icon={hi.CurrencyDollarIcon}
                                            lookup_key={getOnetimePaymentLookupKey(balance)}
                                            org_uuid={balance.org.uuid}
                                            text={"Buy " + getOnetimePaymentCredits(balance) + " credits"} />}
                                    {(user.role === USER_ROLES.professional || user.role === USER_ROLES.professional_plus) && // only professional(+) users with active subscription can manage subscription
                                        balance.stripe_options?.can_use_stripe === true &&
                                        balance.stripe_options?.stripe_portal_url !== "" &&
                                        balance.subscription?.subscription !== undefined &&
                                        <Button
                                            text="Manage subscription"
                                            icon={hi.CogIcon}
                                            highlight={false}
                                            onClick={() => openStripePortal(balance.stripe_options?.stripe_portal_url ?? "")} />}
                                </td>
                            </tr>))}
                        </tbody>
                    </table>}
                    {!balances && <div className="w-full">
                        <LoadingSpinnerLimit />
                    </div>}
                </div>
                <div className="w-full mt-6 max-w-5xl bg-gray-50 border rounded-lg p-4">
                    <h3 className="text-normal font-semibold text-gray-900">
                        Invite a friend and earn free credits
                    </h3>
                    <div className="text-sm text-gray-600 mt-4 space-y-2">
                        <p>
                            Invite a friend to extrakt.AI and earn 100 free credits when they sign up.
                        </p>
                        <p>
                            When they subscribe to a paid plan you will earn additional 1000 free credits.
                        </p>
                    </div>
                    <div className="mt-4 flex flex-row items-center gap-2 text-sm text-gray-600 w-full">
                        <div className="flex-grow">
                            <Textbox placeholder="Email" value={email_to_invite} onChange={val => setEmailToInvite(val)} />
                        </div>
                        <div>
                            <Button
                                text="Invite"
                                icon={!is_sending_invite ? hi.EnvelopeIcon : undefined}
                                highlight={true}
                                loading={is_sending_invite}
                                disabled={is_sending_invite || !validateEmail(email_to_invite)}
                                onClick={sendInvite} />
                        </div>
                    </div>
                    {invite_error && <div className="flex mt-6 gap-2 text-sm text-red-500">{invite_error}</div>}
                    {invite_msg && <div className="flex mt-6 gap-2 text-sm text-gray-900">{invite_msg}</div>}
                </div>

                <div className="w-full max-w-5xl mt-10">
                    <Tabs tabs={tabs} selected_tab_key={selected_tab_key} setSelectedTab={setSelectedTab} />
                    <div className="p-4">
                        {selected_tab_key === "extract_jobs" && <div className="my-4 outer-div shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <ExtractJobs type="user" />
                        </div>}
                        {selected_tab_key === "registration_details" && <UserDetails user={user} is_onboarding={false} is_admin={false} />}
                        {selected_tab_key === "rest_api" && <div>
                            <h3 className="px-4 pt-4 leading-7 text-lg font-medium">
                                Documentation
                            </h3>
                            <div className="my-4 flex flex-row gap-2 items-center">
                                <Button
                                    icon={FileCodeIcon}
                                    text="OpenAPI"
                                    href={`${self_url}/public/open-api.json`}
                                    open_in_new_tab={true} />
                                <Button
                                    icon={BrowserIcon}
                                    text="Swagger"
                                    href={`${self_url}/public/swagger`}
                                    open_in_new_tab={true} />
                                <Button
                                    icon={MarkGithubIcon}
                                    href="https://gist.github.com/blazf/3bb9abf9bab1d968b06413c36005337e"
                                    text="Node.JS example"
                                    open_in_new_tab={true} />
                            </div>
                            <h3 className="px-4 pt-4 leading-7 text-lg font-medium">
                                Personal API keys
                            </h3>
                            <div className="my-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                {personal_org && <WebApiKeyList org_uuid={personal_org.org.uuid} />}
                            </div>
                        </div>}
                        {selected_tab_key === "audit_trail" && <div className="my-4 outer-div shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <UserAuditLog user_uuid={user.uuid} is_admin={false} />
                        </div>}
                        {selected_tab_key.endsWith("_audit_trail") && <div className="my-4 outer-div shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <OrgAuditLog org_uuid={selected_tab_key.split("_")[0]} is_admin={false} />
                        </div>}
                        {selected_tab_key === "security_docs" && <SecurityDocs />}
                    </div>
                </div>

            </div>
        </div>
        {history_org && <TransactionHistory
            is_admin={false}
            org={history_org}
            open={open_transaction_history}
            setOpen={setOpenTransactionHistory} />}
    </Fragment >;
};
