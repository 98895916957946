import { useState } from "react";
import { Button } from "./Button";
import { BackendObj } from "../lib/backend";

type ButtonStripeSubscribeProps = {
    icon?: any;
    text: string;
    lookup_key: string;
    org_uuid: string;
    subscribe_page?: boolean
}

export function ButtonStripeSubscribe(props: ButtonStripeSubscribeProps) {
    const { lookup_key, org_uuid, text, icon, subscribe_page } = props;
    const [is_loading, setIsLoading] = useState<boolean>(false);

    const onClickHandler = async () => {
        // prevent double clicks
        if (is_loading) { return; }
        setIsLoading(true);
        try {
            // get stripe redirect url
            const { url } = await BackendObj.stripe.createCheckoutSession({ lookup_key, org_uuid });
            setIsLoading(false);
            // navigate to Stripe
            window.location.replace(url);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    };

    return subscribe_page === true ? <div
        className="bg-sky-600 text-white shadow-sm hover:bg-sky-500 focus-visible:outline-sky-600 mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 cursor-pointer"
        onClick={onClickHandler}>
        {text}
    </div> : <Button icon={icon}
        disabled={is_loading}
        loading={is_loading}
        onClick={onClickHandler}
        open_in_new_tab={false}
        text={text}
    />;
}
