import * as api from "./rest_client.js";
import * as t from "./stripe.types.generated";

export interface IStripe {

    /** Get Stripe options */
    getStripeOptions(req: t.IGetStripeOptionsReq): Promise<t.IGetStripeOptionsRes>;


    /** Set Stripe flag for org */
    setStripeFlagForOrg(req: t.ISetStripeFlagForOrgReq): Promise<t.ISetStripeFlagForOrgRes>;


    /** Trigger syncing Stripe subscriptions on server */
    syncSubscriptions(req: t.ISyncSubscriptionsReq): Promise<t.ISyncSubscriptionsRes>;


    /** Get Stripe invoices for given organization's current subscription */
    getStripeInvoices(req: t.IGetStripeInvoicesReq): Promise<t.IGetStripeInvoicesRes>;


    /** Check if subscription can be associated with given org */
    verifySubscriptionsSync(req: t.IVerifySubscriptionsSyncReq): Promise<t.IVerifySubscriptionsSyncRes>;


    /** Associate subscription with given org */
    associateSubscriptionsSync(req: t.IAssociateSubscriptionsSyncReq): Promise<t.IAssociateSubscriptionsSyncRes>;


    /** Concludes Stripe session by collecting data from Stripe */
    concludeStripeSession(req: t.IConcludeStripeSessionReq): Promise<t.IConcludeStripeSessionRes>;


    /** Create a new Stripe checkout session */
    createCheckoutSession(req: t.ICreateCheckoutSessionReq): Promise<t.ICreateCheckoutSessionRes>;


    /** Check Stripe session status */
    checkSession(req: t.ICheckSessionReq): Promise<t.ICheckSessionRes>;

}

export class Stripe implements IStripe {

    client: api.IApiClient;

    constructor(client: api.IApiClient) { this.client = client; }

    /** Get Stripe options */
    async getStripeOptions(req: t.IGetStripeOptionsReq): Promise<t.IGetStripeOptionsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/stripe/options`;
        const res = await this.client.get(url, { params: reqx });
        return res.data;
    }


    /** Set Stripe flag for org */
    async setStripeFlagForOrg(req: t.ISetStripeFlagForOrgReq): Promise<t.ISetStripeFlagForOrgRes> {
        const reqx: any = req;
        const url = `/api/v1.0/stripe/set-stripe-flag-for-org`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Trigger syncing Stripe subscriptions on server */
    async syncSubscriptions(req: t.ISyncSubscriptionsReq): Promise<t.ISyncSubscriptionsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/stripe/sync-subscriptions`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Get Stripe invoices for given organization's current subscription */
    async getStripeInvoices(req: t.IGetStripeInvoicesReq): Promise<t.IGetStripeInvoicesRes> {
        const reqx: any = req;
        const url = `/api/v1.0/stripe/invoices`;
        const res = await this.client.get(url, { params: reqx });
        return res.data;
    }


    /** Check if subscription can be associated with given org */
    async verifySubscriptionsSync(req: t.IVerifySubscriptionsSyncReq): Promise<t.IVerifySubscriptionsSyncRes> {
        const reqx: any = req;
        const url = `/api/v1.0/stripe/verify-associate-subscription`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Associate subscription with given org */
    async associateSubscriptionsSync(req: t.IAssociateSubscriptionsSyncReq): Promise<t.IAssociateSubscriptionsSyncRes> {
        const reqx: any = req;
        const url = `/api/v1.0/stripe/associate-subscription`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Concludes Stripe session by collecting data from Stripe */
    async concludeStripeSession(req: t.IConcludeStripeSessionReq): Promise<t.IConcludeStripeSessionRes> {
        const reqx: any = req;
        const url = `/api/v1.0/stripe/conclude-session`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Create a new Stripe checkout session */
    async createCheckoutSession(req: t.ICreateCheckoutSessionReq): Promise<t.ICreateCheckoutSessionRes> {
        const reqx: any = req;
        const url = `/api/v1.0/stripe/create-checkout-session`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Check Stripe session status */
    async checkSession(req: t.ICheckSessionReq): Promise<t.ICheckSessionRes> {
        const reqx: any = req;
        const url = `/api/v1.0/stripe/check-session`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }

}
