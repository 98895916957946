import {
    useEffect,
    useState
} from "react";
import {
    useDispatch,
    useSelector
} from "react-redux";
import { useNavigate } from "react-router-dom";

import {
    CheckCircleIcon,
    ExclamationTriangleIcon
} from "@heroicons/react/24/outline";

import { BackendObj } from "../lib/backend";
import { classNames } from "../lib/utils";
import {
    scraperOrgBalances,
    scraperUser,
    selectIsSidebarLarge
} from "../lib/scraper.slice";
import { AppDispatch } from "../store";

import { LoadingSpinnerIcon } from "../components/LoadingSpinner";
import { SupportLink } from "../components/SupportLink";

////////////////////////////////////////////////////////////////////////////////////////////
// This screen is used to handle the Stripe payment process. It is called by Stripe after
// checkout session finishes, e.g. after the user has paid for the subscription. It processes
// the payment, the invoice and the subscription in our system and redirects to the user's dashboard.
////////////////////////////////////////////////////////////////////////////////////////////

export function Stripe() {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const is_sidebar_large = useSelector(selectIsSidebarLarge);

    const [is_loading, setIsLoading] = useState<boolean>(false);
    const [is_success, setIsSuccess] = useState<boolean>(false);
    const [is_error, setIsError] = useState<boolean>(false);
    const [status_msg, setStatusMsg] = useState<string>("");

    useEffect(() => {

        const TIMEOUT = 1000; // 1 sec
        const TIMEOUT_LONG = 2000; // 2 sec

        const type = new URLSearchParams(window.location.search).get("type") ?? "cancel";
        // checkout session unsuccessful => notify user and return to user page
        if (type !== "success") {
            setStatusMsg("Payment not successful, returning.");
            setIsLoading(false);
            setTimeout(() => {
                navigate("/user");
            }, TIMEOUT_LONG);
            return;
        }

        // retrieve session token from URL and check if it is valid
        const session_id = new URLSearchParams(window.location.search).get("session_id") ?? "UNKNOWN";
        if (session_id === "UNKNOWN") {
            setStatusMsg("Unknown session token, aborting...");
            setIsLoading(false);
            setTimeout(() => {
                navigate("/user");
            }, TIMEOUT_LONG);
            return;
        }
        setIsLoading(true);
        setStatusMsg("Verifying and confirming payment data...");

        // loop until payment is confirmed (or timeout)
        let loops_remaining = 300; // 5min
        const interval_id = setInterval(async () => {
            try {
                if (loops_remaining-- < 0) {
                    // break the loop, we waited for long enough
                    setIsLoading(false);
                    setIsSuccess(false);
                    setStatusMsg("Payment processing timeout. Please contact support.");
                    clearInterval(interval_id);
                    return;
                }

                const { status } = await BackendObj.stripe.checkSession({ session_id });

                // "created" | "paid" | "canceled" | "failed" | "unknown"
                if (status === "created") {
                    // do nothing, keep waiting
                } else if (status === "canceled" || status === "failed" || status === "unknown") {
                    setIsLoading(false);
                    setIsSuccess(false);
                    setStatusMsg("Payment processing timeout. Please contact support.");
                    clearInterval(interval_id);
                } else if (status === "paid") {
                    // If processing is done, clear the interval
                    clearInterval(interval_id);
                    // call backend to conclude the session
                    await BackendObj.stripe.concludeStripeSession({ session_id });
                    setStatusMsg("Verification successful, redirecting...");

                    // when payment confirmed, update the user's balances
                    dispatch(scraperUser());
                    dispatch(scraperOrgBalances());
                    setIsLoading(false);
                    setIsSuccess(true);
                    setTimeout(() => {
                        navigate("/user");
                    }, TIMEOUT_LONG);
                }
            } catch (error) {
                console.error(error);
                setIsLoading(false);
                setIsError(true);
                setStatusMsg("An error occurred while processing your payment.");
            }
        }, TIMEOUT);

        // Clear interval on component unmount
        return () => clearInterval(interval_id);


    }, [navigate, dispatch]);

    return <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        <div className="px-10 py-40">
            {is_loading && <div className="flex flex-col items-center justify-center py-20">
                <LoadingSpinnerIcon />
            </div>}
            {is_success && <div className="flex flex-col items-center justify-center py-20">
                <CheckCircleIcon className="w-16 h-16 text-green-500" />
            </div>}
            {is_error && <div className="flex flex-col items-center justify-center py-20">
                <ExclamationTriangleIcon className="w-16 h-16 text-red-400" />
            </div>}
            <div className="flex flex-col items-center justify-center">
                <p className="max-w-xl py-2 text-center">{status_msg}</p>
                {is_error && <p className="max-w-xl py-2 text-center">
                    Please contact <SupportLink />
                </p>}
            </div>
        </div>
    </div>;
};
