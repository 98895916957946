import { useSelector } from "react-redux";
import { selectIsSidebarLarge } from "../lib/scraper.slice";
import { classNames } from "../lib/utils";
import { XMarkIcon } from "@heroicons/react/24/outline";

type ErrorMessageProps = {
    message?: string;
    clearMessage?: () => void;
    is_sidebar_large_override?: boolean;
};

export function ErrorMessageBar(props: ErrorMessageProps) {
    const { message, clearMessage, is_sidebar_large_override} = props;

    const is_sidebar_large = useSelector(selectIsSidebarLarge);

    if (!props.message) {
        return null;
    }

    return (
        <div className={classNames(
            "z-50 fixed flex flex-row items-center w-full bottom-0 right-0 bg-red-500 p-4",
            (is_sidebar_large_override ?? is_sidebar_large) ? "lg:pl-64" : "lg:pl-20"
        )} style={{ width: "100vw" }}>
            <div className="text-gray-100 text-sm font-semibold px-4">
                {message}
            </div>
            <div className="flex-grow"></div>
            {clearMessage && <button
                className="ml-2 text-sm focus:outline-none focus:underline text-gray-300"
                onClick={() => { clearMessage(); }}>
                <XMarkIcon className="h-6 w-6" />
            </button>}
        </div>
    );
}