import { useEffect, useState } from "react";

interface IDidYouKnowItem {
    filename: string;
    description: string;
    url: string;
}

const BOOK_A_DEMO_URL = "https://meetings-eu1.hubspot.com/tomaz/book-a-demo";

const DID_YOU_KNOW_ITEMS: IDidYouKnowItem[] = [
    {
        filename: "did_you_know_0001",
        description: "Did you know you can get extraction as a CSV file (on email)?",
        url: "/endpoint/new"
    },
    {
        filename: "did_you_know_0002",
        description: "Did you know you can upload multiple files at once?",
        url: "/extraction/new"
    },
    {
        filename: "did_you_know_0003",
        description: "Did you know you can upload & extract from a picture, excel...?",
        url: "/extraction/new"
    },
    {
        filename: "did_you_know_0004",
        description: "Did you know your fields can be set as a number?",
        url: BOOK_A_DEMO_URL
    },
    {
        filename: "did_you_know_0005",
        description: "Did you know you can get daily email report?",
        url: "/endpoint/new"
    },
    {
        filename: "did_you_know_0006",
        description: "Did you know you can use pre-made templates?",
        url: "/template/new"
    },
    {
        filename: "did_you_know_0007",
        description: "Did you know there’s a ‘Suggest a template’ feature, where you upload your document, and we suggest a template?",
        url: "/template/wizard"
    },
    {
        filename: "did_you_know_0008",
        description: "Did you know you can extract Contract Terms from long contracts?",
        url: BOOK_A_DEMO_URL
    },
    {
        filename: "did_you_know_0009",
        description: "Did you know you can perform contract audits for all your contracts?",
        url: BOOK_A_DEMO_URL
    },
    {
        filename: "did_you_know_0010",
        description: "Did you know you can extract handwritten corrections?",
        url: "/extraction/new"
    },
    {
        filename: "did_you_know_0011",
        description: "Did you know that you can create an OCR-friendly PDF for your RPA?",
        url: "https://www.youtube.com/watch?v=ikSqQA8P5cU"
    },
    {
        filename: "did_you_know_0012",
        description: "Did you know that you can extract meta-data for your documentation system?",
        url: "/extraction/new"
    },
    {
        filename: "did_you_know_0013",
        description: "Did you know you can extract data from Annual Reports?",
        url: "https://www.youtube.com/watch?v=7gbNMY_HZP0"
    }
];

type DidYouKnowProps = {
    change_seconds?: number;
}

function getRandomIndex() {
    return Math.floor(Math.random() * DID_YOU_KNOW_ITEMS.length);
}

export function DidYouKnow(props: DidYouKnowProps) {
    const [current_index, setCurrentIndex] = useState<number>(getRandomIndex());
    const change_duration = (props.change_seconds ?? 10) * 1000;

    useEffect(() => {
        const interval = setInterval(() => {
            const random_index = getRandomIndex();
            setCurrentIndex(random_index);
        }, change_duration);
        return () => clearInterval(interval);
    }, [current_index, change_duration]);

    const { filename, description, url } = DID_YOU_KNOW_ITEMS[current_index];

    if (filename === undefined) return (<></>);

    return <a href={url} target="_blank" rel="noreferrer">
        <img
            src={`/did_you_know/${filename}.svg`}
            alt={description}
            className="my-5 w-full rounded-lg cursor-pointer transition-transform transform hover:scale-105"
        />
    </a>;
}