import { Fragment, useState } from "react";

import { classNames } from "../lib/utils";
import { Backend } from "../lib/backend";
import {
    EXCEL_MIME_TYPES,
    OCR_MIME_TYPES,
    SIMPLE_MIME_TYPES
} from "../lib/consts";

import { LoadingSpinnerLimit } from "./LoadingSpinner";

type DragDropTextAreaProps = {
    name: string,
    rows: number,
    text: string,
    readonly?: boolean,
    setText: (text: string, filename?: string) => void,
};

export function DragDropTextArea(props: DragDropTextAreaProps) {
    const { name, rows, text, readonly, setText } = props;

    const [is_drag_over, setIsDragOver] = useState<boolean>(false);
    const [is_parsing_file, setIsParsingFile] = useState<boolean>(false);
    const [error_message, setErrorMessage] = useState<string | undefined>(undefined);

    const handleDragOver = (e: React.DragEvent<HTMLTextAreaElement>) => {
        e.preventDefault();
        setIsDragOver(true);
    }

    const handleDragLeave = (e: React.DragEvent<HTMLTextAreaElement>) => {
        e.preventDefault();
        setIsDragOver(false);
    }

    const handleDrop = async (e: React.DragEvent<HTMLTextAreaElement>) => {
        e.preventDefault();
        setIsDragOver(false);
        if (readonly) {
            setErrorMessage("Cannot upload file in read-only mode.");
            return;
        }

        setIsParsingFile(true);
        setErrorMessage(undefined);
        const file = e.dataTransfer.files[0];

        // check file type
        if (file && OCR_MIME_TYPES.includes(file.type)) {
            try {
                const form_data = new FormData();
                form_data.append("file", file);
                const clean_text = await Backend.getCleanTextFromOcr(form_data);
                setText(clean_text, file.name);
                setIsParsingFile(false);
            } catch (err) {
                setErrorMessage("Error uploading file. Please try again.");
                setIsParsingFile(false);
            }
        } else if (file && EXCEL_MIME_TYPES.includes(file.type)) {
            try {
                const form_data = new FormData();
                form_data.append("file", file);
                const clean_text = await Backend.getCleanTextFromExcel(form_data);
                setText(clean_text, file.name);
                setIsParsingFile(false);
            } catch (err) {
                setErrorMessage("Error uploading file. Please try again.");
                setIsParsingFile(false);
            }
        } else if (file && SIMPLE_MIME_TYPES.includes(file.type)) {
            // plain text can be read directly
            const reader = new FileReader();
            reader.onload = (e) => {
                setText(e.target?.result as string, file.name);
                setIsParsingFile(false);
            };
            reader.readAsText(file);
        } else {
            setErrorMessage("Unsupported file type. Please try with a PDF, Excel, images or plain text file.");
            console.error("Unsupported file type", file);
            setIsParsingFile(false);
        }
    };

    return <Fragment>
        <textarea
            id={name}
            name={name}
            rows={rows}
            className={classNames(
                "block w-full max-w-5sxl p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6 whitespace-pre",
                is_drag_over ? (readonly ? "bg-red-50" : "bg-sky-100") : "", is_parsing_file ? "bg-gray-100 text-gray-400" : "")}
            value={is_drag_over ? "" : text}
            placeholder={is_drag_over ? "" : "Enter text or drag and drop file here."}
            onChange={(e) => setText(e.target.value)}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            disabled={is_parsing_file}
            readOnly={readonly}
        />
        {/* Overlay text area with loading indicator */}
        {is_parsing_file && <div className="absolute inset-0 flex items-center justify-center bg-white opacity-95">
            <LoadingSpinnerLimit text="Uploading and processing file..." />
        </div>}
        {error_message !== undefined && <div className="space-y-12 sm:space-y-16">
            <div>
                <p className="mt-1 max-w-3xl text-sm leading-6 text-red-600">
                    Error: {error_message}
                </p>
            </div>
        </div>}
    </Fragment>;
}