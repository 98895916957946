import { useEffect, useState } from "react";
import { IExcelArraySheet } from "../lib/types";
import { Backend } from "../lib/backend";
import { getExcelColumnName } from "../lib/utils";

type ExcelSheetViewerProps = {
    sheet: IExcelArraySheet;
}

function ExcelSheetViewer(props: ExcelSheetViewerProps) {
    const { sheet } = props;
    const { data } = sheet;

    let number_of_cols = 0;
    for (const row of data) {
        if (row.length > number_of_cols) {
            number_of_cols = row.length;
        }
    }

    if (data.length === 0) {
        return <span>This worksheet is empty.</span>;
    }

    const cols = Array.from({ length: number_of_cols }).map((_, idx) => idx);

    return <div className="">
        <div className="font-bold text-sm pb-2">
            {sheet.name}
        </div>
        <div className="outer-div">
            <table className="">
                <thead>
                    <tr>
                        <th className="min-w-[20px] bg-gray-100 border border-gray-200"></th>
                        {cols.map((idx) =>
                            <th key={idx} className="font-normal min-w-[50px] max-w-[200px] bg-gray-100 border border-gray-200 text-sm">
                                {getExcelColumnName(idx)}
                            </th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, idx) =>
                        <tr key={idx}>
                            <td className="min-w-[20px] bg-gray-100 border border-gray-200 font-normal text-sm px-1 text-center">{idx + 1}</td>
                            {cols.map((col, idx) =>
                                <td key={idx} className="min-w-[50px] max-w-[200px] border border-gray-200 font-normal text-sm px-1 truncate tracking-tighter">
                                    {row[col] || ""}
                                </td>
                            )}
                        </tr>)}
                </tbody>
            </table>
        </div>
    </div >;
}

type ExcelViewerProps = {
    attachment_uuid: string;
}

export function ExcelViewer(props: ExcelViewerProps) {
    const { attachment_uuid } = props;

    const [sheets, setSheets] = useState<IExcelArraySheet[] | undefined>(undefined);

    useEffect(() => {
        Backend.getExcelAttachment({ uuid: attachment_uuid })
            .then((sheets) => setSheets(sheets))
            .catch((error) => console.error(error));
    }, [attachment_uuid]);

    if (sheets === undefined) {
        return <div>Loading...</div>
    }

    return <div className="text-sm pb-4 flex flex-col gap-y-4">
        {sheets.map((sheet, index) => <ExcelSheetViewer key={index} sheet={sheet} />)}
    </div>;
}