import {
    Fragment,
    useEffect,
    useRef,
    useState
} from "react";
import { useSelector } from "react-redux";

import {
    Dialog,
    Transition
} from "@headlessui/react";

import { selectIsSidebarLarge } from "../lib/scraper.slice";
import { classNames } from "../lib/utils";
import { BackendObj } from "../lib/backend";
import { IMetabaseDashboard, ISyncLog } from "../lib/backend/extractions.types.generated";

import { Button, ButtonGroup } from "../components/Button";
import { useNavigate, useParams } from "react-router-dom";
import { DropdownMenu } from "../components/DropdownMenu";
import { ConfirmModal } from "../components/ConfirmModal";
import { ArrowRightIcon, ArrowTopRightOnSquareIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/outline";

type TextboxModalProps = {
    open: boolean;
    validate: (name: string, metabase_dashboard_id: string) => boolean;
    onClose: (result: boolean, name?: string, metabase_dashboard_id?: string) => void;
}

export function TextboxModal(props: TextboxModalProps) {
    const { open, validate, onClose } = props;

    const textBoxRef = useRef(null)

    const [name, setName] = useState("");
    const [metabase_dashboard_id, setMetabaseDashboardId] = useState("");

    const is_valid = validate ? validate(name, metabase_dashboard_id) : true;

    const handleClose = (result: boolean) => {
        onClose(result, name, metabase_dashboard_id);
        setName("");
        setMetabaseDashboardId("");
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            handleClose(true);
        }
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={textBoxRef} onClose={() => handleClose(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div className="sm:flex sm:items-start">
                                    <div className="text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            Add Dashboard
                                        </Dialog.Title>
                                        <div className="mt-2 w-full space-y-2 py-2">
                                            <div className="grid grid-cols-3 items-center gap-x-2">
                                                <span className="text-gray-600 text-sm whitespace-nowrap">Name:</span>
                                                <input type="text"
                                                    ref={textBoxRef}
                                                    className="col-span-2 border border-gray-300 rounded-md w-full px-3 py-2 text-gray-900 text-sm"
                                                    onKeyDown={handleKeyDown}
                                                    value={name}
                                                    onChange={(e) => setName(e.target.value)} />
                                            </div>
                                            <div className="grid grid-cols-3 items-center gap-x-2">
                                                <span className="text-gray-600 text-sm whitespace-nowrap">Dashboard ID:</span>
                                                <input type="text"
                                                    className="col-span-2 border border-gray-300 rounded-md w-full px-3 py-2 text-gray-900 text-sm"
                                                    value={metabase_dashboard_id}
                                                    onChange={(e) => setMetabaseDashboardId(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:flex justify-center">
                                    <Button highlight={true} text="Add" disabled={!is_valid} onClick={() => handleClose(true)} />
                                    <Button text="Cancel" onClick={() => handleClose(false)} />
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export function AdminDashboards() {
    const navigate = useNavigate();
    const is_sidebar_large = useSelector(selectIsSidebarLarge);
    const { dashboard_uuid } = useParams<{ dashboard_uuid: string | undefined }>();

    const [dashboards, setDashboards] = useState<IMetabaseDashboard[]>([]);
    const [selected_dashboard, setSelectedDashboard] = useState<IMetabaseDashboard | undefined>(undefined);
    const [last_sync_log, setLastSyncLog] = useState<ISyncLog | undefined>(undefined);
    const [add_dashboard_open, setAddDashboardOpen] = useState(false);
    const [remove_dashboard_open, setRemoveDashboardOpen] = useState(false);

    useEffect(() => {
        BackendObj.extractions.getMetabaseDashboards({})
            .then(({ dashboards: new_dashboards }) => setDashboards(new_dashboards));
        BackendObj.extractions.getLastSyncTime({})
            .then(({ last_sync_log: new_last_sync_log }) => setLastSyncLog(new_last_sync_log));
    }, []);

    useEffect(() => {
        if (dashboard_uuid === undefined && dashboards.length > 0) {
            navigate(`/admin/dashboard/${dashboards[0].uuid}`);
        }
        if (dashboard_uuid !== undefined) {
            BackendObj.extractions.getMetabaseDashboard({ dashboard_uuid })
                .then(({ dashboard }) => setSelectedDashboard(dashboard));
        }
    }, [dashboard_uuid, dashboards, navigate]);

    const validateAddDashboard = (name: string, metabase_dashboard_id: string) => {
        return name.length > 0 && metabase_dashboard_id.length > 0 && !isNaN(parseInt(metabase_dashboard_id));
    }

    const handleAddDashboardClose = async (result: boolean, name?: string, metabase_dashboard_id?: string) => {
        setAddDashboardOpen(false);
        if (result && name && metabase_dashboard_id && !isNaN(parseInt(metabase_dashboard_id))) {
            const { dashboard_uuid } = await BackendObj.extractions.createMetabaseDashboard({ name, metabase_dashboard_id: parseInt(metabase_dashboard_id) });
            const { dashboards: new_dashboards } = await BackendObj.extractions.getMetabaseDashboards({});
            setDashboards(new_dashboards);
            navigate(`/admin/dashboard/${dashboard_uuid}`);

        }
    }

    const handleRemoveDashboardClose = async (result: boolean) => {
        setRemoveDashboardOpen(false);
        if (result && selected_dashboard) {
            await BackendObj.extractions.deleteMetabaseDashboard({ dashboard_uuid: selected_dashboard.uuid });
            const { dashboards: new_dashboards } = await BackendObj.extractions.getMetabaseDashboards({});
            setDashboards(new_dashboards);
            navigate("/admin/dashboards");
        }
    }

    return <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        <div className="m-16 mb-6">
            <div className="flex flex-row items-center gap-x-4">
                <h2 className="text-xl font-semibold leading-7 text-gray-600 sm:truncate sm:text-3xl sm:tracking-tight">
                    Analytics
                </h2>
                <div className="flex-grow" />
                <DropdownMenu
                    title={selected_dashboard ? selected_dashboard.name : "Select Dashboard"}
                    items={dashboards.map((dashboard) => ({
                        title: dashboard.name,
                        onClick: () => navigate(`/admin/dashboard/${dashboard.uuid}`)
                    }))}
                />
                <ButtonGroup
                    buttons={[
                        { icon: TrashIcon, text: "", onClick: () => setRemoveDashboardOpen(true) },
                        { icon: PlusIcon, text: "", onClick: () => setAddDashboardOpen(true) },
                        { icon: ArrowTopRightOnSquareIcon, text: "", href: "https://nordoon.metabaseapp.com/", open_in_new_tab: true }
                    ]}
                />
            </div>
            <div className="pt-5 border-b-4 border-sky-600" />
        </div>
        <div className="m-10">
            {selected_dashboard === undefined && <div className="text-gray-400 text-sm">No dashboard selected</div>}
            {selected_dashboard !== undefined && (
                <div className="bg-gray-400 p-1 absolute left-10 right-10 bottom-10 top-40">
                    <iframe
                        title={selected_dashboard.name}
                        src={selected_dashboard.metabase_dashboard_url}
                        width="100%"
                        height="100%"
                        style={{ background: "transparent" }}
                    />
                    {last_sync_log && <div className="pt-2 text-gray-400 text-xs">Last DB<ArrowRightIcon className="w-3 h-3 inline" />DW sync: {last_sync_log.sync_ts}</div>}
                </div>
            )}

        </div>
        <TextboxModal
            open={add_dashboard_open}
            validate={validateAddDashboard}
            onClose={handleAddDashboardClose}
        />
        <ConfirmModal
            open={remove_dashboard_open}
            title="Remove Dashboard"
            message={["Are you sure you want to remove this dashboard?"]}
            confirm="Remove"
            cancel="Cancel"
            onClose={handleRemoveDashboardClose}
        />
    </div>;
};
