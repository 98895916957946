import { configureStore } from '@reduxjs/toolkit';
import scraperSlice from './lib/scraper.slice';

export const store = configureStore({
  reducer: {
    scraper: scraperSlice
  }
});

export type AppDispatch = typeof store.dispatch;
