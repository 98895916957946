import { prettyNumber } from "../lib/utils"
import {
    IContextEvalMetrics,
    IContextNoUUID,
    IEvalMetrics,
    IScrapeEvalMetrics
} from "../lib/types"

type MetricsProps = {
    stats: {
        name: string
        stat: string
    }[]
}

export function Metrics(props: MetricsProps) {
    const { stats } = props

    return <dl className="mt-3 grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-4">
        {stats.map((item) => (
            <div key={item.name} className="flex-row rounded-lg px-3 py-2 border border-sky-200">
                <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
                <dd className="mt-1">
                    <span className="text-sm font-normal text-gray-500 mr-2">diff</span>
                    <span className="text-2xl font-semibold tracking-tight text-gray-900">{item.stat}</span>
                </dd>
            </div>
        ))}
    </dl>;
}

export function CompactMetrics(props: MetricsProps) {
    const { stats } = props

    return <dl className="mt-3 grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-4">
        {stats.map((item) => (
            <div key={item.name} className="rounded-lg px-3 py-2 border border-gray-200 bg-white flex flex-row">
                <span className="truncate block text-sm font-medium text-gray-500">{item.name}</span>
                <span className="ml-2 block text-sm font-semibold tracking-tight text-gray-900">{item.stat}</span>
            </div>
        ))}
    </dl>;
}

type EvalMetricsProps = {
    eval_metrics: IEvalMetrics,
    eval_metrics_per_field: { field_name: string, metrics: IEvalMetrics }[],
    compact?: boolean,
}

export function EvalMetrics(props: EvalMetricsProps) {
    const { eval_metrics, eval_metrics_per_field, compact } = props

    const eval_metrics_for_fields_with_change = [...eval_metrics_per_field]
        .sort((a, b) => a.metrics.accuracy - b.metrics.accuracy)
        .filter(item => item.metrics.accuracy < 1.0);

    const stats = [
        { name: "Overall", stat: prettyNumber(100.0 - 100.0 * eval_metrics.accuracy, 1) + "%" },
        ...eval_metrics_for_fields_with_change.map((item) => (
            { name: item.field_name, stat: prettyNumber(100.0 - 100.0 * item.metrics.accuracy, 1) + "%" }
        ))
    ];

    return compact ? <CompactMetrics stats={stats} /> : <Metrics stats={stats} />;
}

type ScrapeEvalMetricsProps = {
    scrapes_eval_metrics: IScrapeEvalMetrics[],
}

export function ScrapeEvalMetrics(props: ScrapeEvalMetricsProps) {
    const { scrapes_eval_metrics } = props;

    return <div>
        {scrapes_eval_metrics.map((scrape_eval_metrics, idx) => (
            <EvalMetrics
                key={idx}
                eval_metrics={scrape_eval_metrics.metrics}
                eval_metrics_per_field={scrape_eval_metrics.metrics_per_field}
                compact={true} />
        ))}
    </div>;
}

type ContextEvalMetricsProps = {
    contexts: (IContextNoUUID & { uuid: string })[],
    contexts_eval_metrics: IContextEvalMetrics[]
}

export function ContextEvalMetrics(props: ContextEvalMetricsProps) {
    const { contexts, contexts_eval_metrics } = props;

    const metrics: { context: IContextNoUUID, eval_metrics: IContextEvalMetrics }[] = [];
    for (const context of contexts) {
        const eval_metrics = contexts_eval_metrics.find(eval_metrics => eval_metrics.context_uuid === context.uuid);
        if (eval_metrics) {
            metrics.push({ context, eval_metrics });
        }
    }

    return <div>
        {metrics.map((item, idx) => (<div key={idx} className="py-4">
            <h3 className="px-4 text-sm font-medium text-gray-600">Step {idx + 1}: {item.context.name}</h3>
            <EvalMetrics
                eval_metrics={item.eval_metrics.metrics}
                eval_metrics_per_field={item.eval_metrics.metrics_per_field}
                compact={true} />
        </div>
        ))}
    </div>;
}