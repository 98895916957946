import { Fragment, useRef, useState, useEffect } from "react";

import * as hi from "@heroicons/react/24/outline";

import { classNames, validateEmail, validatePassword } from "../lib/utils";
import { Backend } from "../lib/backend";
import { Link } from "react-router-dom";

export const LOGIN_STATES = {
    "login_email": "login_email",
    "login_password": "login_password",
    "register": "register",
    "reset_password": "reset_password",
    "email_sent": "email_sent",
    "unknown_email": "unknown_email",
    "unknown_password": "unknown_password",
    "email_already_exists": "email_already_exists",
    "sso_email_cannot_register": "sso_email_cannot_register"
};

type LoginState = typeof LOGIN_STATES[keyof typeof LOGIN_STATES];

type LoginFieldProps = {
    label: string;
    value: string;
    onChange: (value: string) => void;
    onClick?: () => void;
    type?: string;
    tooltip?: boolean;
    focus?: boolean;
    disabled?: boolean;
}

function LoginField(props: LoginFieldProps) {
    const { label, value, onChange, type, tooltip, focus, onClick, disabled } = props;

    const [is_focused, setIsFocused] = useState(false);

    // true if input has focus
    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false);

    // focus on first field if focus is set
    const ref = useRef<HTMLInputElement>(null);
    if (focus) {
        setTimeout(() => ref.current?.focus(), 50);
    }

    return <div>
        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
            {label}
        </label>
        {tooltip && <span className={classNames("text-xs", !is_focused && value.length > 0 && !validatePassword(value) ? "text-red-500" : "text-gray-400")}>
            min. 8 chars, one lowercase and uppercase letter, one digit.
        </span>}
        <div className="mt-2">
            <input
                disabled={disabled}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onClick={onClick}
                ref={ref}
                id={type}
                name={type}
                type={["email", "password"].includes(type || "") ? type : "text"}
                autoComplete={type === "password" ? "current-password" : type}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
            />
        </div>
    </div>;
}

type LoginProps = {
    init_login_state: LoginState;
}

export function Login(props: LoginProps) {
    const { init_login_state } = props;

    const [login_state, setLoginState] = useState<LoginState>(init_login_state);
    const [email_address, setEmailAddress] = useState("");
    const [password, setPassword] = useState("");
    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");
    const [remember_me, setRememberMe] = useState(false);
    const [is_processing, setIsProcessing] = useState(false);
    const [accepted_terms, setAcceptedTerms] = useState(false);
    const [org_contacts, setOrgContacts] = useState<{ name: string, email: string }[]>([]);

    const is_email_valid = validateEmail(email_address);

    // for sign up we just need presence of password
    const is_password_present = password.length > 0;
    // for registration we want to ensure strong password
    const is_password_valid = validatePassword(password);

    const is_name_valid = first_name.length > 0 && last_name.length > 0;

    useEffect(() => {
        // get current local url (http://localhost:3000/templates?a=b => /templates?a=b)
        // we will remember it for redirect after login
        const pre_login_url = window.location.pathname + window.location.search;
        localStorage.setItem("pre_login_url", pre_login_url);
    }, []);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const email = queryParams.get('email');
        if (email) {
            setEmailAddress(email);
        }
    }, []);


    const handleEmailAddressLoginChange = () => {
        setPassword("");
        setLoginState(LOGIN_STATES.login_email);
    };

    const handleContinue = (e: any) => {
        e.preventDefault();
        setIsProcessing(true);
        Backend.authSource({ email_address })
            .then((auth_source) => {
                if (auth_source === "password") {
                    setLoginState(LOGIN_STATES.login_password);
                    setIsProcessing(false);
                } else if (auth_source === "google") {
                    window.location.href = "/auth/google";
                } else if (auth_source === "sso") {
                    window.location.href = `/auth/sso?email_address=${email_address}`;
                } else {
                    // if auth source is unknown, we still ask for password
                    setLoginState(LOGIN_STATES.login_password);
                    setIsProcessing(false);
                }
            })
            .catch((err) => {
                setIsProcessing(false);
                setLoginState(LOGIN_STATES.unknown_email);
                console.log(err);
            });
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        setIsProcessing(true);
        Backend.login({ email_address, password, remember_me })
            .then((ok) => {
                window.location.href = "/";
            })
            .catch((err) => {
                setIsProcessing(false);
                setLoginState(LOGIN_STATES.unknown_password);
                setPassword("");
                console.log(err);
            });
    };

    const handleRegister = (e: any) => {
        e.preventDefault();
        setIsProcessing(true);
        Backend.register({ email_address, password, first_name, last_name })
            .then(({ status, org_contacts }) => {
                console.log("status", status);
                if (status === "error") {
                    setIsProcessing(false);
                    setLoginState(LOGIN_STATES.email_already_exists);
                } else if (status === "sso") {
                    setIsProcessing(false);
                    setOrgContacts(org_contacts || []);
                    setLoginState(LOGIN_STATES.sso_email_cannot_register);
                } else {
                    window.location.href = "/";
                }
            })
            .catch((err: any) => {
                setIsProcessing(false);
                setLoginState(LOGIN_STATES.email_already_exists);
                console.log("register", err);
            });
    };

    const handlePasswordReset = (e: any) => {
        if (is_email_valid) {
            e.preventDefault();
            setIsProcessing(true);
            Backend.passwordReset({ email_address })
                .then(() => {
                    setIsProcessing(false);
                    setLoginState(LOGIN_STATES.reset_password);
                })
                .catch((err) => {
                    // in both cases we claim email was sent, to obscure if account with this email exists
                    setIsProcessing(false);
                    setLoginState(LOGIN_STATES.reset_password);
                    console.log(err);
                });
        }
    };

    const handleGoogleLogin = () => {
        // Redirect to your Express server's Google authentication route
        window.location.href = "/auth/google";
    };

    const handleSendLoginLink = (e: any) => {
        e.preventDefault();
        setIsProcessing(true);
        Backend.sendMagicLink({ email_address, remember_me })
            .then((status) => {
                if (status === "ok") {
                    setIsProcessing(false);
                    setLoginState(LOGIN_STATES.email_sent);
                } else if (status === "sso_email") {
                    // if email is SSO, we redirect to SSO login
                    window.location.href = `/auth/sso?email_address=${email_address}`;
                } else {
                    setIsProcessing(false);
                    setLoginState(LOGIN_STATES.unknown_email);
                }
            })
            .catch((err) => {
                setIsProcessing(false);
                setLoginState(LOGIN_STATES.unknown_email);
                console.log(err);
            });
    };

    return login_state === LOGIN_STATES.register ?
        <div className="flex min-h-screen flex-1 flex-col justify-center py-0 md:py-20 sm:px-6 lg:px-8 w-full bg-cover lg:bg-[url('app_blur.png')]">
            <div className="sm:mx-auto sm:w-full lg:flex lg:flex-row sm:max-w-[1000px]">
                <div className="mt-6 hidden lg:flex justify-center sm:max-w-[480px] shadow sm:rounded-lg bg-sky-800">
                    <div className="mt-12 flex flex-col">
                        <img
                            className="mx-auto ml-10 h-16 w-auto"
                            src="/logo.svg"
                            alt="extrakt.AI"
                        />
                        <div className="mt-10 mx-10 font-bold text-lg text-orange-200">Free trial</div>
                        <div className="mt-2 mx-10 font-normal text-white">
                            Because we understand that you need to see it working with your own eyes, using your data.
                        </div>
                        <div className="mt-6 mx-10 font-bold text-lg text-orange-200">No credit card</div>
                        <div className="mt-2 mx-10 font-normal text-white">
                            Enjoy your free trial without commitments. Decide to commit only after you've seen the value.
                        </div>
                        <div className="mt-6 mx-10 font-bold text-lg text-orange-200">Secure</div>
                        <div className="mt-2 mx-10 font-normal text-white">
                            Your data is just passing through extrakt.AI. You are the master of your data, delete it whenever you want.
                        </div>
                    </div>
                </div>
                <div className="md:mt-6 sm:mx-auto sm:w-full sm:max-w-[480px]">
                    <div className="bg-white px-6 py-2 md:py-12 shadow sm:rounded-lg sm:px-12">
                        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                            Create an account
                        </h2>
                        <p className="mt-2 text-center text-sm leading-6 text-gray-500">
                            Already a member?{' '}
                            <button onClick={() => setLoginState(LOGIN_STATES.login_email)} className="font-semibold text-sky-600 hover:text-sky-500">
                                Sign in
                            </button>
                        </p>
                        <div>
                            <form className="">
                                <LoginField label="Email address" value={email_address} onChange={setEmailAddress} />
                                <div className="mt-6">
                                    <LoginField label="Password" value={password} onChange={setPassword} type="password" tooltip={true} />
                                </div>
                                <div className="mt-6 grid grid-cols-2 gap-4">
                                    <LoginField label="First name" value={first_name} onChange={setFirstName} />
                                    <LoginField label="Last name" value={last_name} onChange={setLastName} />
                                </div>
                                <div className="mt-6 gap-4">
                                    <div className="flex items-center">
                                        <input
                                            type="checkbox"
                                            checked={accepted_terms}
                                            onChange={(e) => setAcceptedTerms(e.target.checked)}
                                            className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                                        />
                                        <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-700">
                                            <span onClick={() => setAcceptedTerms(!accepted_terms)}>I accept the </span> <a href="https://www.extrakt.ai/terms-of-use" target="_blank" rel="noreferrer" className="font-semibold text-sky-600 hover:text-sky-500">terms of service</a> and <a href="https://www.extrakt.ai/privacy-policy" target="_blank" rel="noreferrer" className="font-semibold text-sky-600 hover:text-sky-500">privacy policy</a>.
                                        </label>
                                    </div>
                                </div>
                                <div>
                                    <button
                                        onClick={handleRegister}
                                        disabled={!is_email_valid || !is_password_valid || !is_name_valid || is_processing || !accepted_terms}
                                        className="mt-6 flex w-full justify-center rounded-md bg-sky-600 disabled:bg-gray-300 disabled:text-gray-900  px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                                    >
                                        {is_processing && <i className="fas fa-spinner fa-spin mt-1" />}&nbsp;Create account
                                    </button>
                                </div>
                            </form>
                        </div>

                        <div className="mt-10">
                            <div className="relative">
                                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                    <div className="w-full border-t border-gray-200" />
                                </div>
                                <div className="relative flex justify-center text-sm font-medium leading-6">
                                    <span className="bg-white px-6 text-gray-900">Or continue with</span>
                                </div>
                            </div>

                            <div className="mt-6 grid grid-cols-1 gap-4">
                                <button onClick={handleGoogleLogin} className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow flex items-center justify-center h-9">
                                    <img className="w-6 h-6 mr-2" src="/google_logo.png" alt="google" />
                                    Google
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div > :
        <div className="flex min-h-screen flex-1 flex-col justify-center py-20 sm:px-6 lg:px-8 w-full bg-cover lg:bg-[url('app_blur.png')]">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img
                    className="mx-auto h-12 w-auto"
                    src="/logo_white.svg"
                    alt="extrakt.AI"
                />
                {(login_state === LOGIN_STATES.login_email || login_state === LOGIN_STATES.login_password) && <Fragment>
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Sign in to your account
                    </h2>
                    <p className="mt-2 text-center text-sm leading-6 text-gray-500">
                        Not a member?{' '}
                        <button onClick={() => setLoginState(LOGIN_STATES.register)} className="font-semibold text-sky-600 hover:text-sky-500">
                            Create an account
                        </button>
                    </p>
                </Fragment>}

                {login_state === LOGIN_STATES.email_sent && <Fragment>
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Check your email
                    </h2>
                    <p className="mt-2 text-center text-sm leading-6 text-gray-500">
                        We sent you an email with a link to sign in.
                    </p>
                </Fragment>}

                {login_state === LOGIN_STATES.reset_password && <Fragment>
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Check your email
                    </h2>
                    <p className="mt-2 text-center text-sm leading-6 text-gray-500">
                        We sent you an email with a link to reset password.
                    </p>
                </Fragment>}

                {login_state === LOGIN_STATES.unknown_email && <Fragment>
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Unknown email
                    </h2>
                    <p className="mt-2 text-center text-sm leading-6 text-gray-500">
                        Please try again{' '}or{' '}
                        <button onClick={() => setLoginState(LOGIN_STATES.register)} className="font-semibold text-sky-600 hover:text-sky-500">
                            create an account
                        </button>
                        .
                    </p>
                </Fragment>}

                {login_state === LOGIN_STATES.unknown_password && <Fragment>
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Unknown credentials
                    </h2>
                    <p className="mt-2 text-center text-sm leading-6 text-gray-500">
                        Wrong email or password.
                    </p>
                    <p className="mt-2 text-center text-sm leading-6 text-gray-500">
                        Please try again,{' '}
                        <button onClick={() => setLoginState(LOGIN_STATES.register)} className="font-semibold text-sky-600 hover:text-sky-500">
                            create an account
                        </button>
                        {' '}or{' '}
                        <button onClick={handlePasswordReset} disabled={!is_email_valid} className="font-semibold text-sky-600 hover:text-sky-500 disabled:text-gray-300">
                            reset password
                        </button>
                        .
                    </p>
                </Fragment>}

                {login_state === LOGIN_STATES.email_already_exists && <Fragment>
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Account already exists
                    </h2>
                    <p className="mt-2 text-center text-sm leading-6 text-gray-500">
                        Please login or{' '}
                        <button onClick={handlePasswordReset} disabled={!is_email_valid} className="font-semibold text-sky-600 hover:text-sky-500 disabled:text-gray-300">
                            reset password
                        </button>
                        .
                    </p>
                </Fragment>}

                {login_state === LOGIN_STATES.sso_email_cannot_register && <Fragment>
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Single sign-on email address
                    </h2>
                    <p className="mt-2 text-center text-sm leading-6 text-gray-500">
                        This email address is managed by your organization.
                    </p>
                    <p className="mt-2 text-center text-sm leading-6 text-gray-500">
                        Please contact your organization administrator to get access:<br />
                        {org_contacts.map((contact, i) => (
                            <Fragment key={i}>
                                <Link to={`mailto:${contact.email}`} className="font-semibold text-sky-600 hover:text-sky-500">
                                    {contact.name}
                                </Link>
                                <br />
                            </Fragment>
                        ))}
                    </p>
                </Fragment>}

                {login_state !== LOGIN_STATES.email_sent &&
                    login_state !== LOGIN_STATES.reset_password &&
                    login_state !== LOGIN_STATES.sso_email_cannot_register &&
                    <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-[480px]">
                        <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                            <div>
                                <form className="">
                                    {(
                                        login_state === LOGIN_STATES.login_email ||
                                        login_state === LOGIN_STATES.unknown_email ||
                                        login_state === LOGIN_STATES.email_already_exists
                                    ) && <div>
                                            <LoginField label="Email address" value={email_address} onChange={setEmailAddress} focus={true} />
                                            <div>
                                                <button
                                                    onClick={handleContinue}
                                                    disabled={!is_email_valid || is_processing}
                                                    className="mt-6 flex w-full justify-center rounded-md bg-sky-600 disabled:bg-gray-300 disabled:text-gray-900  px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                                                >
                                                    {is_processing && <i className="fas fa-spinner fa-spin mt-1" />}&nbsp;Continue
                                                </button>
                                            </div>
                                        </div>}
                                    {(login_state === LOGIN_STATES.login_password || login_state === LOGIN_STATES.unknown_password) && <div>
                                        <LoginField label="Email address" value={email_address} onClick={handleEmailAddressLoginChange} onChange={setEmailAddress} />
                                        <div className="mt-6">
                                            <LoginField label="Password" value={password} onChange={setPassword} type="password" tooltip={false} focus={true} />
                                        </div>
                                        <div className="grid grid-cols-2 gap-4 mt-2">
                                            <div className="flex items-center justify-between">
                                                <div className="flex items-center">
                                                    <input
                                                        type="checkbox"
                                                        checked={remember_me}
                                                        onChange={(e) => setRememberMe(e.target.checked)}
                                                        className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                                                    />
                                                    <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-700">
                                                        Remember me
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="text-sm leading-6 text-gray-500 text-right">
                                                <span
                                                    onClick={handlePasswordReset}
                                                    className={is_email_valid ? "font-semibold text-sky-600 hover:text-sky-500 cursor-pointer" : "font-semibold text-gray-300 cursor-not-allowed"}
                                                >
                                                    Reset password
                                                </span>
                                            </div>
                                        </div>
                                        <div>
                                            <button
                                                onClick={handleSubmit}
                                                disabled={!is_email_valid || !is_password_present || is_processing}
                                                className="mt-6 flex w-full justify-center rounded-md bg-sky-600 disabled:bg-gray-300 disabled:text-gray-900  px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                                            >
                                                {is_processing && <i className="fas fa-spinner fa-spin mt-1" />}&nbsp;Sign in
                                            </button>
                                        </div>
                                    </div>}
                                </form>
                            </div>

                            <div className="mt-10">
                                <div className="relative">
                                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                        <div className="w-full border-t border-gray-200" />
                                    </div>
                                    <div className="relative flex justify-center text-sm font-medium leading-6">
                                        <span className="bg-white px-6 text-gray-900">Or continue with</span>
                                    </div>
                                </div>

                                <div className="mt-6 grid grid-cols-1 gap-4">
                                    <button onClick={handleGoogleLogin} className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow flex items-center justify-center h-9">
                                        <img className="w-6 h-6 mr-2" src="/google_logo.png" alt="google" />
                                        Google
                                    </button>
                                </div>

                                <div className="mt-6 grid grid-cols-1 gap-4">
                                    <button onClick={handleSendLoginLink} className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow flex items-center justify-center h-9">
                                        <hi.EnvelopeIcon className="w-6 h-6 mr-2" />
                                        Send login link
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>}
            </div>
        </div >;
}
