import * as hi from "@heroicons/react/24/outline";

type PaginationProps = {
    offset: number;
    limit: number;
    total: number;
    setOffset: (offset: number) => void;
};

export function Pagination(props: PaginationProps) {
    const { offset, limit, total, setOffset } = props;

    const current_page = Math.floor(offset / limit);
    const max_page = Math.ceil(total / limit);

    const pages = [
        current_page - 2,
        current_page - 1,
        current_page,
        current_page + 1,
        current_page + 2,
    ].filter((page) => page >= 0 && page < max_page);

    if (pages[0] !== 0) {
        const first_listed_page = pages[0];
        const first_page = 0;
        if (first_listed_page - first_page > 1) {
            pages.unshift(-1);
        }
        pages.unshift(0);
    }

    if (pages[pages.length - 1] < (max_page - 1)) {
        const last_listed_page = pages[pages.length - 1];
        const last_page = max_page - 1;
        if (last_page - last_listed_page > 1) {
            pages.push(-1);
        }
        pages.push(max_page - 1);
    }

    return <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
        <div className="flex flex-1 justify-between sm:hidden">
            <span className="cursor-pointer relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50" onClick={() => { if (current_page > 0) { setOffset(offset - limit); } }}>
                Previous
            </span>
            <span className="cursor-pointer relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50" onClick={() => { if (current_page < (pages.length - 1)) { setOffset(offset + limit); } }}>
                Next
            </span>
        </div>
        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <div>
                <p className="text-sm text-gray-700">
                    Showing <span className="font-medium">{offset + 1}</span> to <span className="font-medium">{Math.min(offset + limit, total)}</span> of{' '}
                    <span className="font-medium">{total}</span> results
                </p>
            </div>
            <div>
                <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                    <span className="cursor-pointer relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0" onClick={() => { if (current_page > 0) { setOffset(offset - limit); } }}>
                        <span className="sr-only">Previous</span>
                        <hi.ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                    {pages.map((page, idx) => {
                        if (page === -1) {
                            return <span key={idx} className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">...</span>
                        } else if (page === current_page) {
                            return <span key={idx} aria-current="page" className="cursor-pointer relative z-10 inline-flex items-center bg-sky-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600">{page + 1}</span>
                        } else {
                            return <span key={idx} className="cursor-pointer relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0" onClick={() => { setOffset(page * limit); }}>{page + 1}</span>
                        }
                    })}
                    <span className="cursor-pointer relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0" onClick={() => { if (current_page < (pages.length - 1)) { setOffset(offset + limit); } }}>
                        <span className="sr-only">Next</span>
                        <hi.ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                </nav>
            </div>
        </div>
    </div>
}