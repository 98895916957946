import { Fragment, useEffect, useRef, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { Button } from "./Button";

type TextboxModalProps = {
    icon?: any;
    open: boolean;
    title: string;
    message?: string;
    init_text?: string;
    cancel?: string;
    confirm?: string;
    validate?: (input_text: string) => boolean;
    onClose: (result: boolean, input_text?: string) => void;
}

export function TextboxModal(props: TextboxModalProps) {
    const { open, title, message, init_text, cancel, confirm, onClose } = props;

    const textBoxRef = useRef(null)

    const [input_text, setInputText] = useState("");

    useEffect(() => {
        setInputText(init_text ?? "");
    }, [init_text]);

    const is_valid = props.validate ? props.validate(input_text) : true;

    const handleClose = (result: boolean) => {
        onClose(result, input_text);
        setInputText("");
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            handleClose(true);
        }
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={textBoxRef} onClose={() => handleClose(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div className="sm:flex sm:items-start">
                                    {props.icon && <div className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-sky-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <props.icon className="h-6 w-6 text-sky-600" aria-hidden="true" />
                                    </div>}
                                    <div className="text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            {title}
                                        </Dialog.Title>
                                        {message !== undefined && <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                {message}
                                            </p>
                                        </div>}
                                        <div className="mt-2 w-full">
                                            <input type="text"
                                                ref={textBoxRef}
                                                className="border border-gray-300 rounded-md w-full px-3 py-2 text-gray-900 text-sm"
                                                onKeyDown={handleKeyDown}
                                                value={input_text}
                                                onChange={(e) => setInputText(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:flex justify-center">
                                    <Button highlight={true} text={confirm ?? "OK"} disabled={!is_valid} onClick={() => handleClose(true)} />
                                    <Button text={cancel ?? "Cancel"} onClick={() => handleClose(false)} />
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
