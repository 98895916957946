import { TrashIcon } from "@heroicons/react/24/outline";
import { IExtractEnumValue } from "../lib/backend/extractions.types.generated";
import { InvisibleTextbox } from "./Textbox";

type EnumTableProps = {
    values: IExtractEnumValue[];
    setValues: (values: IExtractEnumValue[]) => void;
}

export function EnumTable(props: EnumTableProps) {
    const { values, setValues } = props;

    const setValue = (idx: number, value: string) => {
        setValues(values.map((v, i) => i === idx ? { ...v, value } : v));
    }

    const setGuideline = (idx: number, guideline: string) => {
        setValues(values.map((v, i) => i === idx ? { ...v, guideline } : v));
    }

    const addValue = () => {
        setValues([...values, { value: "", guideline: "" }]);
    }

    const deleteValue = (idx: number) => {
        setValues(values.filter((_, i) => i !== idx));
    }

    return <table className="w-full">
        <thead>
            <tr>
                <th className="w-[20px] bg-gray-100 border border-gray-300"></th>
                <th className="py-1 px-2 bg-gray-100 border border-gray-300 text-left text-sm font-semibold align-top">Label</th>
                <th className="py-1 px-2 bg-gray-100 border border-gray-300 text-left text-sm font-semibold align-top">Guideline (optional)</th>
            </tr>
        </thead>
        <tbody>
            {values.map(({ value, guideline }, idx) => (<tr key={idx}>
                <td className="py-1 px-2 align-middle bg-gray-100 border border-gray-300 text-left text-sm font-semibold">{idx + 1}</td>
                <td className="border border-gray-300 whitespace-normal overflow-wrap hover:bg-sky-100 text-left text-sm align-top cursor-text">
                    <InvisibleTextbox
                        value={value}
                        onChange={(new_value) => setValue(idx, new_value)} />
                </td>
                <td className="border border-gray-300 whitespace-normal overflow-wrap hover:bg-sky-100 text-left text-sm align-top cursor-text">
                    <InvisibleTextbox
                        value={guideline}
                        onChange={(new_guideline) => setGuideline(idx, new_guideline)} />
                </td>
                <td
                    className="p-2 w-4 align-middle bg-gray-100 text-gray-600 border border-gray-300 cursor-pointer hover:text-white hover:bg-sky-300"
                    onClick={() => deleteValue(idx)}>
                    <TrashIcon className="w-4 h-4" />
                </td>
            </tr>))}
            <tr>
                <td
                    className="py-1 px-2 bg-gray-100 border border-gray-300 text-center text-sm text-gray-600 align-middle cursor-pointer hover:text-white hover:bg-sky-300"
                    onClick={addValue}>
                    +
                </td>
            </tr>
        </tbody>
    </table>;
}