import { ReactNode } from "react";

type ProcessFlowHeadProps = {
    label: string;
    children?: ReactNode;
}

export function ProcessFlowHead({ label, children }: ProcessFlowHeadProps) {
    return <div className="flex flex-row items-center text-sm font-semibold text-gray-900">
        <div className="w-[4.5rem] flex-shrink-0 text-center py-2.5 rounded-xl bg-sky-100 border-sky-200 border-2">{label}</div>
        <div className="pl-6 border-2 border-white w-full text-sm font-semibold text-gray-900">{children}</div>
    </div>;
}

type ProcessFlowBodyProps = {
    children?: ReactNode;
}

export function ProcessFlowBody({ children }: ProcessFlowBodyProps) {
    return <div className="flex flex-row">
        <div className="relative w-[4.5rem] flex-shrink-0">
            <div className="absolute inset-y-0 left-1/2 transform -translate-x-1/2 w-0.5 bg-sky-200"></div>
        </div>
        <div className="pt-2 pb-6 pl-6 flex flex-col w-full max-w-full overflow-hidden">
            {children}
        </div>
    </div>
}