import { useEffect, useState } from "react";
import { hardReloadPage } from "../lib/utils";
import { Backend } from "../lib/backend";
import { BUILD_NUMBER } from "../lib/commit";
import { ConfirmModal } from "./ConfirmModal";

const STORAGE_KEY_NEXT_CHECK = "last-seen-version-next-check";

export type VersionCheckProps = {};

/** Utility class that contains static methods for checking version on server. */
class VersionChecker {

    /** "Static" constructor, called bellow */
    public static init(): void {
        const next_check = new Date(Date.now() - 1).toISOString();
        localStorage.setItem(STORAGE_KEY_NEXT_CHECK, next_check);
    }

    /** Return true if version mismatch was detected */
    public static async shouldShowWarning(): Promise<boolean> {
        // check if next check is due
        const next_check = localStorage.getItem(STORAGE_KEY_NEXT_CHECK) ?? new Date().toISOString();
        const next_check_d = new Date(next_check);
        const should_run_check = (new Date() >= next_check_d);
        if (!should_run_check) {
            return false;
        }

        // set next check to 5 minutes from now
        const next_check_new = new Date(Date.now() + 5 * 60 * 1000).toISOString();
        localStorage.setItem(STORAGE_KEY_NEXT_CHECK, next_check_new);

        // check if version on server has changed
        // i.e. is not the same as the version on client
        const version_on_server = (await Backend.getHealth()).commit_hash;
        const version_on_client = BUILD_NUMBER;

        return (version_on_server !== version_on_client);
    }

    /** Snooze checks for 1h */
    public static snooze(): void {
        // set next check to 1h from now
        const next_check = new Date(Date.now() + 60 * 60 * 1000).toISOString();
        localStorage.setItem(STORAGE_KEY_NEXT_CHECK, next_check);
    }
}
VersionChecker.init(); // effectively call "static constructor"



/** Simple component to show notifications when */
export function VersionCheck(props: VersionCheckProps) {

    // const { } = props;
    const texts = [
        "A new version of the application is available.",
        "We recommend that you reload this page."
    ];

    const [show_modal, setShowModal] = useState<boolean>(false);

    useEffect(() => {
        // will be called on every render
        VersionChecker.shouldShowWarning()
            .then(res => setShowModal(res));
    });

    const handleSnooze = () => {
        VersionChecker.snooze();
        setShowModal(false);
    };
    const handleReload = async (): Promise<void> => {
        setShowModal(false);
        await hardReloadPage();
    };

    return <div>
        <ConfirmModal
            open={show_modal}
            title={"New version available"}
            message={texts}
            cancel="Remind me again in 1 hour"
            confirm="Reload page"
            onClose={(result) => result ? handleReload() : handleSnooze()}
        /></div>;
}
