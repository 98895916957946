import * as hi from "@heroicons/react/24/outline";

import { LongText } from "./LongText";
import { classNames } from "../lib/utils";
import { useSelector } from "react-redux";
import { selectIsSidebarLarge } from "../lib/scraper.slice";
import { useEffect, useState, useCallback } from "react";
import { stringify as yaml_stringify } from "yaml";

type FullScreenTextProps = {
    title?: string;
    text: string;
    show: boolean;
    onToggle?: () => void;
    onClose: () => void;
}

export function FullScreenText(props: FullScreenTextProps) {
    const { title, text, show, onClose, onToggle } = props;
    const [is_debug_view, setIsDebugView] = useState(true);
    const [is_valid_json, setIsValidJson] = useState(false);
    const [yaml_text, setYamlText] = useState("");

    const is_sidebar_large = useSelector(selectIsSidebarLarge);

    // Reset states when text changes
    useEffect(() => {
        setIsDebugView(true);
        setIsValidJson(false);
        setYamlText("");
    }, [text]);

    useEffect(() => {
        try {
            const parsedJson = JSON.parse(text);
            const yaml = yaml_stringify(parsedJson);
            setYamlText(yaml);
            setIsValidJson(true);
        } catch (e) {
            setIsValidJson(false);
            return;
        }
    }, [text]);

    const onDebugView = useCallback(() => {
        setIsDebugView((prev_is_debug_view) => !prev_is_debug_view);
    }, []);

    // intercept the escape key and close the box
    // intercept the 'd' key and toggle the debug view
    useEffect(() => {
        const handleKey = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                onClose();
            } else if (event.key === "d" || event.key === "D") {
                onDebugView();
            }
        };

        window.addEventListener("keydown", handleKey);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener("keydown", handleKey);
        };
    }, [onClose, onDebugView]); // Re-run the effect if onClose changes

    if (!show) {
        return null;
    }

    // intercept click on the outside and close the box
    const onBorderClick = () => {
        onClose();
    }

    // if the user clicks on the box, don't close it
    const onBoxClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
    };

    const onCopy = () => {
        navigator.clipboard.writeText((is_debug_view && is_valid_json) ? yaml_text : text);
    }

    const onDownload = () => {
        const dataUri = 'data:application/json;charset=utf-8,' + encodeURIComponent((is_debug_view && is_valid_json) ? yaml_text : text);
        let downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href", dataUri);
        downloadAnchorNode.setAttribute("download", (title || "data.txt") + ((is_debug_view && is_valid_json) ? ".yaml" : ""));
        document.body.appendChild(downloadAnchorNode);
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    }

    return <div className={classNames("fixed inset-0 z-50", is_sidebar_large ? "lg:left-64" : "lg:left-20")} onClick={onBorderClick}>
        <div className="absolute inset-x-11 inset-y-2 bg-white opacity-95 border-2 rounded-lg shadow-lg overflow-y-auto overflow-x-auto" onClick={onBoxClick}>
            <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                {onToggle && <button
                    type="button"
                    className="mr-4 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                    onClick={onToggle}
                    title="Toggle View"
                >
                    <span className="sr-only">Toggle View</span>
                    <hi.ArrowPathIcon className="h-6 w-6" aria-hidden="true" />
                </button>}
                <button
                    type="button"
                    className="mr-4 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                    onClick={onDebugView}
                    title="Toggle JSON/YAML View (press 'd' to toggle)"
                >
                    <span className="sr-only">Toggle JSON/YAML View</span>
                    <hi.ArrowsRightLeftIcon className="h-6 w-6" aria-hidden="true" />
                </button>
                <button
                    type="button"
                    className="mr-4 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                    onClick={onDownload}
                    title="Download"
                >
                    <span className="sr-only">Download</span>
                    <hi.ArrowDownTrayIcon className="h-6 w-6" aria-hidden="true" />
                </button>
                <button
                    type="button"
                    className="mr-4 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                    onClick={onCopy}
                    title="Copy to clipboard"
                >
                    <span className="sr-only">Copy to clipboard</span>
                    <hi.ClipboardDocumentIcon className="h-6 w-6" aria-hidden="true" />
                </button>
                <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                    onClick={onClose}
                    title="Close (press 'Escape' to close)"
                >
                    <span className="sr-only">Close</span>
                    <hi.XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
            </div>
            <div className="p-4">
                <div className="text-sm font-mono whitespace-pre-wrap font-light">
                    <LongText text={(is_debug_view && is_valid_json) ? yaml_text : text} />
                </div>
            </div>
        </div>
    </div>;
}

type FullScreenProps = {
    children: any;
    show: boolean;
    opacity?: boolean; // default is true
    show_close_button?: boolean; // default is true
    onClose?: () => void;
}

export function FullScreen(props: FullScreenProps) {
    const { children, show, opacity, onClose } = props;

    const is_sidebar_large = useSelector(selectIsSidebarLarge);
    const show_opacity = opacity === undefined || opacity === true;
    const show_close_button = props.show_close_button === undefined || props.show_close_button === true;

    // intercept the escape key and close the box
    useEffect(() => {
        const handleEsc = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                if (onClose) { onClose(); }
            }
        };

        window.addEventListener('keydown', handleEsc);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, [onClose]); // Re-run the effect if onClose changes

    if (!show) {
        return null;
    }

    // intercept click on the outside and close the box
    const onBorderClick = () => {
        if (onClose) { onClose(); }
    }

    // if the user clicks on the box, don't close it
    const onBoxClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
    };

    return <div className={classNames("fixed inset-0 z-50", is_sidebar_large ? "lg:left-64" : "lg:left-20")} onClick={onBorderClick}>
        <div className={classNames("mt-16 mb-2 absolute inset-x-11 inset-y-2 bg-white border-2 rounded-lg shadow-lg overflow-y-auto overflow-x-auto", show_opacity ? "bg-opacity-95" : "")} onClick={onBoxClick}>
            <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                {show_close_button && <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                    onClick={onClose}
                >
                    <span className="sr-only">Close</span>
                    <hi.XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>}
            </div>
            <div className="p-4">
                <div className="text-sm">
                    {children}
                </div>
            </div>
        </div>
    </div>;
}

type SubScreenProps = {
    children: any;
    show: boolean;
    onClose?: () => void;
}

export function SubScreen(props: SubScreenProps) {
    const { children, show, onClose } = props;

    const is_sidebar_large = useSelector(selectIsSidebarLarge);

    // intercept the escape key and close the box
    useEffect(() => {
        const handleEsc = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                if (onClose) { onClose(); }
            }
        };

        window.addEventListener('keydown', handleEsc);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, [onClose]); // Re-run the effect if onClose changes

    if (!show) {
        return null;
    }

    // intercept click on the outside and close the box
    const onBorderClick = () => {
        if (onClose) { onClose(); }
    }

    // if the user clicks on the box, don't close it
    const onBoxClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
    };

    return <div className={classNames("fixed inset-0 z-50", is_sidebar_large ? "lg:left-64" : "lg:left-20")} onClick={onBorderClick}>
        <div className={classNames("absolute lg:mt-16 inset-0 bg-white overflow-y-auto overflow-x-auto")} onClick={onBoxClick}>
            {children}
        </div>
    </div>;
}

type SubSubScreenProps = {
    children: any;
    show: boolean;
    onClose?: () => void;
}

export function SubSubScreen(props: SubSubScreenProps) {
    const { children, show, onClose } = props;

    const is_sidebar_large = useSelector(selectIsSidebarLarge);

    // intercept the escape key and close the box
    useEffect(() => {
        const handleEsc = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                if (onClose) { onClose(); }
            }
        };

        window.addEventListener('keydown', handleEsc);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, [onClose]); // Re-run the effect if onClose changes

    if (!show) {
        return null;
    }

    // intercept click on the outside and close the box
    const onBorderClick = () => {
        if (onClose) { onClose(); }
    }

    // if the user clicks on the box, don't close it
    const onBoxClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
    };

    return <div className={classNames("lg:mt-32 fixed inset-0 z-50", is_sidebar_large ? "lg:left-64" : "lg:left-20")} onClick={onBorderClick}>
        <div className={classNames("absolute inset-0 bg-white overflow-y-auto overflow-x-auto")} onClick={onBoxClick}>
            {children}
        </div>
    </div>;
}