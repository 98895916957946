type ReadOnlyTextboxProps = {
    text: string;
}

export function ReadOnlyTextbox(props: ReadOnlyTextboxProps) {
    const { text } = props;

    return <div className="flex">
        <input
            type="text"
            value={text}
            readOnly
            className="flex-grow px-2 py-1 rounded-md border-gray-300 outline-none"
        />
    </div>;
}