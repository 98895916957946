import {
    useEffect,
    useState
} from "react";
import { useSelector } from "react-redux";

import * as hi from "@heroicons/react/24/outline";

import * as t from "../lib/types"
import { Backend } from "../lib/backend";
import { selectIsSidebarLarge } from "../lib/scraper.slice";
import { classNames } from "../lib/utils";

import { LoadingSpinnerLimit } from "../components/LoadingSpinner";
import { LongText } from "../components/LongText";
import {
    FullScreen,
    FullScreenText
} from "../components/FullScreen";
import { Pagination } from "../components/Pagination";
import { DemoTable } from "../components/ItemTables";

const limit = 10;

export function AdminDemoUsage() {
    const is_sidebar_large = useSelector(selectIsSidebarLarge);

    const [demo_usage_list, setDemoUsageList] = useState<t.IDebugDemoUsageList | undefined>(undefined);
    const [offset, setOffset] = useState(0);
    const [full_screen_text, setFullScreenText] = useState<string | undefined>(undefined);
    const [full_screen_fields, setFullScreenFields] = useState<t.IContextField[] | undefined>(undefined);
    const [full_screen_records, setFullScreenRecords] = useState<t.IRecord[] | undefined>(undefined);

    useEffect(() => {
        setDemoUsageList(undefined);
        Backend.getDemoUsage({ offset, limit }).then((demo_usage_list) => {
            setDemoUsageList(demo_usage_list);
        });
    }, [offset]);

    return <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        <div className="m-16 mb-6">
            <h2 className="text-xl font-semibold leading-7 text-gray-600 sm:truncate sm:text-3xl sm:tracking-tight">
                Demo Usage
            </h2>
            <div className="pt-5 border-b-4 border-sky-600" />
        </div>

        <div className="flex flex-col px-10 py-5">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Visitor
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Location
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Fields
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Example
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Record Example
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Date
                            </th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                        {demo_usage_list && demo_usage_list.usages.map((usage, idx) => (
                            <tr key={idx}>
                                <td className="whitespace-nowrap py-4 pl-2 text-sm font-semibold text-gray-500 sm:pl-3 align-top">
                                    {idx + offset + 1}
                                </td>
                                <td className="px-3 py-4 text-sm text-gray-500 align-top cursor-pointer" onClick={() => setFullScreenText(JSON.stringify(usage.visitor_details, null, 2))}>
                                    Details <hi.ArrowTopRightOnSquareIcon className="h-4 w-4 ml-1 text-gray-400" />
                                </td>
                                <td className="px-3 py-4 text-sm text-gray-500 align-top">
                                    {usage.visitor_details.country || "unknown"},<br />{usage.visitor_details.city || "unknown"},<br />{usage.visitor_details.organization || "unknown"}
                                </td>
                                <td className="px-3 py-4 text-sm text-gray-500 align-top cursor-pointer" onClick={() => setFullScreenText(JSON.stringify(usage.fields, null, 2))}>
                                    {usage.fields.map((field, idx) => (field.name)).join(", ")}
                                </td>
                                <td className="px-3 py-4 text-sm text-gray-500 align-top cursor-pointer" onClick={() => setFullScreenText(usage.example_text)}>
                                    <LongText text={usage.example_text} remove_whitespace={true} line_limit={10} />
                                </td>
                                <td className="px-3 py-4 text-sm text-gray-500 align-top cursor-pointer"
                                    onClick={() => { setFullScreenRecords(usage.example_records); setFullScreenFields(usage.fields); }}>
                                    Show <hi.ArrowTopRightOnSquareIcon className="h-4 w-4 ml-1 text-gray-400" />
                                </td>
                                <td className="px-3 py-4 text-xs text-gray-500 align-top">
                                    {new Date(usage.created_at).toLocaleString()}
                                </td>
                            </tr>
                        ))}
                        {!demo_usage_list && <tr>
                            <td className="whitespace-nowrap py-4 pl-2 text-sm font-semibold text-gray-500 sm:pl-3 align-top" colSpan={4}>
                                <LoadingSpinnerLimit />
                            </td>
                        </tr>}
                    </tbody>
                </table>
                <Pagination offset={offset} limit={limit} total={demo_usage_list?.total || 0} setOffset={setOffset} />
            </div>
            <FullScreenText
                text={full_screen_text || ""}
                show={full_screen_text !== undefined}
                onClose={() => setFullScreenText(undefined)} />
            <FullScreen
                show={full_screen_records !== undefined}
                onClose={() => setFullScreenRecords(undefined)}>
                <DemoTable fields={full_screen_fields || []} records={full_screen_records || []} />
            </FullScreen>
        </div>
    </div >;
};
