import { useState } from "react";

import CodeMirror from "@uiw/react-codemirror";
import { json, jsonParseLinter } from "@codemirror/lang-json";
import { linter } from "@codemirror/lint";

import {
    IContextField,
    ILookupTableBase
} from "../lib/types";
import { Button } from "./Button";

type HierarchicalTableProps = {
    fields: IContextField[];
    disabled: boolean;
    lookup_tables: ILookupTableBase[];
    setFields: (fields: IContextField[]) => void;
}

export function HierarchicalFieldsTable(props: HierarchicalTableProps) {
    const { fields, disabled, setFields } = props;

    const [is_changed, setIsChanged] = useState<boolean>(false);
    const [fields_str, setFieldsStr] = useState<string>(JSON.stringify(fields, null, 2));
    const [error_message, setErrorMessage] = useState<string | undefined>(undefined);

    const updateFields = () => {
        setErrorMessage(undefined);
        try {
            const new_fields = JSON.parse(fields_str);
            if (setFields) {
                setFields(new_fields);
            }
            setIsChanged(false);
        } catch (e: any) {
            setErrorMessage(e.message);
        }
    }

    return <div className="flex flex-col w-full gap-y-4">
        <div className="w-full shadow border border-gray-300">
            <CodeMirror
                value={fields_str}
                height="500px"
                theme={"light"}
                extensions={[json(), linter(jsonParseLinter())]}
                readOnly={disabled}
                onChange={(value) => {
                    setFieldsStr(value);
                    setIsChanged(true);
                }} />
        </div>
        <Button text="Update" disabled={!is_changed} highlight={true} onClick={updateFields} />
        {error_message && <div className="text-red-600">{error_message}</div>}
    </div>;
}