import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { selectIsSidebarLarge } from "../lib/scraper.slice";
import { classNames } from "../lib/utils";
import { EXTRACT_JOB_STATUS, extract_job_statuses } from "../lib/consts";
import { ExtractJobStatus } from "../lib/backend/extractions.types.generated";

import { ExtractJobs } from "../components/ExtractJobs";
import { DropdownMenu } from "../components/DropdownMenu";

export function AdminExtractionJobs() {
    const navigate = useNavigate();
    const { status } = useParams<{ status: ExtractJobStatus | "combined" }>();
    const is_sidebar_large = useSelector(selectIsSidebarLarge);


    return <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        <div className="m-16 mb-6">
            <div className="flex flex-row items-center gap-x-4">
                <h2 className="text-xl font-semibold leading-7 text-gray-600 sm:truncate sm:text-3xl sm:tracking-tight">
                    Extraction Jobs - {status}
                </h2>
                <div className="flex-grow" />
                <DropdownMenu title="Select status" items={extract_job_statuses.map((s) => ({ title: s, onClick: () => { navigate(`/admin/extraction-jobs/${s}`); } }))} />
            </div>
            <div className="pt-5 border-b-4 border-sky-600" />
        </div>

        {status !== "combined" && <div className="flex flex-col m-10">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <ExtractJobs type="status" statuses={status ? [status] : undefined} limit={20} />
            </div>
        </div>}

        {status === "combined" && <div className="flex flex-col m-10">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg mb-10">
                <ExtractJobs type="status" statuses={[EXTRACT_JOB_STATUS.running]} limit={100} />
            </div>
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <ExtractJobs type="status" statuses={[EXTRACT_JOB_STATUS.done, EXTRACT_JOB_STATUS.error]} limit={10} />
            </div>
        </div>}

    </div >;
};
