import { classNames } from "../lib/utils";

type CopyTextboxProps = {
    text: string;
    email_pretty_name?: string;
    is_email?: boolean;
}

export function CopyTextbox(props: CopyTextboxProps) {
    const { text, email_pretty_name, is_email } = props;

    const onClick = () => {
        if (is_email === true) {
            if (email_pretty_name) {
                window.location.href = `mailto:"Extrakt.AI: ${email_pretty_name}" <${text}>`;
            } else {
                window.location.href = `mailto:${text}`;
            }
        }
    };

    const onCopy = () => {
        if (email_pretty_name) {
            navigator.clipboard.writeText(`Extrakt.AI: ${email_pretty_name} <${text}>`);
        } else {
            navigator.clipboard.writeText(text);
        }
    };

    return <div className="flex text-sm">
        <input
            type="text"
            value={text}
            readOnly
            className={classNames(
                "flex-grow text-sm px-2 py-1 rounded-l-md border-gray-300 outline-none truncate",
                is_email === true ? "underline cursor-pointer" : ""
            )}
            onClick={onClick}
        />
        <button
            onClick={onCopy}
            className="px-4 py-2 bg-sky-600 text-white rounded-r-md hover:bg-sky-500"
        >
            Copy
        </button>
    </div>;
}