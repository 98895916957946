import { IApiClient } from "./rest_client";
import { IStripe, Stripe } from "./stripe.callers.generated";
import { Extractions, IExtractions } from "./extractions.callers.generated";

export interface IBackend {
    stripe: IStripe;
    extractions: IExtractions;
    // add new callers here
}

class Backend implements IBackend {

    api_client: IApiClient;
    stripe: IStripe;
    extractions: IExtractions;
    // add new callers here

    constructor(api_client: IApiClient) {
        this.api_client = api_client;
        this.stripe = new Stripe(api_client);
        this.extractions = new Extractions(api_client);
        // add new callers here
    }
}

/** Simple factory function for backend */
export function createBackend(api_client: IApiClient): IBackend {
    return new Backend(api_client);
}
