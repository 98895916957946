export function SecurityDocs() {
    return <div className="flex items-center justify-center">
        <div className="w-full flex flex-row">
            <div className="p-4">
                <img
                    className="border border-gray-400 shadow-xl w-12"
                    src="/security/icon_data_processing_whitepaper.png"
                    alt="Data Processing whitepaper" />
            </div>
            <div className="flex-grow flex items-center text-sm font-semibold">
                <a href="/security/extraktai_data_processing_whitepaper.pdf" target="_blank">
                    Data Processing whitepaper
                </a>
            </div>
        </div>
        <div className="w-full flex flex-row">
            <div className="p-4">
                <img
                    className="border border-gray-400 shadow-xl w-12"
                    src="/security/icon_model_training_and_customer_data.png"
                    alt="Data Processing whitepaper" />
            </div>
            <div className="flex-grow flex items-center text-sm font-semibold">
                <a href="/security/extraktai_model_training_and_customer_data.pdf" target="_blank">
                    Model Training and Customer Data
                </a>
            </div>
        </div>
    </div>;
}