import {
    Fragment,
    useState
} from "react";

import CodeMirror from "@uiw/react-codemirror";
import { json, jsonParseLinter } from "@codemirror/lang-json";
import { linter } from "@codemirror/lint";

import { BackendObj } from "../lib/backend";
import { useSelector } from "react-redux";
import {
    selectIsSidebarLarge,
    selectMemberships
} from "../lib/scraper.slice";
import {
    ORG_ROLES,
    ORG_TYPES
} from "../lib/consts";
import { classNames } from "../lib/utils";

import { Button } from "../components/Button";
import { OrgPill } from "../components/OrgPill";
import { ITemplateExport } from "../lib/backend/extractions.types.generated";

export function AdminImportTemplate() {
    const is_sidebar_large = useSelector(selectIsSidebarLarge);
    const memberships = useSelector(selectMemberships);
    // default is personal org, if not available, use first org
    const default_org_uuid =
        memberships.find((membership) => membership.org.type === ORG_TYPES.personal)?.org.uuid ||
        memberships[0].org.uuid || "";
    // get list of admin orgs, since only admin can create or edit templates
    const admin_orgs = memberships.filter((m) => m.role === ORG_ROLES.admin).map((m) => m.org);

    const [message, setMessage] = useState<string | undefined>(undefined);
    const [org_uuid, setOrgUuid] = useState<string>(default_org_uuid);
    const [template_def, setTemplateDef] = useState<string>("");
    const [is_loading, setIsLoading] = useState<boolean>(false);

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            const file = event.dataTransfer.files[0];
            const reader = new FileReader();
            reader.onload = (e) => {
                const text = e.target?.result;
                setTemplateDef(text as string);
                setMessage(undefined);
            };
            reader.readAsText(file);
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const createTemplateInner = async (template_def_json: ITemplateExport) => {
        await BackendObj.extractions.importTemplate({
            data: template_def_json,
            org_uuid: default_org_uuid
        });
    };
    const createTemplate = () => {
        try {
            const template_def_json: ITemplateExport = JSON.parse(template_def);
            setIsLoading(true);
            createTemplateInner(template_def_json)
                .then(() => {
                    setMessage("Success");
                    setIsLoading(false);
                })
                .catch((e) => {
                    setMessage(e.message);
                    setIsLoading(false);
                });
        } catch (e) {
            setMessage("Invalid JSON");
            setIsLoading(false);
        }
    };

    return <Fragment>
        <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <div className="m-16 mb-6">
                <h2 className="text-xl font-semibold leading-7 text-gray-600 sm:truncate sm:text-3xl sm:tracking-tight">
                    Import Template
                </h2>
                <div className="pt-5 border-b-4 border-sky-600" />
            </div>

            <div className="flex flex-col px-10 py-5">
                <div className="w-full">
                    <div className="flex gap-2">
                        <span>Organization:</span>{admin_orgs.map((org, idx) => (<OrgPill key={idx} name={org.name} type={org.type} selected={org.uuid === org_uuid} onClick={() => setOrgUuid(org.uuid)} />))}
                    </div>
                    <div className="mt-4 shadow border border-gray-300" onDrop={handleDrop} onDragOver={handleDragOver}>
                        <CodeMirror
                            value={template_def}
                            height="600px"
                            theme={"light"}
                            extensions={[json(), linter(jsonParseLinter())]}
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                            onChange={(value) => {
                                setTemplateDef(value);
                                setMessage(undefined);
                            }} />
                    </div>
                    {message && <div className={classNames("p-4 rounded-md text-center mb-4 w-full",
                        message === "Success" ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800"
                    )}>{message}</div>}
                    <div className="mt-4 text-right">
                        <Button
                            onClick={createTemplate} text="Create Template" disabled={is_loading} loading={is_loading}
                            highlight={true} />
                    </div>
                </div>
            </div>
        </div>
    </Fragment >;
};
