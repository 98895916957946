import { LockClosedIcon } from "@heroicons/react/24/solid";
import { Tooltip } from "react-tooltip";

type WizardButtonIconProps = {
    title: string;
    icon: any;
    locked?: boolean;
    tooltip?: string;
    onClick?: () => void;
};

export function WizardButtonIcon(props: WizardButtonIconProps) {
    const locked = props.locked === true;

    return <div className="m-4 flex flex-col items-center group cursor-pointer"
        onClick={props.onClick}
        data-tooltip-id={props.title}
        data-tooltip-html={props.tooltip}
        >
        <div className="relative flex flex-col p-8 items-center w-48 h-36 rounded-lg border border-gray-300 shadow overflow-clip bg-gray-100 group-hover:bg-sky-100">
            <props.icon className="h-24 w-24 text-slate-400" aria-hidden="true" />
            {locked && (
                <div className="absolute bottom-2 right-2">
                    <LockClosedIcon className="h-6 w-6 text-sky-600" />
                </div>
            )}
        </div>
        <p className="mt-2 text-base font-medium text-gray-900">{props.title}</p>
        <Tooltip id={props.title} place="bottom" />
    </div>;
}


type WizardButtonImageProps = {
    title: string;
    image: string;
    onClick?: () => void;
};

export function WizardLogoButtonImage(props: WizardButtonImageProps) {
    return <div className="m-4 flex flex-col items-center group cursor-pointer" onClick={props.onClick}>
        <div className="flex items-center justify-center w-48 h-36 rounded-lg border border-gray-300 shadow bg-gray-100 group-hover:bg-sky-100">
            <img className="w-24 cursor-pointer group-hover:opacity-75" alt={props.title} src={props.image} />
        </div>
        <p className="mt-2 text-base font-medium text-gray-900">{props.title}</p>
    </div>;
}

export function WizardDocumentButtonImage(props: WizardButtonImageProps) {
    return <div className="m-4 flex flex-col items-center group cursor-pointer" onClick={props.onClick}>
        <div className="w-48 h-36 rounded-lg border border-gray-300 shadow overflow-clip group-hover:bg-sky-100">
            <img className="cursor-pointer object-cover opacity-75 group-hover:opacity-65" alt={props.title} src={props.image} />
        </div>
        <p className="mt-2 text-base font-medium text-gray-900">{props.title}</p>
    </div>;
}
