import {
    PlusIcon,
    TrashIcon
} from "@heroicons/react/24/outline";

import {
    CONTEXT_FACT_ENTITY,
    CONTEXT_FACT_ENTITIES
} from "../lib/consts";
import { IContextFact } from "../lib/types";

import { Button } from "./Button";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import { Tooltip } from "react-tooltip";

type TemplateFactsProps = {
    facts: IContextFact[];
    setFacts: (facts: IContextFact[]) => void;
};

export function TemplateFacts(props: TemplateFactsProps) {
    const { facts, setFacts } = props;

    const addFact = (entity?: string) => {
        setFacts([...facts, { entity: entity ? entity : "", role: "" }]);
    };

    const delFact = (idx: number) => {
        setFacts(facts.filter((_, i) => i !== idx));
    }

    const editFactEntity = (idx: number, entity: string) => {
        const newFacts = [...facts];
        newFacts[idx].entity = entity;
        setFacts(newFacts);
    };

    const editFactRole = (idx: number, role: string) => {
        const newFacts = [...facts];
        newFacts[idx].role = role;
        setFacts(newFacts);
    };

    return (
        <div className="max-w-5xl sm:items-start sm:gap-4 sm:py-6 text-gray-600 text-sm">
            <div className="pb-4 flex flex-row items-center">
                <p className="pr-2 font-medium leading-6 text-gray-900">
                    Static Facts
                </p>
                <QuestionMarkCircleIcon
                    className="w-5 h-5 ml-1 text-gray-400"
                    data-tooltip-id="facts-tooltip-id"
                    data-tooltip-html={
                        `<p class="pb-4 max-w-sm">
                            Statements about entities that are always true when extracting this template.
                        </p>
                        <p class="pb-4 max-w-sm">
                            For example, when creating a template for processing supplier invoices, you might
                            include a static fact stating the name of your company and affirming that your company
                            is always the recipient of the invoice.
                        </p>
                        <p class="max-w-sm">
                            Similarly, when processing emails, you can define the role of the individual who forwarded
                            the email to the system. For instance, in the context of processing supplier invoices, a static
                            fact might state that the person forwarding the email is always considered the approver.
                        </p>`
                    }
                />
                <Tooltip id="facts-tooltip-id" />
                <div className="grow" />
                <Button icon={PlusIcon} text="Recipient Role" onClick={() => { addFact(CONTEXT_FACT_ENTITY.recipient); }} />
                <Button icon={PlusIcon} text="New Fact" onClick={() => { addFact(); }} />
            </div>
            {facts.map((fact, idx) => (<div key={idx} className="py-1 grid grid-cols-3 items-center">
                <div className="h-9">
                    {fact.entity === CONTEXT_FACT_ENTITY.sender && <label
                        htmlFor="title"
                        className="my-2 text-sm font-medium leading-6 text-gray-900"
                        title="Person from outside that sent the email to the recipient">
                        Email Sender
                    </label>}
                    {fact.entity === CONTEXT_FACT_ENTITY.recipient && <label
                        htmlFor="title"
                        className="my-2 text-sm font-medium leading-6 text-gray-900"
                        title="Person who forwarded the email to the system">
                        Email Recipient
                    </label>}
                    {!CONTEXT_FACT_ENTITIES.includes(fact.entity) && <input
                        type="text"
                        className="block w-full h-9 shadow-sm sm:text-sm focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
                        placeholder="entity"
                        value={fact.entity}
                        onChange={(e) => { editFactEntity(idx, e.target.value); }} />}
                </div>
                <div className="ml-2 col-span-2 flex h-9">
                    <input
                        type="text"
                        className="block w-full shadow-sm sm:text-sm focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
                        placeholder="role"
                        value={fact.role}
                        onChange={(e) => { editFactRole(idx, e.target.value); }} />
                    <Button icon={TrashIcon} onClick={() => { delFact(idx); }} />
                </div>
            </div>))}
        </div>
    );
}