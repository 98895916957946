import {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems
} from '@headlessui/react'
import {
    ChevronDownIcon,
    LockClosedIcon
} from '@heroicons/react/20/solid'
import { classNames } from '../lib/utils';

export interface IButtonMenuItem {
    title: string;
    separator?: boolean;
    admin_only?: boolean;
    onClick: () => void;
}

type ButtonMenuProps = {
    title: string;
    items: IButtonMenuItem[];
    highlight?: boolean;
    disabled?: boolean;
};

export function ButtonMenu(props: ButtonMenuProps) {
    const { title, items, highlight, disabled } = props;

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <MenuButton disabled={disabled} className={classNames(
                    "inline-flex w-full justify-center gap-x-1.5 rounded-md px-3 py-1.5 text-sm font-semibold shadow-sm ring-1 ring-inset whitespace-nowrap",
                    highlight ? "bg-sky-600 text-white ring-sky-600 hover:bg-sky-500" : "bg-white text-gray-900 ring-gray-300 hover:bg-gray-50"
                )}>
                    {title}
                    <ChevronDownIcon aria-hidden="true" className={classNames("-mr-1 w-5 h-5", highlight ? "text-white" : "text-gray-400")} />
                </MenuButton>
            </div>

            <MenuItems
                transition
                className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
                <div className="py-1">
                    {items.map(({ title, separator, admin_only, onClick }, idx) => (
                        <MenuItem key={idx}>
                            <a // eslint-disable-line
                                href="#"
                                className={classNames(
                                    "block px-4 py-2 text-sm flex flex-row items-center",
                                    separator ? "border-t border-gray-200" : "",
                                    admin_only ? "text-gray-400" : "text-gray-900",
                                    "data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                                )}
                                onClick={onClick}
                            >
                                {admin_only && <LockClosedIcon className="w-4 h-4 mr-2" />}
                                {title}
                            </a>
                        </MenuItem>
                    ))}
                </div>
            </MenuItems>
        </Menu>
    )
}
