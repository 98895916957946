import {
    createRef,
    Fragment,
    RefObject,
    useEffect,
    useState
} from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";

import {
    ArrowLeftIcon,
    MinusIcon
} from "@heroicons/react/24/outline";

import {
    selectEnv,
    selectUser,
    selectIsSidebarLarge,
    selectMemberships
} from "../lib/scraper.slice";
import {
    ORG_ROLES,
    ORG_TYPES
} from "../lib/consts";
import {
    IExcelArraySheet,
    ILookupTableDetails,
    LookupTableUpdateType
} from "../lib/types";
import * as c from "../lib/consts";
import {
    classNames,
    getExcelColumnName,
    setDocumentTitle
} from "../lib/utils";
import { Backend } from "../lib/backend";

import {
    LoadingSpinner
} from "../components/LoadingSpinner";
import { Button } from "../components/Button";
import { OrgPill } from "../components/OrgPill";
import { Dropdown } from "../components/Dropdown";
import { Sheet } from "../components/Sheets";
import { LookupTableDropArea } from "./UploadLookupTable";
import { CopyTextbox } from "../components/CopyTextbox";
import { Checkbox } from "../components/Checkbox";
import { ConfirmModal } from "../components/ConfirmModal";

export function NewLookupTable() {
    const navigate = useNavigate();

    const is_sidebar_large = useSelector(selectIsSidebarLarge);
    const env = useSelector(selectEnv);
    const user = useSelector(selectUser);

    const memberships = useSelector(selectMemberships);
    const all_orgs = memberships.map((m) => m.org);
    // get list of admin or editor orgs, since only admin can create or edit templates
    const admin_orgs = memberships.filter((m) => (m.role === ORG_ROLES.admin)).map((m) => m.org);
    // default is business org, if not available, use first org
    const default_org_uuid =
        memberships.find((membership) => membership.org.type === ORG_TYPES.business && membership.role === ORG_ROLES.admin)?.org.uuid ||
        memberships.find((membership) => membership.org.type === ORG_TYPES.personal)?.org.uuid ||
        memberships[0].org.uuid ||
        "";
    // check if we have a non-personal org, to make it worthwhile to show org selector
    const is_business_orgs = admin_orgs.some((org) => org.type === ORG_TYPES.business);

    // check if user is admin
    const is_admin = user.role === c.USER_ROLES.admin;

    const { lookup_table_uuid } = useParams<{ lookup_table_uuid: string | undefined }>();

    const [is_init, setIsInit] = useState<boolean>(false);
    const [is_changed, setIsChanged] = useState<boolean>(false);
    const [filename, setFilename] = useState<string | undefined>(undefined);
    const [name, setName] = useState<string>("");
    const [org_uuid, setOrgUuid] = useState<string>(default_org_uuid);
    const [headers, setHeaders] = useState<string[]>([""]);
    const [new_header, setNewHeader] = useState<string | undefined>(undefined);
    const [header_refs, setHeaderRefs] = useState<RefObject<HTMLTableCellElement>[]>([]);
    const [current_header_idx, setCurrentHeaderIdx] = useState<number>(-1);
    const [details, setDetails] = useState<ILookupTableDetails>({ rest_api_update: false });
    const [email_address, setEmailAddress] = useState<string | undefined>(undefined);
    const [sheets, setSheets] = useState<IExcelArraySheet[] | undefined>(undefined);
    const [selected_sheet_idx, setSelectedSheetIdx] = useState<number>(-1);
    const [selected_sheet, setSelectedSheet] = useState<IExcelArraySheet | undefined>(undefined);
    const [is_saving, setIsSaving] = useState<boolean>(false);
    const [is_valid_admin_json, setIsValidAdminJSON] = useState<boolean>(true);
    const [is_back_modal_open, setIsBackModalOpen] = useState<boolean>(false);

    const is_edit = lookup_table_uuid !== undefined;

    useEffect(() => {
        if (is_edit) {
            setIsInit(false);
            Backend.getLookupTable({ lookup_table_uuid }).then((lookup_table) => {
                setFilename(undefined);
                setName(lookup_table?.name || "");
                setOrgUuid(lookup_table?.org_uuid || default_org_uuid);
                setHeaders(lookup_table?.headers || []);
                setNewHeader(undefined);
                setCurrentHeaderIdx(-1);
                setDetails(lookup_table?.details || { rest_api_update: false });
                setEmailAddress(lookup_table?.email_address);
                setSheets(undefined);
                setSelectedSheetIdx(-1);
                setSelectedSheet(undefined);
                setIsSaving(false);
                setIsInit(true);
                setIsChanged(false);
            });
        } else {
            // reset state
            setIsInit(true);
            setFilename(undefined);
            setName("");
            setOrgUuid(default_org_uuid);
            setHeaders([""]);
            setNewHeader(undefined);
            setCurrentHeaderIdx(-1);
            setDetails({ rest_api_update: false });
            setEmailAddress(undefined);
            setSheets(undefined);
            setSelectedSheetIdx(-1);
            setSelectedSheet(undefined);
            setIsSaving(false);
            setIsChanged(false);
        }
    }, [lookup_table_uuid, default_org_uuid, is_edit]);

    useEffect(() => {
        setHeaderRefs(headers.map(() => createRef()));
    }, [headers, headers.length]);

    useEffect(() => {
        if (current_header_idx !== -1 && header_refs[current_header_idx]) {
            const element = header_refs[current_header_idx]?.current;
            if (element) {
                element.focus();
                window.getSelection()?.selectAllChildren(element);
                window.getSelection()?.collapseToEnd();
            }
        }
    }, [headers, headers.length, header_refs, header_refs.length, current_header_idx]);

    useEffect(() => {
        if (lookup_table_uuid === undefined) {
            setDocumentTitle("Create Lookup Table", env);
        } else {
            setDocumentTitle(`Edit Lookup Table - ${name}`, env);
        }
    }, [lookup_table_uuid, name, env]);

    useEffect(() => {
        if (name.length === 0 && filename !== undefined) {
            setName(filename);
        }
    }, [filename, name]);

    useEffect(() => {
        if (sheets && selected_sheet_idx !== -1) {
            setSelectedSheet(sheets[selected_sheet_idx]);
        } else {
            setSelectedSheet(undefined);
        }
    }, [sheets, selected_sheet_idx]);

    const handleParse = (filename: string, sheets: IExcelArraySheet[]) => {
        setFilename(filename);
        setSheets(sheets);
        if (!is_edit) {
            setHeaders(sheets[0].data[0] || []);
        }
        setSelectedSheetIdx(0);
        setIsChanged(true);
    };

    const handleBlur = (e: any, idx: number) => {
        setCurrentHeaderIdx(-1);
        const new_headers = [...headers];
        if (new_header !== undefined && new_headers[idx] !== new_header) {
            new_headers[idx] = new_header;
            setNewHeader(undefined);
        }
        setHeaders(new_headers);
        setIsChanged(true);
    };

    const handleInput = (e: any, idx: number) => {
        setNewHeader(e.target.innerText.trim());
        setIsChanged(true);
    };

    const handleKeyDown = (e: any, idx: number) => {
        if (idx === headers.length - 1) {
            if (e.key === "Tab" && !e.shiftKey) {
                e.preventDefault();
                addHeader();
            } else if (e.key === "Enter") {
                e.preventDefault();
                addHeader();
            }
        }
    }

    const addHeader = () => {
        const new_header = [...headers, ""];
        setHeaders(new_header);
        setCurrentHeaderIdx(new_header.length - 1);
        setIsChanged(true);
    }

    const deleteHeader = (idx: number) => {
        const new_headers = [...headers];
        new_headers.splice(idx, 1);
        setHeaders(new_headers);
        setIsChanged(true);
    }

    const handleFocus = (e: any, idx: number) => {
        setCurrentHeaderIdx(idx);
    }

    const handleDetailFieldChange = (field: "email_update" | "email_attachment_format" | "rest_api_update", value: string | boolean) => {
        if (field === "email_update") {
            if (value === "disabled") {
                setDetails({
                    ...details,
                    email_update: undefined
                });
            } else {
                setDetails({
                    ...details,
                    email_update: value as LookupTableUpdateType,
                    email_attachment_format: details.email_attachment_format || "tsv"
                });
            }
        } else if (field === "email_attachment_format") {
            setDetails({
                ...details,
                email_attachment_format: value as "tsv"
            });
        } else if (field === "rest_api_update") {
            setDetails({
                ...details,
                rest_api_update: value as boolean
            });
        }
    }

    const handleCreate = () => {
        if (!is_edit) {
            setIsSaving(true);
            Backend.createLookupTable(
                { org_uuid, name, headers, details, sheet: selected_sheet }
            ).then(({ lookup_table_uuid }) => {
                navigate(`/lookup_table/${lookup_table_uuid}`);
            });
        }
    };

    const handleUpdate = () => {
        if (is_edit && lookup_table_uuid !== undefined) {
            setIsSaving(true);
            Backend.updateLookupTable(
                { lookup_table_uuid, org_uuid, name, headers, details }
            ).then(() => {
                navigate(`/lookup_table/${lookup_table_uuid}`);
            });
        }
    };

    const handleCheckedMergeKey = (idx: number, checked: boolean) => {
        const new_merge_key_column_indices_set = new Set(details.merge_key_column_indices || []);
        if (checked) {
            new_merge_key_column_indices_set.add(idx);
        } else {
            new_merge_key_column_indices_set.delete(idx);
        }
        // convert set back to sorted array
        const new_merge_key_column_indices = Array.from(new_merge_key_column_indices_set).sort();
        setDetails({
            ...details,
            merge_key_column_indices: new_merge_key_column_indices
        });
    }

    const handleCheckedCompensateLeadingZeros = (idx: number, checked: boolean) => {
        const new_compensate_lz_column_indices_set = new Set(details.compensate_leading_zeros_column_indices || []);
        if (checked) {
            new_compensate_lz_column_indices_set.add(idx);
        } else {
            new_compensate_lz_column_indices_set.delete(idx);
        }
        // convert set back to sorted array
        const new_compensate_lz_column_indices = Array.from(new_compensate_lz_column_indices_set).sort();
        setDetails({
            ...details,
            compensate_leading_zeros_column_indices: new_compensate_lz_column_indices
        });
    }

    const handleCheckBack = () => {
        if (is_changed) {
            setIsBackModalOpen(true);
        } else {
            navigate(lookup_table_uuid ? `/lookup_table/${lookup_table_uuid}` : "/endpoints");
        }
    }

    const handleBack = (result: boolean) => {
        if (result) {
            navigate(lookup_table_uuid ? `/lookup_table/${lookup_table_uuid}` : "/endpoints");
        }
        setIsBackModalOpen(false);
    }

    const can_create = name.length > 0 && org_uuid.length > 0 && !is_saving;
    const is_email_enabled = details.email_update !== undefined && details.email_update !== "disabled";

    if (is_edit && !is_init) {
        return <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <LoadingSpinner />
        </div>;
    }

    const hash_keys = headers.filter((_, idx) => (details.merge_key_column_indices || []).includes(idx));
    const effective_compensate_leading_zero_keys = headers.filter((_, idx) =>
        (details.compensate_leading_zeros_column_indices || []).includes(idx) &&
        (details.merge_key_column_indices || []).includes(idx)
    )

    const tooltip = is_changed ? "Unsaved changes to the lookup table" : "";
    const lookup_table_org = memberships.find(({ org }) => org.uuid === org_uuid);

    return <div className={classNames("lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        <div className="h-20 w-full bg-white border-b border-b-gray-200">
            <div className="pl-4 pr-10 py-4 flex flex-row items-start max-w-5xl">
                <Button icon={ArrowLeftIcon} onClick={handleCheckBack} />
                <ConfirmModal
                    open={is_back_modal_open}
                    title="Unsaved changes"
                    message={["There are unsaved changes to the lookup table.", "Are you sure you want to go back?"]}
                    cancel="No"
                    confirm="Yes"
                    onClose={handleBack} />
                <div className="pl-4 flex flex-col justify-start gap-1">
                    <h2 className="flex flex-row items-center  gap-4 text-xl font-semibold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight">
                        {name.length > 0 ? name : "<no name>"}
                        {lookup_table_org && <OrgPill name={lookup_table_org.org.name} type={lookup_table_org.org.type} />}
                    </h2>
                    <h2 className="text-sm text-gray-400 tracking-tight">
                        {!is_edit && "Create Lookup Table"}
                        {is_edit && "Edit Lookup Table"}
                    </h2>
                </div>
                <div className="grow"></div>
                <div className="pl-4 flex flex-col justify-end gap-1">
                    <div className="text-right">
                        {!is_edit && <Button
                            text="Create"
                            highlight={true}
                            disabled={!can_create}
                            loading={is_saving}
                            onClick={() => handleCreate()} />}
                        {is_edit && <Button
                            text="Save Changes"
                            highlight={true}
                            disabled={!can_create}
                            loading={is_saving}
                            onClick={() => handleUpdate()} />}
                    </div>
                    <div className="flex items-end">
                        {tooltip.length > 0 && <span className="text-sm text-gray-600">{tooltip}</span>}
                    </div>
                </div>
            </div>
        </div>

        <div className="flex flex-col py-10">
            <div className="px-10 max-w-5xl">
                {is_business_orgs && <div className="sm:grid sm:grid-cols-4 max-w-5xl sm:items-start sm:gap-4 pb-6">
                    <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        Organization
                    </label>
                    <div className="col-span-3 flex flex-row">
                        {all_orgs.map((org, idx) => <div key={idx} className="pr-2"><OrgPill
                            key={org.uuid}
                            name={org.name}
                            type={org.type}
                            disabled={admin_orgs.findIndex((o) => o.uuid === org.uuid) === -1}
                            selected={org.uuid === org_uuid}
                            onClick={() => {
                                setOrgUuid(org.uuid);
                                setIsChanged(true);
                            }} /></div>)}
                    </div>
                </div>}
                <div className="grid grid-cols-4 items-center w-full">
                    <div className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">Name</div>
                    <div className="col-span-3">
                        <input
                            type="text"
                            value={name}
                            className="border border-gray-300 rounded-md w-full px-3 py-2 text-gray-900"
                            onChange={(e) => {
                                setName(e.target.value);
                                setIsChanged(true);
                            }} />
                    </div>
                </div>
            </div>
            <div className="px-10 mt-5 pb-10 flow flow-col items-center">
                <div className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">Table Headers</div>
                <div className="mt-5 outer-div">
                    <table className="py-4 text-xs md:text-base">
                        <thead>
                            <tr>
                                <th></th>
                                {headers.map((_header, idx) => <th key={idx}
                                    className="py-1 px-4 bg-gray-50 border border-gray-300 text-gray-900 text-xs font-normal align-top w-32 focus:ring-1 focus:ring-sky-500 min-w-[150px] max-w-[300px]"
                                    colSpan={2}>
                                    {getExcelColumnName(idx)}
                                </th>)}
                            </tr>
                            <tr>
                                <th className="py-1 px-4 bg-gray-100 border border-gray-300 text-gray-900 text-sm font-semibold align-top">Header</th>
                                {headers.map((header, idx) => <Fragment key={idx}>
                                    <th
                                        className={classNames(
                                            "py-1 px-4 bg-gray-100 border border-gray-300 cursor-text hover:bg-sky-100 text-left text-sm font-normal align-top w-32 focus:ring-1 focus:ring-sky-500 min-w-[150px] max-w-[300px]",
                                            header.length === 0 && current_header_idx !== idx ? "text-gray-400" : "text-gray-900",
                                        )}
                                        contentEditable={true}
                                        onBlur={e => handleBlur(e, idx)}
                                        onInput={e => handleInput(e, idx)}
                                        onFocus={e => handleFocus(e, idx)}
                                        onKeyDown={e => handleKeyDown(e, idx)}
                                        ref={header_refs[idx]}
                                        dangerouslySetInnerHTML={{ __html: header.length === 0 && current_header_idx !== idx ? "click to edit" : header }}
                                    />
                                    <th key={idx + headers.length}
                                        className="py-1 px-2 bg-gray-100 text-gray-400 border border-gray-300 cursor-pointer hover:bg-sky-300 hover:text-white w-4"
                                        onClick={() => { deleteHeader(idx); }}
                                    >
                                        <MinusIcon className="h-4 w-4 " />
                                    </th>
                                </Fragment>)}
                                <th className="py-1 px-4 bg-gray-50 hover:bg-sky-300 border border-gray-300 cursor-pointer" onClick={addHeader}>+</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="py-1 px-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm font-semibold align-top">Key</td>
                                {headers.map((header, idx) => <td key={idx} className="py-1 px-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm font-normal align-top text-center" colSpan={2}>
                                    <Checkbox checked={(details.merge_key_column_indices || []).includes(idx)}
                                        setChecked={(checked) => handleCheckedMergeKey(idx, checked)} />
                                </td>)}
                            </tr>
                            <tr>
                                <td className="py-1 px-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm font-semibold align-top">Leading zeros</td>
                                {headers.map((header, idx) => <td key={idx} className="py-1 px-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm font-normal align-top text-center" colSpan={2}>
                                    <Checkbox checked={(details.compensate_leading_zeros_column_indices || []).includes(idx)}
                                        setChecked={(checked) => handleCheckedCompensateLeadingZeros(idx, checked)} />
                                </td>)}
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="text-sm text-gray-600 mt-4 max-w-2xl">
                    {hash_keys.length === 0 && "No key column selected. When merging, complete rows will be matched using all columns."}
                    {hash_keys.length === 1 && <span>When merging, only rows with a new unique <span className="font-semibold">{hash_keys[0]}</span> will be added to the lookup table.</span>}
                    {hash_keys.length > 1 && <span>When merging, only extracted rows with a new unique combination <span className="font-semibold">[{hash_keys.join(", ")}]</span> will be added to the lookup table.</span>}
                </div>
                <div className="text-sm text-gray-600 mt-4 max-w-2xl">
                    {effective_compensate_leading_zero_keys.length >= 1 && <span>Merging will ignore leading zeros in values for the following column(s): <span className="font-semibold">{effective_compensate_leading_zero_keys.join(", ")}.</span></span>}
                </div>
            </div>
            {!is_edit && <div className="px-10 max-w-5xl">
                <div className="pb-10 border-b border-t border-gray-200">
                    <div className="flex flex-row items-center w-full pt-10 ">
                        <div className="flex-1 flex flex-col">
                            <div className="text-sm font-medium leading-6 text-gray-900">
                                Upload Initial Data
                            </div>
                            <div className="text-sm text-gray-500">
                                You can initialize the lookup table with data from an Excel file.
                            </div>
                        </div>
                    </div>
                    {sheets === undefined && <div className="mt-3"><LookupTableDropArea setSheets={handleParse} /></div>}
                    {sheets !== undefined && <div className="mt-3 grid grid-cols-4 items-center w-full">
                        <div className="text-sm text-gray-500">Select Sheet</div>
                        <div className="col-span-3">
                            <Dropdown
                                values={sheets.map((sheet) => sheet.name)}
                                ids={sheets.map((_, idx) => `${idx}`)}
                                selected={`${selected_sheet_idx}`}
                                onChange={(idx) => setSelectedSheetIdx(parseInt(idx, 10))} />
                        </div>
                    </div>}
                    {sheets !== undefined && selected_sheet && <div className="pb-5 w-full">
                        <div className="outer-div pt-5">
                            <Sheet data={selected_sheet.data} show_header={true} />
                        </div>
                    </div>}
                </div>
            </div>}
            <div className="px-10 max-w-5xl">
                <div className="py-10 w-full sm:grid sm:grid-cols-6 sm:items-start sm:gap-4 text-gray-400 border-t border-gray-200">
                    <label htmlFor="endpoint_reply_file_format"
                        className="block sm:col-span-2 text-sm font-medium leading-6 sm:pt-1.5 text-gray-900">
                        <p>Upload via email</p>
                        <p className="mt-3 max-w-2xl text-sm leading-6 font-normal text-gray-600">
                            When enabled, you can send email to the email address below with the attachment in the format specified.
                        </p>
                    </label>
                    <div className="mt-2 sm:mt-0 ">
                        <Dropdown
                            values={["/", "Upload", "Append", "Merge (incoming wins)", "Merge (existing wins)"]}
                            ids={["disabled", "replace", "append", "merge_incoming_wins", "merge_existing_wins"]}
                            selected={details.email_update || "disabled"}
                            onChange={(value) => {
                                handleDetailFieldChange("email_update", value);
                                setIsChanged(true);
                            }} />
                    </div>
                    <label htmlFor="endpoint_reply_file_format"
                        className="block sm:col-span-2 text-sm font-medium leading-6 sm:pt-1.5 text-gray-900">
                        <p>Attachment format</p>
                        <p className="mt-3 max-w-2xl text-sm leading-6 font-normal text-gray-600">
                            Columns must match the headers in the lookup table. First row is assumed to be headers.
                        </p>
                    </label>
                    <div className="mt-2 sm:mt-0 ">
                        <Dropdown
                            values={["TSV"]}
                            ids={["tsv"]}
                            disabled={!is_email_enabled}
                            selected={details.email_attachment_format || "tsv"}
                            onChange={(value) => {
                                handleDetailFieldChange("email_attachment_format", value);
                                setIsChanged(true);
                            }} />
                    </div>
                    {is_edit && is_email_enabled && email_address && <div className="w-full sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:col-span-6">
                        <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                            Email connector
                        </label>
                        <div className="mt-2 sm:col-span-3 sm:mt-0 text-sky-600">
                            <CopyTextbox text={email_address} email_pretty_name={name} is_email={true} />
                        </div>
                    </div>}

                    <label htmlFor="endpoint_reply_file_format"
                        className="block sm:col-span-2 text-sm font-medium leading-6 sm:pt-1.5 text-gray-900">
                        <p>REST API Update</p>
                        <p className="mt-3 max-w-2xl text-sm leading-6 font-normal text-gray-600">
                            When enabled, the lookup table can be updated via the REST API.
                        </p>
                    </label>
                    <div className="mt-2 sm:mt-0">
                        <Dropdown
                            values={["Enabled", "Disabled"]}
                            ids={["enabled", "disabled"]}
                            selected={details.rest_api_update ? "enabled" : "disabled"}
                            onChange={(value) => handleDetailFieldChange("rest_api_update", value === "enabled")} />
                    </div>
                </div>
            </div>
            {is_admin && <div className="px-10 max-w-5xl">
                <div className="py-6 text-sm font-bold leading-6 text-gray-400 border-t border-gray-200">
                    Raw JSON Settings (Admin Only)
                </div>
                <div className="w-full shadow border">
                    <CodeMirror
                        value={JSON.stringify({ lookup_table_uuid, org_uuid, name, headers, details }, null, 2)}
                        height="400px"
                        theme="light"
                        extensions={[javascript()]}
                        onChange={(value) => {
                            try {
                                const parsed = JSON.parse(value);
                                setOrgUuid(parsed.org_uuid);
                                setName(parsed.name);
                                setHeaders(parsed.headers);
                                setDetails(parsed.details);
                                setIsChanged(true);
                                setIsValidAdminJSON(true);
                            } catch (e) {
                                console.error("Invalid JSON", e);
                                setIsValidAdminJSON(false);
                            }
                        }}
                    />
                </div>
                <div className={`mt-2 text-sm ${is_valid_admin_json ? "text-green-600" : "text-red-600"}`}>
                    {is_valid_admin_json ? "VALID JSON" : "INVALID JSON"}
                </div>
            </div>}
        </div>
    </div>;
}
