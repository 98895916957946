import { Fragment, useEffect, useState } from "react";

import { Dialog, Transition } from "@headlessui/react";
import {
    CheckIcon,
    PencilIcon,
    XMarkIcon
} from "@heroicons/react/24/outline";

import { IOrganization, ITransaction, ITransactionList } from "../lib/types";
import { classNames, isValidInteger, prettyDateTime } from "../lib/utils";
import { Backend } from "../lib/backend";

import { Pagination } from "./Pagination";
import { LoadingSpinnerLimit } from "./LoadingSpinner";
import { Button } from "./Button";
import { Textbox } from "./Textbox";

type TransactionHistoryProps = {
    is_admin: boolean;
    user_uuid?: string;
    org: IOrganization;
    open: boolean;
    setOpen: (open: boolean) => void;
}

export function TransactionHistory(props: TransactionHistoryProps) {
    const { is_admin, user_uuid, org, open, setOpen } = props;

    const [transaction_list, setTransactionList] = useState<ITransactionList | undefined>(undefined);
    const [offset, setOffset] = useState(0);
    const [edit_uuid, setEditUuid] = useState<string | undefined>(undefined);
    const [edit_amount, setEditAmount] = useState<string | undefined>(undefined);
    const [edit_text, setEditText] = useState<string | undefined>("");
    const [is_saving, setIsSaving] = useState(false);

    const limit = 15;

    useEffect(() => {
        setTransactionList(undefined);
        if (is_admin) {
            Backend.getAdminOrgTransactionHistory({ org_uuid: org.uuid, offset, limit })
                .then((transaction_list) => { setTransactionList(transaction_list); });
        } else {
            Backend.getOrgTransactionHistory({ org_uuid: org.uuid, offset, limit })
                .then((transaction_list) => { setTransactionList(transaction_list); });
        }
    }, [is_admin, offset, org, user_uuid]);

    const startEdit = (transaction: ITransaction) => {
        setEditUuid(transaction.uuid);
        setEditAmount(`${transaction.amount}`);
        setEditText(transaction.details.text);
    };

    const saveEdit = async () => {
        if (!is_admin) { return; }
        // make sure we have valid data
        if (edit_uuid === undefined || edit_amount === undefined || edit_text === undefined || !isValidInteger(edit_amount)) { return; }
        // find the transaction
        const old_transaction = transaction_list?.transactions.find(transaction => transaction.uuid === edit_uuid);
        if (old_transaction !== undefined) {
            // save
            setIsSaving(true);
            try {
                await Backend.editOrgCredit({
                    org_uuid: old_transaction.org_uuid,
                    transaction_uuid: edit_uuid,
                    amount: Number(edit_amount),
                    details_text: edit_text
                });
                // update the transaction list
                const new_transaction_list = await Backend.getAdminOrgTransactionHistory({ org_uuid: org.uuid, offset, limit });
                setTransactionList(new_transaction_list);
            } catch (error) {
                console.error(error);
            }
        }
        setEditUuid(undefined);
        setEditAmount(undefined);
        setEditText("");
        setIsSaving(false);
    }

    // check if edit_amount is valid number
    const is_valid = edit_amount !== undefined && isValidInteger(edit_amount);

    return <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
            <div className="fixed inset-0" />
            <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                    <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                                <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                    <div className="px-4 sm:px-6">
                                        <div className="flex items-start justify-between">
                                            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                                Transaction History for {org.name}
                                            </Dialog.Title>
                                            <div className="ml-3 flex h-7 items-center">
                                                <button
                                                    type="button"
                                                    className="relative rounded-md bg-white text-gray-400 hover:text-gray-500"
                                                    onClick={() => setOpen(false)}
                                                >
                                                    <span className="absolute -inset-2.5" />
                                                    <span className="sr-only">Close panel</span>
                                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                        <table className="min-w-full divide-y divide-gray-300">
                                            <thead className="bg-gray-50">
                                                <tr>
                                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6" />
                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Date</th>
                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Amount</th>
                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Type</th>
                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Details</th>
                                                    {is_admin && < th />}
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200 bg-white">
                                                {transaction_list && transaction_list.transactions.map((transaction, idx) => (
                                                    <Fragment key={idx}>
                                                        <tr className={classNames("text-sm text-gray-500", edit_uuid === transaction.uuid ? "font-semibold text-gray-900" : "")}>
                                                            <td className="whitespace-nowrap py-4 pl-2 font-semibold sm:pl-3 align-top">{idx + offset + 1}</td>
                                                            <td className="whitespace-nowrap px-3 py-4 ">{prettyDateTime(transaction.created_at)}</td>
                                                            <td className="whitespace-nowrap px-3 py-4">{transaction.amount}</td>
                                                            <td className="whitespace-nowrap px-3 py-4">{transaction.type}</td>
                                                            <td className="px-3 py-4">{transaction.details.text}</td>
                                                            {is_admin && <td className="whitespace-nowrap">
                                                                {edit_uuid === undefined && <PencilIcon className="h-4 w-4 text-gray-400 cursor-pointer" onClick={() => startEdit(transaction)} />}
                                                            </td>}
                                                        </tr>
                                                        {edit_uuid === transaction.uuid && <tr className="bg-gray-50">
                                                            <td></td>
                                                            <td colSpan={5}>
                                                                <div className="flex flex-row py-1 gap-x-2">
                                                                    {edit_amount !== undefined && <Textbox value={edit_amount} onChange={setEditAmount} />}
                                                                    {edit_text !== undefined && <Textbox value={edit_text} onChange={setEditText} />}
                                                                    <div className="flex flex-row">
                                                                        <Button icon={CheckIcon} loading={is_saving} disabled={!is_valid} onClick={() => saveEdit()} />
                                                                        <Button icon={XMarkIcon} loading={is_saving} onClick={() => setEditUuid(undefined)} />
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>}
                                                    </Fragment>
                                                ))}
                                                {!transaction_list && <tr>
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" colSpan={is_admin ? 6 : 5}>
                                                        <LoadingSpinnerLimit />
                                                    </td>
                                                </tr>}
                                            </tbody>
                                        </table>
                                        <Pagination offset={offset} limit={limit} total={transaction_list?.total || 0} setOffset={setOffset} />
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </div>
        </Dialog>
    </Transition.Root>;
}