import { Fragment } from "react";

import {
    Popover,
    Transition
} from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { classNames } from "../lib/utils";

export interface IDropdownMenuItem {
    title: string;
    separator?: boolean;
    onClick: () => void;
}

type DropdownMenuProps = {
    title: string;
    items: IDropdownMenuItem[];
};

export function DropdownMenu(props: DropdownMenuProps) {
    const { title, items } = props;

    return <Popover className="relative">
        <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-700 cursor-pointer">
            <span>{title}</span>
            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
        </Popover.Button>

        <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
        >
            <Popover.Panel className="absolute z-10 mt-2 flex w-screen max-w-min">
                <div className="w-80 shrink rounded bg-white p-2 text-sm leading-6 text-gray-700 shadow-lg ring-1 ring-gray-900/5">
                    {items.map((item, idx) => (
                        <Popover.Button key={idx} className={classNames(
                            "block p-2 hover:text-sky-600 cursor-pointer w-full text-left",
                            item.separator && (idx !== items.length - 1) ? "border-b border-gray-200" : ""
                        )} onClick={item.onClick}>{item.title}</Popover.Button>
                    ))}
                </div>
            </Popover.Panel>
        </Transition>
    </Popover>;
}