type LongTextProps = {
    text: string;
    line_limit?: number;
    remove_whitespace?: boolean;
    trim_whitespace?: boolean;
}

export function LongText(props: LongTextProps) {
    const { text, line_limit, remove_whitespace, trim_whitespace } = props;

    if (line_limit === 1) {
        // we want single line
        return <div className="truncate">{text}</div>;
    } else if (line_limit !== undefined) {
        // we want to limit to fix number of lines
        const rows = trim_whitespace === true || remove_whitespace === true ?
            text.split("\n").map(row => row.trim()).filter(row => row !== "") :
            text.split("\n");
        return <div className="w-full" style={{
            display: "-webkit-box",
            WebkitLineClamp: `${line_limit}`,
            WebkitBoxOrient: "vertical",
            overflow: "hidden"
        }}>
            {rows.map((row, idx) => <p className={remove_whitespace ? "" : "whitespace-pre-wrap"} key={idx}>{row}<br /></p>)}
        </div>;
    } else {
        // just make nice paragraphs
        return <div className="whitespace-pre-wrap">
            {text}
        </div>;
    }
}
