import {
    Fragment,
    useEffect,
    useState
} from "react";
import { useNavigate } from "react-router-dom";

import { classNames, redirectToExternalPage } from "../lib/utils";
import Notification from "./Notification";
import { Tooltip } from "react-tooltip";

type ButtonProps = {
    icon?: any;
    icon_text?: string;
    text?: string;
    tooltip?: string;
    disabled?: boolean;
    disabled_warning?: string;
    loading?: boolean;
    onClick?: () => void;
    href?: string;
    highlight?: boolean;
    highlight_color?: "sky" | "gray";
    open_in_new_tab?: boolean;
}

export function Button(props: ButtonProps) {
    const navigate = useNavigate();

    const { icon, icon_text, text, tooltip, disabled, disabled_warning, loading, onClick, href, highlight, highlight_color: _highlight_color, open_in_new_tab } = props;

    const [show_disabled_warning, setShowDisabledWarning] = useState(false);

    useEffect(() => {
        if (!disabled) { setShowDisabledWarning(false); }
    }, [disabled]);

    const highlight_color = _highlight_color || "sky";

    const onClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        if (disabled) {
            setShowDisabledWarning(true);
            return;
        }
        if (href !== undefined) {
            if (open_in_new_tab) {
                redirectToExternalPage(href, true);
            } else if (href.startsWith("mailto:")) {
                redirectToExternalPage(href);
            } else if (href.startsWith("http")) {
                redirectToExternalPage(href);
            } else {
                navigate(href);
            }
        } else if (onClick !== undefined) {
            onClick();
        }
    };

    return <Fragment>
        <button type="button" onClick={onClickHandler} title={tooltip}
            className={classNames(
                "h-8 rounded-md mx-1 px-2.5 py-1.5 text-sm font-semibold shadow-sm ring-1 ring-inset whitespace-nowrap",
                disabled ? "bg-gray-100 text-gray-500 ring-gray-300 cursor-not-allowed" :
                    highlight && highlight_color === "sky" ? "bg-sky-600 text-white ring-sky-600 hover:bg-sky-500" :
                        highlight && highlight_color === "gray" ? "bg-gray-200 text-gray-600 ring-gray-400 group hover:bg-sky-300 hover:text-white" :
                            "bg-white text-gray-700 ring-gray-300 hover:bg-gray-50"
            )}>
            {icon && <props.icon className={classNames(
                "w-5 h-5 inline-block",
                text !== undefined && text.length > 0 ? "mr-1" : "",
                (text !== undefined && highlight && highlight_color === "sky" ? "text-white" :
                    text !== undefined ? "text-gray-400" :
                        highlight && highlight_color === "sky" ? "text-white" :
                            highlight && highlight_color === "gray" ? "text-gray-400 group-hover:text-white" :
                                "text-gray-700")
            )} />}
            {icon_text && <span className="text-gray-400 mr-1">{icon_text}</span>}
            {loading && <i className="fas fa-spinner fa-spin mt-1" />}{text !== undefined ? <>&nbsp;</> : null}{text}
        </button>
        {disabled && disabled_warning && show_disabled_warning &&
            <Notification title="Warning" message={disabled_warning} show={true} setShow={setShowDisabledWarning} />}
    </Fragment>;
}

export type GroupButtonProps = {
    icon?: any;
    text: string;
    href?: string;
    onClick?: () => void;
    open_in_new_tab?: boolean;
    skip?: boolean;
    disabled?: boolean;
    selected?: boolean;
    tooltip?: string;
}

function ButtonIcon(props: { icon?: any, has_text: boolean, tiny?: boolean, selected?: boolean }) {
    if (props.icon === undefined) { return null; }
    return <props.icon className={classNames("inline-block",
        props.has_text ? "mr-1 text-gray-400" : "text-gray-700",
        props.tiny ? "w-4 h-4" : "w-5 h-5",
        props.selected ? "text-gray-700" : "text-gray-400")} />;
}

type ButtonGroupProps = {
    buttons: GroupButtonProps[];
    disabled?: boolean;
    tiny?: boolean;
}

export function ButtonGroup(props: ButtonGroupProps) {
    const navigate = useNavigate();

    const { buttons: all_buttons, disabled, tiny } = props;

    const buttons = all_buttons.filter(button => !button.skip);

    const onClickHandler = (button: GroupButtonProps) => {
        if (disabled) { return; }
        if (button.disabled) { return; }
        const { href, onClick, open_in_new_tab } = button;
        if (href !== undefined) {
            if (open_in_new_tab) {
                redirectToExternalPage(href, true);
            } else if (href.startsWith("mailto:")) {
                redirectToExternalPage(href);
            } else if (href.startsWith("http")) {
                redirectToExternalPage(href);
            } else {
                navigate(href);
            }
        } else if (onClick !== undefined) {
            onClick();
        }
    };

    const onClickWrapper = (button: GroupButtonProps, event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        onClickHandler(button);
    };

    if (buttons.length === 0) {
        return null;
    }

    if (buttons.length === 1) {
        return <Button icon={buttons[0].icon} text={buttons[0].text} onClick={() => onClickHandler(buttons[0])} disabled={buttons[0].disabled} />;
    }

    return <span className={classNames("mx-1 isolate inline-flex rounded-md shadow-sm", tiny ? "h-7" : "h-8")}>
        <button
            type="button"
            className={classNames(
                "relative inline-flex items-center rounded-l-md text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10",
                disabled || buttons[0].disabled ? "bg-gray-100 text-gray-500 cursor-not-allowed" :
                    buttons[0].selected ? "bg-sky-100 text-gray-400 hover:bg-sky-200" :
                        "bg-white text-gray-700 hover:bg-gray-50",
                tiny ? "px-2 py-2" : "px-3 py-2"
            )}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => onClickWrapper(buttons[0], e)}
            data-tooltip-id="tooltip-id"
            data-tooltip-html={buttons[0].tooltip}
        >
            <ButtonIcon icon={buttons[0].icon} has_text={buttons[0].text.length > 0} tiny={tiny} />
            {buttons[0].text}
        </button>
        {buttons.slice(1, -1).map((button, idx) => (<button
            key={idx}
            type="button"
            className={classNames(
                "relative -ml-px inline-flex items-center text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10",
                disabled || button.disabled ? "bg-gray-100 text-gray-500 cursor-not-allowed" :
                    button.selected ? "bg-sky-100 text-gray-700 hover:bg-sky-200" :
                        "bg-white text-gray-700 hover:bg-gray-50",
                tiny ? "px-2 py-2" : "px-3 py-2"
            )}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => onClickWrapper(button, e)}
            data-tooltip-id="tooltip-id"
            data-tooltip-html={button.tooltip}
        >
            <ButtonIcon icon={button.icon} has_text={buttons[0].text.length > 0} tiny={tiny} />
            {button.text}
        </button>))}
        <button
            type="button"
            className={classNames(
                "relative -ml-px inline-flex items-center rounded-r-md text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10",
                disabled || buttons[buttons.length - 1].disabled ? "bg-gray-100 text-gray-500 cursor-not-allowed" :
                    buttons[buttons.length - 1].selected ? "bg-sky-100 text-gray-700 hover:bg-sky-200" :
                        "bg-white text-gray-700 hover:bg-gray-50",
                tiny ? "px-2 py-2" : "px-3 py-2"
            )}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => onClickWrapper(buttons[buttons.length - 1], e)}
            data-tooltip-id="tooltip-id"
            data-tooltip-html={buttons[buttons.length - 1].tooltip}
        >
            <ButtonIcon icon={buttons[buttons.length - 1].icon} has_text={buttons[0].text.length > 0} tiny={tiny} />
            {buttons[buttons.length - 1].text}
        </button>
        <Tooltip id="tooltip-id" place="bottom" />
    </span>;
}
