import {
    Fragment,
    useEffect,
    useState
} from "react";
import { useSelector } from "react-redux";
import {
    Link,
    useNavigate,
    useParams
} from "react-router-dom";

import { SlChemistry } from "react-icons/sl";
import {
    ArrowLeftIcon,
    ArrowPathIcon,
    BoltIcon,
    ChevronRightIcon,
    ClockIcon,
    CommandLineIcon,
    EnvelopeIcon,
    GlobeAltIcon,
    PlusIcon,
    PuzzlePieceIcon,
    ScaleIcon,
    TableCellsIcon
} from "@heroicons/react/24/outline";
import { TbTable, TbTablePlus } from "react-icons/tb";

import {
    selectEnv,
    selectIsSidebarLarge,
    selectMemberships
} from "../lib/scraper.slice";
import {
    classNames,
    prettySmartDateTime,
    setDocumentTitle
} from "../lib/utils";
import {
    Backend,
    BackendObj
} from "../lib/backend";
import {
    ExtractJobStatus,
    ExtractJobType,
    IItem,
    IItemSlim,
    IOrganization,
    IViewExtractJob,
    IViewExtractJobSlim
} from "../lib/backend/extractions.types.generated";
import {
    EXTRACT_JOB_TYPE,
    ORG_TYPES
} from "../lib/consts";

import { Button } from "../components/Button";
import { ErrorMessageBar } from "../components/ErrorMessageBar";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { OrgPill } from "../components/OrgPill";
import { ItemDetail, ItemDownloadButtons, ItemList } from "./Items";
import { SubScreen, SubSubScreen } from "../components/FullScreen";

type JobIconProps = {
    job_type: ExtractJobType;
    job_status?: ExtractJobStatus
    fallback: "text" | "icon";
};

export function JobIcon(props: JobIconProps) {
    const { job_type, job_status, fallback } = props;

    return job_status === "running" ? <ClockIcon className="h-5 w-5" aria-hidden="true" title={"running"} /> :
        job_type === EXTRACT_JOB_TYPE.email ? <EnvelopeIcon className="h-5 w-5" aria-hidden="true" title={job_type} /> :
            job_type === EXTRACT_JOB_TYPE.api ? <CommandLineIcon className="h-5 w-5" aria-hidden="true" title={job_type} /> :
                job_type === EXTRACT_JOB_TYPE.web ? <GlobeAltIcon className="h-5 w-5" aria-hidden="true" title={job_type} /> :
                    job_type === EXTRACT_JOB_TYPE.eval ? <ScaleIcon className="h-5 w-5" aria-hidden="true" title={job_type} /> :
                        job_type === EXTRACT_JOB_TYPE.suggest ? <TbTablePlus className="h-5 w-5" aria-hidden="true" title={job_type} /> :
                            job_type === EXTRACT_JOB_TYPE.lookup_table_update ? <TableCellsIcon className="h-5 w-5" aria-hidden="true" title={job_type} /> :
                                fallback === "text" ? <span>job_type</span> : <BoltIcon className="h-5 w-5" aria-hidden="true" title={job_type} />;
}

export function EmptyJobsList() {
    const is_sidebar_large = useSelector(selectIsSidebarLarge);

    return <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        <div className="flex justify-center items-center h-screen w-full">
            <div className="text-center">
                <SlChemistry className="mx-auto h-12 w-12 text-gray-400" />
                <h3 className="mt-2 text-sm font-semibold text-gray-900">No jobs</h3>
                <p className="mt-1 text-sm text-gray-500">Get started by creating a new job.</p>
                <div className="mt-6">
                    <Button icon={PlusIcon} text="New Extraction" href="/extraction/new" />
                </div>
            </div>
        </div>
    </div >;
}

type JobsListProps = {
    jobs: IViewExtractJobSlim[];
    selected_job_uuid?: string;
    can_change: boolean;
    selectJob(job_uuid: string): void;
    increaseLimit: () => void;
};

function JobsList(props: JobsListProps) {
    const { jobs, selected_job_uuid, can_change, selectJob } = props;

    const memberships = useSelector(selectMemberships);
    const orgs = memberships.map((membership) => membership.org);
    const org_map = new Map<string, IOrganization>();
    for (const org of orgs) {
        org_map.set(org.uuid, org);
    }

    return <div className="p-2">
        <ul className="divide-y divide-gray-20">
            {jobs.map((job) => (
                <li key={job.uuid} className={classNames(
                    can_change ? "hover:bg-sky-100 cursor-pointer hover:round" : "cursor-wait",
                    "px-3 py-4 hover:bg-sky-100 cursor-pointer",
                    selected_job_uuid === job.uuid ? "bg-gray-200" : "")}
                    onClick={() => { if (can_change) { selectJob(job.uuid) } }}>

                    <div className="flex flex-row items-center pb-1">
                        <div className={classNames("",
                            job.status === "running" ? "text-gray-400" :
                                job.status === "done" ? "text-sky-400" :
                                    job.status === "error" ? "text-red-400" :
                                        "text-gray-400")}>
                            <JobIcon job_type={job.type} job_status={job.status} fallback="icon" />
                        </div>
                        <div className={classNames("flex-grow font-semibold text-sm truncate pl-2")}>
                            {job.title.length > 0 ? job.title : "[no subject]"}
                        </div>
                        <span className="ml-4 text-xs text-gray-400 whitespace-nowrap">
                            {prettySmartDateTime(job.start_ts)}
                        </span>
                    </div>
                    <div className="flex justify-between items-center">
                        <div className="pt-2 flex-grow truncate text-sm text-gray-600">
                            {job.subtitle.length > 0 ? job.subtitle : ""}
                        </div>
                        <div>
                            {org_map.has(job.org_uuid) && <OrgPill
                                name={org_map.get(job.org_uuid)?.name ?? ""}
                                type={org_map.get(job.org_uuid)?.type ?? ORG_TYPES.personal} />}
                        </div>
                    </div>
                </li>
            ))}
        </ul>
    </div>;
}

type JobDetailProps = {
    job: IViewExtractJob;
};

function JobDetail(props: JobDetailProps) {
    const { job } = props;

    const is_sidebar_large = useSelector(selectIsSidebarLarge);

    const [selected_item, setSelectedItem] = useState<IItem | undefined>(undefined);
    const [show_selected_item, setShowSelectedItem] = useState(false);

    const selectItem = async (item: IItemSlim) => {
        setShowSelectedItem(true);
        if (item.uuid !== selected_item?.uuid) {
            setSelectedItem(undefined);
            const { item: new_selected_item } = await Backend.getItem({ item_uuid: item.uuid });
            setSelectedItem(new_selected_item);
        }
    }

    return <div>
        {job.details.template_uuid !== undefined && <div className="px-2 pt-4 text-sm text-gray-400 flex flex-row">
            {job.details.endpoint_uuid !== undefined && <Link to={`/endpoint/${job.details.endpoint_uuid}`}>
                <div className="flex flex-row items-center hover:underline pr-2">
                    <PuzzlePieceIcon className="h-4 w-4" />
                    <div className="text-gray-400 text-sm px-2">{job.details.endpoint_name}</div>
                    <div>|</div>
                </div>
            </Link>}
            <Link to={`/template/${job.details.template_uuid}`}>
                <div className="flex flex-row items-center hover:underline">
                    <TbTable className="h-4 w-4" />
                    <div className="text-gray-400 text-sm px-2">{job.details.template_name}</div>
                    <div><ChevronRightIcon className="h-4 w-4" /></div>
                </div>
            </Link>
        </div>}

        {job.items && job.items.length > 0 && <div className="px-4 py-4 space-y-4">
            <div className="font-bold text-gray-600">Extractions:</div>
            <div className="border border-gray-300">
                <ItemList items={job.items} onItemSelected={selectItem} is_wide={true} />
            </div>
        </div>}

        <SubScreen show={show_selected_item} onClose={() => setShowSelectedItem(false)}>
            <div className={classNames("fixed right-0 h-16 px-4 flex flex-row items-center bg-white border-b-gray-200 border-b", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
                <Button icon={ArrowLeftIcon} onClick={() => setShowSelectedItem(false)} />
                <h2 className="pl-4 text-xl font-semibold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight">
                    {selected_item !== undefined ?
                        <span>Extraction: {selected_item?.name ?? "[no subject]"}</span> :
                        <span className="text-sm text-gray-400">Loading extraction...</span>}
                </h2>
                <div className="flex-grow"></div>
                <ItemDownloadButtons item={selected_item} />
            </div>
            <SubSubScreen show={true}>
                <div className="p-4">
                    {selected_item && <ItemDetail item={selected_item} />}
                    {!selected_item && <LoadingSpinner />}
                </div>
            </SubSubScreen>
        </SubScreen>
    </div>
}

export function Jobs() {
    const navigate = useNavigate();
    const { selected_job_uuid } = useParams<{ selected_job_uuid: string | undefined }>();

    const env = useSelector(selectEnv);
    const is_sidebar_large = useSelector(selectIsSidebarLarge);

    const [show_items, setShowItems] = useState(true);
    const [limit, setLimit] = useState(100);
    const [jobs, setJobs] = useState<IViewExtractJobSlim[] | undefined>(undefined);
    const [total_jobs, setTotalJobs] = useState<number | undefined>(undefined);
    const [is_selected_job_loading, setIsSelectedJobLoading] = useState(false);
    const [selected_job, setSelectedJob] = useState<IViewExtractJob | undefined>(undefined);
    const [error_message, setErrorMessage] = useState<string | undefined>(undefined);

    useEffect(() => {
        setDocumentTitle("Jobs", env);
    }, [env]);

    useEffect(() => {
        if (selected_job === undefined) {
            setDocumentTitle("Jobs", env);
        } else {
            setDocumentTitle(`Jobs - ${selected_job.title.length > 0 ? selected_job.title : "[no subject]"}`, env);
        }
    }, [selected_job, env]);

    useEffect(() => {
        BackendObj.extractions.listJobs({ offset: "0", limit: `${limit}` })
            .then(({ jobs: new_jobs, total: new_total }) => {
                setJobs(new_jobs);
                setTotalJobs(new_total);
                if (selected_job_uuid === undefined && new_jobs.length > 0) {
                    navigate(`/jobs/${new_jobs[0].uuid}`);
                }
            })
            .catch((error) => {
                setErrorMessage(error.message);
            });
    }, [limit, navigate]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setSelectedJob(undefined);
        if (selected_job_uuid !== undefined) {
            setIsSelectedJobLoading(true);
            BackendObj.extractions.getJobDetails({ job_uuid: selected_job_uuid })
                .then(({ job }) => {
                    setSelectedJob(job);
                    setIsSelectedJobLoading(false);
                })
                .catch((error) => {
                    setErrorMessage(error.message);
                    setIsSelectedJobLoading(false);
                });
        }
    }, [selected_job_uuid]);

    const increaseLimit = () => {
        if (total_jobs !== undefined && limit < total_jobs) {
            setLimit(limit + 100);
        }
    }

    const selectJob = (job_uuid: string) => {
        navigate(`/jobs/${job_uuid}`);
    }

    const extract_uuid = selected_job?.details.endpoint_uuid ?? selected_job?.details.template_uuid;

    if (jobs === undefined) {
        return <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <LoadingSpinner />
        </div>;
    }

    if (jobs.length === 0) {
        return <EmptyJobsList />;
    }

    return <Fragment>
        <div className={classNames("hidden lg:fixed lg:right-0 lg:h-16 lg:flex lg:flex-row border-b-gray-200 border-b", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <div className="flex flex-row items-center p-4 sticky w-full">
                <Button icon={ArrowPathIcon} tooltip="Refresh" onClick={() => { }} />
                <div className="pl-4 truncate">
                    <h2 className="text-xl font-semibold leading-7 text-gray-900 sm:text-2xl sm:tracking-tight">
                        {selected_job ?
                            (selected_job.title.length > 0 ? "Job: " + selected_job.title : "Job: [no subject]") :
                            <span className="text-sm text-gray-400">Loading...</span>}
                    </h2>
                </div>
                <div className="flex-grow"></div>
                <div className="flex items-center">
                    {extract_uuid && <Button icon={SlChemistry} text="Extract" href={`/extraction/new/${extract_uuid}`} highlight={true} />}
                </div>
            </div>
        </div>
        <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:top-16 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <div className={classNames(is_sidebar_large ? "basis-1/3" : "basis-1/4", "overflow-y-auto bg-white border-r-gray-200 border-r")}>
                <div className="h-auto">
                    <JobsList
                        jobs={jobs}
                        can_change={!is_selected_job_loading}
                        selected_job_uuid={selected_job_uuid}
                        increaseLimit={increaseLimit}
                        selectJob={selectJob} />
                </div>
            </div>
            <div className={classNames(is_sidebar_large ? "basis-2/3" : "basis-3/4", "overflow-y-auto")}>
                {selected_job && <JobDetail job={selected_job} />}
                {!selected_job && <LoadingSpinner />}
            </div>
        </div >
        <div className="lg:hidden w-full">
            {show_items && <JobsList
                jobs={jobs}
                can_change={!is_selected_job_loading}
                selected_job_uuid={selected_job_uuid}
                increaseLimit={increaseLimit}
                selectJob={selectJob} />}
            {!show_items && <div className="p-4 border-t-2 border-gray-50">
                <div className="px-1">
                    <Button icon={ArrowLeftIcon} text="Back" onClick={() => setShowItems(true)} />
                </div>
                {selected_job && <JobDetail job={selected_job} />}
                {!selected_job && <LoadingSpinner />}
            </div>}
        </div>
        <ErrorMessageBar message={error_message} clearMessage={() => setErrorMessage(undefined)} />
    </Fragment>;
}