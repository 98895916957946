import { XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";
import { classNames } from "../lib/utils";

type MultiselectInputFieldProps = {
    values: string[];
    placeholder: string;
    separator: "underline" | "space";
    color?: "sky" | "green" | "red";
    onAdd?: () => void;
    onRemove?: (idx: number) => void;
    onClick?: (idx: number) => void;
}

export function MultiselectInputField(props: MultiselectInputFieldProps) {
    const { values, placeholder, separator, color, onAdd, onRemove, onClick } = props;

    const selected_color = color ? color : "sky";

    return <div className="px-2 py-1 flex flex-row flex-wrap items-center rounded-md shadow-sm ring-1 ring-inset ring-gray-300 cursor-pointer" onClick={() => { if (onAdd) { onAdd(); } }}>
        {values.map((value, idx) => {
            return <Fragment key={idx}>
                {idx > 0 && <span className={classNames("text-sm h-4", separator === "underline" ? "border-b-2 border-gray-400 w-3" : "w-2")}></span>}
                <div className="p-1 flex flex-row items-center">
                    <span className={classNames(
                        "flex items-center justify-center p-1 h-6 text-sm border rounded-l-sm",
                        selected_color === "sky" ? "bg-sky-100 border border-sky-200" :
                            selected_color === "green" ? "bg-green-100 border border-green-200" :
                                selected_color === "red" ? "bg-red-100 border border-red-200" : "",
                        onClick ? `cursor-pointer hover:bg-border-200` : "")}
                        onClick={(e) => { e.stopPropagation(); if (onClick) { onClick(idx); } }}
                    >
                        {value}
                    </span>
                    <span
                        className={classNames(
                            "flex items-center justify-center p-0.5 h-6 text-sm cursor-pointer rounded-r-sm",
                            selected_color === "sky" ? "bg-sky-200 border border-sky-200 hover:bg-sky-400" :
                                selected_color === "green" ? "bg-green-200 border border-green-200 hover:bg-green-400" :
                                    selected_color === "red" ? "bg-red-200 border border-red-200 hover:bg-red-400" : ""
                        )}
                        onClick={(e) => { e.stopPropagation(); if (onRemove) { onRemove(idx); } }}>
                        <XMarkIcon className={`h-4 w-4 text-${selected_color}-600`} />
                    </span>
                </div>
            </Fragment>
        })}
        {values.length === 0 && <div className="p-1"><span className="p-1 h-6 text-sm text-gray-400">
            {placeholder}
        </span></div>}
    </div>
}