import {
    useState,
    useEffect
} from "react";

import { PlusIcon, TrashIcon } from "@heroicons/react/24/outline";

import { BackendObj } from "../lib/backend";
import { IWebApiKey } from "../lib/types";
import { prettyDateTime } from "../lib/utils";

import { HiddenTextbox } from "../screens/Endpoints";
import { CopyTextbox } from "./CopyTextbox";
import { Button } from "./Button";
import { TextboxModal } from "./TextboxModal";
import { ConfirmModal } from "./ConfirmModal";
import { ErrorMessageBar } from "./ErrorMessageBar";

type WebApiKeyListProps = {
    org_uuid: string;
}

export function WebApiKeyList(props: WebApiKeyListProps) {
    const { org_uuid } = props;

    const [org_keys, setOrgKeys] = useState<IWebApiKey[]>([]);
    const [show_create_modal, setShowCreateModal] = useState(false);
    const [show_confirm_modal, setShowConfirmModal] = useState(false);
    const [check_delete_key, setCheckDeleteKey] = useState<IWebApiKey | undefined>(undefined);
    const [error_message, setErrorMessage] = useState<string | undefined>(undefined);
    useEffect(() => {
        BackendObj.extractions.listAPIKeys({ org_uuid })
            .then(({ keys }) => { setOrgKeys(keys); });
    }, [org_uuid]);

    const showKey = async (key_uuid: string) => {
        const { key: selected_key } = await BackendObj.extractions.getAPIKey({ key_uuid });
        if (selected_key === undefined) { return; }
        setOrgKeys(org_keys.map((key) => (key.uuid === key_uuid) ? selected_key : key));
    };

    const createNewKey = (result: boolean, name?: string) => {
        setShowCreateModal(false);
        setErrorMessage(undefined);
        if (result && name !== undefined) {
            BackendObj.extractions.createAPIKey({ org_uuid, name })
                .then(({ key }) => { setOrgKeys([...org_keys, key]); })
                .catch((error) => { setErrorMessage(error.response?.data ?? "Error creating API key"); });
        }
    };

    const checkDeleteKey = (key: IWebApiKey) => {
        setCheckDeleteKey(key);
        setShowConfirmModal(true);
    };

    const deleteKey = (result: boolean) => {
        setShowConfirmModal(false);
        setErrorMessage(undefined);
        if (result && check_delete_key !== undefined) {
            BackendObj.extractions.deleteAPIKey({ key_uuid: check_delete_key.uuid })
                .then(() => { setOrgKeys(org_keys.filter((key) => key.uuid !== check_delete_key.uuid)); })
                .catch((error) => { setErrorMessage(error.response?.data ?? "Error deleting API key"); });
        }
    };

    return <div>
        <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
                <tr>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Name</th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Key</th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Created</th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Last used</th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-36"></th>
                </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
                {org_keys.map((key) => (
                    <tr key={key.uuid}>
                        <td className="px-4 py-2 text-sm">{key.name}</td>
                        <td className="px-4 py-2 text-xs font-mono">
                            {key.key.length > 0 && <CopyTextbox text={key.key} is_email={false} />}
                            {key.key.length === 0 && <HiddenTextbox showText={() => showKey(key.uuid)} />}
                        </td>
                        <td className="px-4 py-2 text-sm">{prettyDateTime(key.created_at)}</td>
                        <td className="px-4 py-2 text-sm">{key.last_used_at ? prettyDateTime(key.last_used_at) : "-"}</td>
                        <td className="px-4 py-2 text-sm text-right">
                            <Button icon={TrashIcon} tooltip="Delete key" onClick={() => checkDeleteKey(key)} />
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
        <div className="m-4 mb-4 text-right">
            <Button icon={PlusIcon} text="Create key" onClick={() => setShowCreateModal(true)} />
        </div>

        <TextboxModal
            open={show_create_modal}
            title="Create new API key"
            message={"Enter a name for the new API key."}
            confirm="Create"
            validate={(name) => name.length > 0}
            onClose={createNewKey} />

        <ConfirmModal
            open={show_confirm_modal}
            title="Remove API key"
            message={[`Are you sure you want to remove API key '${check_delete_key?.name}'?`]}
            confirm="Remove"
            onClose={deleteKey} />
        <ErrorMessageBar
            message={error_message}
            clearMessage={() => setErrorMessage(undefined)} />
    </div>;
}
