import { ContextType, IScrapeDocument } from "../lib/backend/extractions.types.generated";
import { DEFAULT_NEW_CONTEXT, DEFAULT_NEW_TEMPLATE } from "../screens/NewTemplate";
import { IContextField } from "./types";
import { newUuid } from "./utils";

export interface IInitTemplateExample {
    name: string;
    documents: IScrapeDocument[];
    comment: string;
}

export interface IInitContext {
    context_name: string;
    fields: IContextField[];
    type: ContextType;
}

export interface IInitTemplate {
    template_name: string;
    contexts: IInitContext[];
}

export const DEV_TEMPLATE: IInitTemplate = {
    template_name: "Contact information",
    contexts: [
        {
            context_name: "",
            fields: [
                { uuid: "<placeholder>", name: "Name", datatype: "string", type: "extract" },
                { uuid: "<placeholder>", name: "Address", extract: { format: "STREET, CITY, ZIP, COUNTRY" }, datatype: "string", type: "extract" },
                { uuid: "<placeholder>", name: "Phone number", extract: { format: "COUNTRY (AREA) LOCAL" }, datatype: "string", type: "extract" },
                { uuid: "<placeholder>", name: "Email", datatype: "string", type: "extract" }
            ],
            type: "array",
        },
        {
            context_name: "First address mentioned in the document",
            fields: [
                { uuid: "<placeholder>", name: "Name", datatype: "string", type: "extract" },
                { uuid: "<placeholder>", name: "Address", datatype: "string", type: "extract" },
                { uuid: "<placeholder>", name: "Phone number", datatype: "string", type: "extract" },
                { uuid: "<placeholder>", name: "Email", datatype: "string", type: "extract" }
            ],
            type: "object"
        }
    ],
};

export const DEV_EXAMPLE_01: IInitTemplateExample = {
    name: "Multiple addresses",
    documents: [{
        type: "document",
        name: "Multiple addresses",
        pages: [{
            name: "page 0",
            text: "Headquarters of Timber&co are available at 123 Main Street, New York, NY 10001. " +
                "You can reach us at 1-800-555-5555 or info@timber.com.\n" +
                "Department for Artificial Intelligence, Jožef Stefan Institute, Jamova 39, SI- 1000 Ljubljana, Slovenia, " +
                "Tel.: + 386 1 477 3528, Fax.:+ 386 1 477 3851, e-mail: info@ijs.si, web: www.ijs.si\n" +
                "Magna International Inc., 337 Magna Drive, Aurora, Ontario, Canada L4G 7K1, Telephone: +1 905 726 2462"
        }]
    }],
    comment: "Multiple addresses, last without email."
};

export const DEV_EXAMPLE_02: IInitTemplateExample = {
    name: "Single address",
    documents: [{
        type: "document",
        name: "Single address",
        pages: [{
            name: "page 0",
            text: "Blaz Fortuna\n" +
                "Co-founder\n" +
                "extrakt.AI Inc., 4 Palo Alto Square, Palo Alto, CA 94306\n" +
                "P: +1 (650) 283-1854"
        }]
    }],
    comment: "Single address, without email."
};

export const WIZARD_INVOICE: IInitTemplate = {
    template_name: "Invoice",
    contexts: [
        {
            context_name: "General Information",
            fields: [
                {
                    "uuid": "<placeholder>",
                    "name": "Invoice Number",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Supplier Company Name",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Supplier Company Address",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "PO Number",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Issue Date",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {
                        "format": "YYYY-MM-DD"
                    }
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Due Date",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {
                        "format": "YYYY-MM-DD"
                    }
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Currency",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {
                        "examples": [
                            "EUR",
                            "USD"
                        ]
                    }
                }
            ],
            type: "object"
        },
        {
            context_name: "Items",
            fields: [
                {
                    "uuid": "<placeholder>",
                    "name": "SKU Code",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Item Name",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Quantity",
                    "datatype": "number",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Item Price",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Total Price",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                }
            ],
            type: "array"
        }
    ]
};

export const WIZARD_INVOICE_XERO: IInitTemplate = {
    template_name: "Invoice - Xero",
    contexts: [
        {
            context_name: "",
            fields: [
                {
                    "uuid": "<placeholder>",
                    "name": "*ContactName",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {
                        "examples": [
                            "John Smith"
                        ],
                        "guidelines": [
                            "Person name when available"
                        ]
                    }
                },
                {
                    "uuid": "<placeholder>",
                    "name": "EmailAddress",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "*InvoiceNumber",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "*InvoiceDate",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {
                        "format": "mm/dd/yyyy"
                    }
                },
                {
                    "uuid": "<placeholder>",
                    "name": "*DueDate",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {
                        "format": "mm/dd/yyyy"
                    }
                },
                {
                    "uuid": "<placeholder>",
                    "name": "InventoryItemCode",
                    "datatype": "number",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "*Description",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "*Quantity",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "*UnitAmount",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "*AccountCode",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "*TaxType",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {
                        "examples": [
                            "20%"
                        ]
                    }
                }
            ],
            type: "array"
        }
    ]
}

export const WIZARD_PURCHASE_ORDER: IInitTemplate = {
    template_name: "Purchase Order",
    contexts: [
        {
            context_name: "General Information",
            fields: [
                {
                    "uuid": "<placeholder>",
                    "name": "PO Number",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Customer Company Name",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Customer Company Address",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Document Date",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {
                        "format": "YYYY-MM-DD"
                    }
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Currency",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {
                        "examples": [
                            "EUR",
                            "USD"
                        ]
                    }
                }
            ],
            type: "object"
        },
        {
            context_name: "Items",
            fields: [
                {
                    "uuid": "<placeholder>",
                    "name": "SKU Code",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Item Name",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Quantity",
                    "datatype": "number",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Item Price",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Total Price",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                }
            ],
            type: "array"
        }
    ]
};

export const WIZARD_ORDER_CONFIRMATION: IInitTemplate = {
    template_name: "Order Confirmation",
    contexts: [
        {
            context_name: "General Information",
            fields: [
                {
                    "uuid": "<placeholder>",
                    "name": "Order Confirmation Number",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Supplier Company Name",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Supplier Company Address",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Customer PO Number",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Currency",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {
                        "format": "",
                        "examples": [
                            "EUR",
                            "USD"
                        ]
                    }
                }
            ],
            type: "object"
        },
        {
            context_name: "Items",
            fields: [
                {
                    "uuid": "<placeholder>",
                    "name": "SKU Code",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Quantity",
                    "datatype": "number",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Item Price",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Total Price",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Delivery Date",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {
                        "format": "YYYY-MM-DD"
                    }
                }
            ],
            type: "array"
        }
    ]
};

export const WIZARD_CUSTOMS_DECLARATION: IInitTemplate = {
    template_name: "Customs Declaration",
    contexts: [
        {
            context_name: "General Information",
            fields: [
                {
                    "uuid": "<placeholder>",
                    "name": "Entry Date",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {
                        "format": "YYYY-MM-DD"
                    }
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Manufacturer ID",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Exporting Country",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Importer Number",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Reference Number",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                }
            ],
            type: "object"
        },
        {
            context_name: "Items",
            fields: [
                {
                    "uuid": "<placeholder>",
                    "name": "Item Name",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Weight",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Quantity",
                    "datatype": "number",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Item Value",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "IRC Rate",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Duty and IR TAX",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                }
            ],
            type: "array"
        }
    ]
};

export const WIZARD_RFQ: IInitTemplate = {
    template_name: "Request for Quotation",
    contexts: [
        {
            context_name: "General Information",
            fields: [
                {
                    "uuid": "<placeholder>",
                    "name": "RFQ Number",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Supplier Company Name",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Supplier Company Address",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Deadline Date",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {
                        "format": "YYYY-MM-DD"
                    }
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Start Date",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {
                        "format": "YYYY-MM-DD"
                    }
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Completion Date",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {
                        "format": "YYYY-MM-DD"
                    }
                }
            ],
            type: "object"
        },
        {
            context_name: "Items",
            fields: [
                {
                    "uuid": "<placeholder>",
                    "name": "SKU Code",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Item Description",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Quantity",
                    "datatype": "number",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Price Quotation",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Specific Requirements",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                }
            ],
            type: "array"
        }
    ]
};

export const WIZARD_RESUME: IInitTemplate = {
    template_name: "Resume",
    contexts: [
        {
            context_name: "",
            fields: [
                { uuid: "<placeholder>", name: "Name", datatype: "string", type: "extract" },
                { uuid: "<placeholder>", name: "Email", datatype: "string", type: "extract" },
                { uuid: "<placeholder>", name: "Phone Number", datatype: "string", type: "extract" },
                { uuid: "<placeholder>", name: "LinkedIn", datatype: "string", type: "extract" },
                { uuid: "<placeholder>", name: "Experience", datatype: "string", type: "extract" },
                { uuid: "<placeholder>", name: "Start Date", datatype: "string", type: "extract" },
                { uuid: "<placeholder>", name: "End Date", datatype: "string", type: "extract" },
                { uuid: "<placeholder>", name: "Skills", datatype: "string", type: "extract" }
            ],
            type: "array"
        }
    ]
};

export const WIZARD_BANK_STATEMENT: IInitTemplate = {
    template_name: "Bank Statement",
    contexts: [
        {
            context_name: "",
            fields: [
                { uuid: "<placeholder>", name: "Date", datatype: "string", type: "extract" },
                { uuid: "<placeholder>", name: "Amount", datatype: "string", type: "extract" },
                { uuid: "<placeholder>", name: "Payee", datatype: "string", type: "extract" },
                { uuid: "<placeholder>", name: "Description", datatype: "string", type: "extract" },
                { uuid: "<placeholder>", name: "Reference", datatype: "string", type: "extract" },
                { uuid: "<placeholder>", name: "Check Number", datatype: "string", type: "extract" }
            ],
            type: "array"
        }
    ]
};

export const WIZARD_SALES_QUOTATION: IInitTemplate = {
    template_name: "Sales Quotation",
    contexts: [
        {
            context_name: "General Information",
            fields: [
                {
                    "uuid": "<placeholder>",
                    "name": "Order Number",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Vendor Company Name",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Vendor Company Address",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Document Date",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {
                        "format": "YYYY-MM-DD"
                    }
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Currency",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {
                        "examples": [
                            "EUR",
                            "USD"
                        ]
                    }
                }
            ],
            type: "object"
        },
        {
            context_name: "Items",
            fields: [
                {
                    "uuid": "<placeholder>",
                    "name": "SKU Code",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Description",
                    "type": "extract",
                    "datatype": "string",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Quantity",
                    "type": "extract",
                    "datatype": "number",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Item Price",
                    "type": "extract",
                    "datatype": "string",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Total Price",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                }
            ],
            type: "array"
        }
    ]
};

export const WIZARD_VENDOR_EVALUATION: IInitTemplate = {
    template_name: "Vendor Evaluation",
    contexts: [
        {
            context_name: "General Information",
            fields: [
                {
                    "uuid": "<placeholder>",
                    "name": "Evaluation Number",
                    "type": "extract",
                    "datatype": "string",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Company Name",
                    "type": "extract",
                    "datatype": "string",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Company Address",
                    "type": "extract",
                    "datatype": "string",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Document Date",
                    "type": "extract",
                    "datatype": "string",
                    "extract": {
                        "format": "YYYY-MM-DD"
                    }
                }
            ],
            type: "object"
        },
        {
            context_name: "Ratings",
            fields: [
                {
                    "uuid": "<placeholder>",
                    "name": "Criteria",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Score",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                }
            ],
            type: "array"
        }
    ]
};

export const WIZARD_SHORT_CONTRACT: IInitTemplate = {
    template_name: "Short Contract",
    contexts: [
        {
            context_name: "",
            fields: [
                { uuid: "<placeholder>", name: "Vendor Company", type: "extract", datatype: "string" },
                { uuid: "<placeholder>", name: "Vendor Address", type: "extract", datatype: "string" },
                { uuid: "<placeholder>", name: "Customer Company", type: "extract", datatype: "string" },
                { uuid: "<placeholder>", name: "Customer Address", type: "extract", datatype: "string" },
                { uuid: "<placeholder>", name: "Start Date", type: "extract", datatype: "string" },
                { uuid: "<placeholder>", name: "Termination Date", type: "extract", datatype: "string" },
                { uuid: "<placeholder>", name: "Total Amount", datatype: "string", type: "extract" },
                { uuid: "<placeholder>", name: "Date of Signing", type: "extract", datatype: "string" },
                { uuid: "<placeholder>", name: "Contract signed by Vendor", datatype: "string", type: "extract", extract: { format: "Yes or no" } },
                { uuid: "<placeholder>", name: "Contract signed by Customer", datatype: "string", type: "extract", extract: { format: "Yes or no" } }
            ],
            type: "object"
        }
    ]
};

export const WIZARD_SALES_REPORT: IInitTemplate = {
    template_name: "Sales Report",
    contexts: [
        {
            context_name: "General Information",
            fields: [
                {
                    "uuid": "<placeholder>",
                    "name": "Sales Representative",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Total Sales Revenue",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Document Date",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {
                        "format": "YYYY-MM-DD"
                    }
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Currency",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {
                        "examples": [
                            "EUR",
                            "USD"
                        ]
                    }
                }
            ],
            type: "object"
        },
        {
            context_name: "Items",
            fields: [
                {
                    "uuid": "<placeholder>",
                    "name": "SKU Code",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Quantity",
                    "type": "extract",
                    "datatype": "number",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Item Name",
                    "type": "extract",
                    "datatype": "string",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Item Price",
                    "type": "extract",
                    "datatype": "string",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Total Price",
                    "type": "extract",
                    "datatype": "string",
                    "extract": {}
                }
            ],
            type: "array"
        }
    ]
};

export const WIZARD_ANNUAL_REPORT: IInitTemplate = {
    template_name: "Annual Report",
    contexts: [
        {
            context_name: "",
            fields: [
                { uuid: "<placeholder>", name: "Company", datatype: "string", type: "extract" },
                { uuid: "<placeholder>", name: "Date", datatype: "string", type: "extract" },
                { uuid: "<placeholder>", name: "Profit after Taxes", datatype: "string", type: "extract" },
                { uuid: "<placeholder>", name: "Net Profit", datatype: "string", type: "extract" },
                { uuid: "<placeholder>", name: "Retained earnings", datatype: "string", type: "extract" },
                { uuid: "<placeholder>", name: "Distributable profit", datatype: "string", type: "extract" }
            ],
            type: "object"
        }
    ]
};

export const WIZARD_BILL_OF_LADING: IInitTemplate = {
    template_name: "Bill of Lading",
    contexts: [
        {
            context_name: "",
            fields: [
                {
                    "uuid": "<placeholder>",
                    "name": "Bill of Lading Number",
                    "type": "extract",
                    "datatype": "string",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Document Date",
                    "type": "extract",
                    "datatype": "string",
                    "extract": {
                        "format": "YYYY-MM-DD"
                    }
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Buyer Company Name",
                    "type": "extract",
                    "datatype": "string",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Buyer Company Address",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Buyer Contact",
                    "type": "extract",
                    "datatype": "string",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Vessel Name",
                    "type": "extract",
                    "datatype": "string",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Carrier Name",
                    "type": "extract",
                    "datatype": "string",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Way Bill Number",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Customer Order Number",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Reference Code",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Time of arrival",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                }
            ],
            type: "object"
        }
    ]
};

export const WIZARD_DELIVERY_NOTE: IInitTemplate = {
    template_name: "Delivery Note",
    contexts: [
        {
            context_name: "General Information",
            fields: [
                {
                    "uuid": "<placeholder>",
                    "name": "Delivery Note Number",
                    "type": "extract",
                    "datatype": "string",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Supplier Company Name",
                    "type": "extract",
                    "datatype": "string",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Supplier Company Address",
                    "type": "extract",
                    "datatype": "string",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Supplier Company Contact",
                    "type": "extract",
                    "datatype": "string",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Document Date",
                    "type": "extract",
                    "datatype": "string",
                    "extract": {
                        "format": "YYYY-MM-DD"
                    }
                }
            ],
            type: "object"
        },
        {
            context_name: "Items",
            fields: [
                {
                    "uuid": "<placeholder>",
                    "name": "SKU",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Item Name",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Quantity",
                    "datatype": "number",
                    "type": "extract",
                    "extract": {}
                }
            ],
            type: "array"
        }
    ]
};

export const WIZARD_SHIPPING_LABEL: IInitTemplate = {
    template_name: "Shipping Label",
    contexts: [
        {
            context_name: "General Information",
            fields: [
                {
                    "uuid": "<placeholder>",
                    "name": "Tracking Number",
                    "type": "extract",
                    "datatype": "string",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Service Type",
                    "type": "extract",
                    "datatype": "string",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Recipient Company Name",
                    "type": "extract",
                    "datatype": "string",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Recipient Company Address",
                    "type": "extract",
                    "datatype": "string",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Sender Company Name",
                    "type": "extract",
                    "datatype": "string",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Sender Company Address",
                    "type": "extract",
                    "datatype": "string",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Additional Info",
                    "type": "extract",
                    "datatype": "string",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Shipping Date",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {
                        "format": "YYYY-MM-DD"
                    }
                }
            ],
            type: "object"
        }
    ]
};

export const WIZARD_BOM: IInitTemplate = {
    template_name: "BOM",
    contexts: [
        {
            context_name: "",
            fields: [
                { uuid: "<placeholder>", name: "BOM Level", type: "extract", datatype: "number" },
                { uuid: "<placeholder>", name: "SKU Code", type: "extract", datatype: "string" },
                { uuid: "<placeholder>", name: "Description", type: "extract", datatype: "string" },
                { uuid: "<placeholder>", name: "Yield %", type: "extract", datatype: "string" },
                { uuid: "<placeholder>", name: "Quantity", type: "extract", datatype: "string" },
                { uuid: "<placeholder>", name: "UOM", datatype: "string", type: "extract" }
            ],
            type: "array"
        }
    ]
};

export const WIZARD_QUALITY_CONTROL: IInitTemplate = {
    template_name: "Quality Control",
    contexts: [
        {
            context_name: "",
            fields: [
                {
                    "uuid": "<placeholder>",
                    "name": "Project Number",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Contractor Company Name",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Contractor Company Address",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Work Description",
                    "type": "extract",
                    "datatype": "string",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Subcontractor Company Name",
                    "type": "extract",
                    "datatype": "string",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Equipment Used",
                    "type": "extract",
                    "datatype": "string",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Number of Workers",
                    "type": "extract",
                    "datatype": "string",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Total Hours",
                    "type": "extract",
                    "datatype": "string",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Document Date",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {
                        "format": "YYYY-MM-DD"
                    }
                }
            ],
            type: "object"
        }
    ]
};

export const WIZARD_WORK_ORDER: IInitTemplate = {
    template_name: "Work Order",
    contexts: [
        {
            context_name: "General Information",
            fields: [
                {
                    "uuid": "<placeholder>",
                    "name": "Work Order Number",
                    "type": "extract",
                    "datatype": "string",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Customer Company Name",
                    "type": "extract",
                    "datatype": "string",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Customer Company Address",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Document Date",
                    "type": "extract",
                    "datatype": "string",
                    "extract": {
                        "format": "YYYY-MM-DD"
                    }
                }
            ],
            type: "object"
        },
        {
            context_name: "Items",
            fields: [
                {
                    "uuid": "<placeholder>",
                    "name": "Description",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Quantity",
                    "datatype": "number",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Unit of Measure",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                }
            ],
            type: "array"
        }
    ]
};

export const WIZARD_LONG_CONTRACT: IInitTemplate = {
    template_name: "Long Contract",
    contexts: [
        {
            context_name: "",
            fields: [
                { uuid: "<placeholder>", name: "Contract÷Termination Facts (top 3)≤≥÷reference_line_number", type: "extract", datatype: "string", extract: { examples: ["L124"] } },
                { uuid: "<placeholder>", name: "Contract÷Termination Facts (top 3)≤≥÷Summary", type: "extract", datatype: "string", extract: { examples: ["Either party may terminate this agreement by providing written notice to the other party at least 30 days prior to the desired termination date. In the event of termination, the Client shall pay the Service Provider for all services performed up to the effective date of termination."] } },
                { uuid: "<placeholder>", name: "Contract÷Termination Facts (top 3)≤≥÷Termination Triggers÷reference_line_number", type: "extract", datatype: "string", extract: { examples: ["L130"] } },
                { uuid: "<placeholder>", name: "Contract÷Termination Facts (top 3)≤≥÷Termination Triggers÷Trigger Description", type: "extract", datatype: "string", extract: { examples: ["Breach of contract terms by either party"] } },
                { uuid: "<placeholder>", name: "Contract÷Termination Facts (top 3)≤≥÷Notice Period÷reference_line_number", type: "extract", datatype: "string", extract: { examples: ["L190"] } },
                { uuid: "<placeholder>", name: "Contract÷Termination Facts (top 3)≤≥÷Notice Period÷Duration", type: "extract", datatype: "number", extract: { examples: ["30"] } },
                { uuid: "<placeholder>", name: "Contract÷Termination Facts (top 3)≤≥÷Notice Period÷Unit", type: "extract", datatype: "string", extract: { examples: ["days"] } },
                { uuid: "<placeholder>", name: "Contract÷Pricing÷Currency", type: "extract", datatype: "string", extract: { examples: ["USD"] } },
                { uuid: "<placeholder>", name: "Contract÷Pricing÷Pricing Model", type: "extract", datatype: "string", extract: { examples: ["Subscription"] } },
                { uuid: "<placeholder>", name: "Contract÷Pricing÷Base Price÷reference_line_number", type: "extract", datatype: "string", extract: { examples: ["L123"] } },
                { uuid: "<placeholder>", name: "Contract÷Pricing÷Base Price÷Price", type: "extract", datatype: "number", extract: { examples: ["5000"] } },
                { uuid: "<placeholder>", name: "Contract÷Liability Facts (top 3)≤≥÷reference_line_number", type: "extract", datatype: "string", extract: { examples: ["L123"] } },
                { uuid: "<placeholder>", name: "Contract÷Liability Facts (top 3)≤≥÷Summary", type: "extract", datatype: "string", extract: { examples: ["The Service Provider shall indemnify and hold harmless the Client from any third-party claims arising from the Service Provider's breach of intellectual property rights."] } },
                { uuid: "<placeholder>", name: "Contract÷Liability Facts (top 3)≤≥÷Liability Type", type: "extract", datatype: "string", extract: { examples: ["Indemnification"] } },
                { uuid: "<placeholder>", name: "Contract÷Liability Facts (top 3)≤≥÷Liable Party", type: "extract", datatype: "string", extract: { examples: ["Service Provider"] } },
                { uuid: "<placeholder>", name: "Contract÷Term÷Effective Date÷reference_line_number", type: "extract", datatype: "string", extract: { examples: ["L100"] } },
                { uuid: "<placeholder>", name: "Contract÷Term÷Effective Date÷Date", type: "extract", datatype: "string", extract: { examples: ["2023-01-01"] } },
                { uuid: "<placeholder>", name: "Contract÷Term÷Expiration Date÷reference_line_number", type: "extract", datatype: "string", extract: { examples: ["L110"] } },
                { uuid: "<placeholder>", name: "Contract÷Term÷Expiration Date÷Date", type: "extract", datatype: "string", extract: { examples: ["2023-12-31"] } },
                { uuid: "<placeholder>", name: "Contract÷Maintenance Facts÷Scope Of Maintenance", type: "extract", datatype: "string", extract: { examples: ["The maintenance agreement covers bug fixes, software updates, and technical support for the XYZ software system."] } },
                { uuid: "<placeholder>", name: "Contract÷Maintenance Facts÷Maintenance Period÷reference_line_number", type: "extract", datatype: "string", extract: { examples: ["L123"] } },
                { uuid: "<placeholder>", name: "Contract÷Maintenance Facts÷Maintenance Period÷Start Date", type: "extract", datatype: "string", extract: { examples: ["2023-01-01"] } },
                { uuid: "<placeholder>", name: "Contract÷Maintenance Facts÷Maintenance Period÷End Date", type: "extract", datatype: "string", extract: { examples: ["2023-12-31"] } },
                { uuid: "<placeholder>", name: "Contract÷Maintenance Facts÷Service Level÷reference_line_number", type: "extract", datatype: "string", extract: { examples: ["L252"] } },
                { uuid: "<placeholder>", name: "Contract÷Maintenance Facts÷Service Level÷Response Time", type: "extract", datatype: "string", extract: { examples: ["2 business hours"] } },
                { uuid: "<placeholder>", name: "Contract÷Maintenance Facts÷Service Level÷Resolution Time", type: "extract", datatype: "string", extract: { examples: ["8 business hours"] } },
                { uuid: "<placeholder>", name: "Contract÷Maintenance Facts÷Service Level÷Availability", type: "extract", datatype: "string", extract: { examples: ["99.9%"] } }
            ],
            type: "hierarchical"
        }
    ]
};

export const WIZARD_BACKORDERS: IInitTemplate = {
    template_name: "Backorders",
    contexts: [
        {
            context_name: "General Info",
            fields: [
                {
                    "uuid": "<placeholder>",
                    "name": "Supplier Company Name",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Supplier Company Address",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Supplier Company Representative",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Representative Email",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Email Last Date of Interaction",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {
                        "format": "YYYY-MM-DD"
                    }
                },
                {
                    "uuid": "<placeholder>",
                    "name": "First Date of Interaction",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {
                        "format": "YYYY-MM-DD"
                    }
                }
            ],
            type: "object"
        },
        {
            context_name: "Email Thread Log",
            fields: [
                {
                    "uuid": "<placeholder>",
                    "name": "Date",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {
                        "format": "YYYY-MM-DD"
                    }
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Sender",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Recipient",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Subject",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Email type",
                    "datatype": "enum",
                    "type": "extract",
                    "extract": {
                        "verbatim": false,
                        "enum_data": {
                            "type": "list",
                            "values": [
                                {
                                    "value": "Inquiry",
                                    "guideline": ""
                                },
                                {
                                    "value": "Reminder",
                                    "guideline": ""
                                },
                                {
                                    "value": "Change of date",
                                    "guideline": ""
                                },
                                {
                                    "value": "Change of quantity",
                                    "guideline": ""
                                },
                                {
                                    "value": "Confirmation",
                                    "guideline": ""
                                },
                                {
                                    "value": "Other",
                                    "guideline": ""
                                }
                            ]
                        }
                    }
                }
            ],
            type: "array"
        },
        {
            context_name: "Changes",
            fields: [
                {
                    "uuid": "<placeholder>",
                    "name": "Customer Purchase Order Name",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Confirmation Order Number",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {
                        "guidelines": [
                            "Also called sales order number"
                        ]
                    }
                },
                {
                    "uuid": "<placeholder>",
                    "name": "SKU code",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Item Description",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Ordered Quantity",
                    "datatype": "number",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Previously Confirmed Delivery Date",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {
                        "format": "",
                        "guidelines": [
                            "Only changes of already confirmed dates should be listed here!"
                        ],
                        "examples": [
                            "12/23/2024"
                        ]
                    }
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Updated Delivery Date",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {
                        "verbatim": true,
                        "guidelines": [
                            "Update delivery date, VERBATIM as expressed in the email"
                        ]
                    }
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Status",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {
                        "guidelines": [
                            "Also comment"
                        ]
                    }
                }
            ],
            type: "array"
        }
    ]
};

export const WIZARD_DEMAND_FORECAST_REPORT: IInitTemplate = {
    template_name: "Demand Forecast Report",
    contexts: [
        {
            context_name: "General Information",
            fields: [
                {
                    "uuid": "<placeholder>",
                    "name": "Demand Forecast Number",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Customer Company Name",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Customer Company Address",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Document Date",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {
                        "format": "YYYY-MM-DD"
                    }
                }
            ],
            type: "object"
        },
        {
            context_name: "Items",
            fields: [
                {
                    "uuid": "<placeholder>",
                    "name": "Material ID",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Quantity",
                    "datatype": "number",
                    "type": "extract",
                    "extract": {}
                },
                {
                    "uuid": "<placeholder>",
                    "name": "Requested Date",
                    "datatype": "string",
                    "type": "extract",
                    "extract": {}
                }
            ],
            type: "array"
        }
    ]
};

export type WizardTemplateType = "custom" | "dev" | "invoice" | "invoice_xero" | "purchase_order" | "order_confirmation" | "customs_declaration" | "rfq" |
    "resume" | "bank_statement" | "bank_statement_xero" | "sales_quotation" | "vendor_evaluation" | "short_contract" | "sales_report" | "annual_report" |
    "bill_of_lading" | "delivery_note" | "shipping_label" | "bom" | "quality_control" | "work_order" | "long_contract" | "backorders" | "demand_forecast_report";

function getWizardInitTemplate(init_template_type: WizardTemplateType): IInitTemplate {
    switch (init_template_type) {
        case "dev": return DEV_TEMPLATE;
        case "invoice": return WIZARD_INVOICE;
        case "invoice_xero": return WIZARD_INVOICE_XERO;
        case "purchase_order": return WIZARD_PURCHASE_ORDER;
        case "order_confirmation": return WIZARD_ORDER_CONFIRMATION;
        case "customs_declaration": return WIZARD_CUSTOMS_DECLARATION;
        case "rfq": return WIZARD_RFQ;
        case "resume": return WIZARD_RESUME;
        case "bank_statement": return WIZARD_BANK_STATEMENT;
        case "bank_statement_xero": return WIZARD_BANK_STATEMENT;
        case "sales_quotation": return WIZARD_SALES_QUOTATION;
        case "vendor_evaluation": return WIZARD_VENDOR_EVALUATION;
        case "short_contract": return WIZARD_SHORT_CONTRACT;
        case "sales_report": return WIZARD_SALES_REPORT;
        case "annual_report": return WIZARD_ANNUAL_REPORT;
        case "bill_of_lading": return WIZARD_BILL_OF_LADING;
        case "delivery_note": return WIZARD_DELIVERY_NOTE;
        case "shipping_label": return WIZARD_SHIPPING_LABEL;
        case "bom": return WIZARD_BOM;
        case "quality_control": return WIZARD_QUALITY_CONTROL;
        case "work_order": return WIZARD_WORK_ORDER;
        case "long_contract": return WIZARD_LONG_CONTRACT;
        case "backorders": return WIZARD_BACKORDERS;
        case "demand_forecast_report": return WIZARD_DEMAND_FORECAST_REPORT;
        default: return {
            template_name: DEFAULT_NEW_TEMPLATE.name,
            contexts: [{
                context_name: "",
                fields: DEFAULT_NEW_CONTEXT.fields,
                type: DEFAULT_NEW_CONTEXT.type
            }]
        };
    }
}

export function getWizardTemplate(init_template_type: WizardTemplateType): IInitTemplate {
    const new_template = getWizardInitTemplate(init_template_type);
    return {
        ...new_template,
        contexts: new_template.contexts.map((context) => ({
            ...context,
            fields: context.fields.map((field) => ({ ...field, uuid: newUuid() }))
        }))
    };
}