import { EXTRACT_CONFIRMATION_STATUS } from "../lib/consts";
import {
    ExtractConfirmationStatus
} from "../lib/backend/extractions.types.generated";
import { classNames } from "../lib/utils";

type ConfirmationPillProps = {
    status: ExtractConfirmationStatus;
}

export function ConfirmationPill(props: ConfirmationPillProps) {
    const { status, } = props;
    return <span className={classNames(
        "px-1 my-1 mr-2",
        status === EXTRACT_CONFIRMATION_STATUS.pending ?
            "bg-yellow-400" :
            status === EXTRACT_CONFIRMATION_STATUS.confirmed ?
                "bg-green-400" :
                status === EXTRACT_CONFIRMATION_STATUS.rejected ?
                    "bg-red-400" :
                    "bg-gray-400"
    )} >{" "}</span>

}